/**** Responsive ****/
@media (min-width: 2200px){.hmenu_sec ul {margin-top: 8px}}
@media (min-width: 1200px){        
.mobile_hmenu_list{ display: none;}/* Mobile cms menu */
.hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media (min-width: 679px){
.cur-ord-rec-mobile{ display: none;}
}   
@media (min-width: 576px){    
.modal-dialog { margin: 1.75rem auto; }
.modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}
}
@media(max-width: 1330px) and (min-width: 991px){
.promation_btn .btn {min-width: auto; width: 48%; float: left; margin: 1%; /*font-size: 16px;*/ padding: 12px 10px;}
}
@media (min-width: 1500px){   
.container-full{ padding-left: 0; padding-right: 0  } 

.login-popup-all .order_delivery_item h3 {
    font-size: 18px;
}
}
/* Max width */
@media (max-width: 1350px){
.container, .container-full{padding-left: 15px; padding-right: 15px;}
}

@media (max-width: 1280px){
ul.hmenu_list > li > a, .hsign_sec a{/*font-size: 14px;*/}
.hordertype_sec a { padding: 10px 20px;  margin: 0 15px 0 20px;/*font-size: 14px;*/}    
.htico_cart{margin-left: 10px}    
.pdr_right.price-fixed{left: inherit !important; right: 0}    
}
@media (max-width: 1240px){
.header_in::before, .header_in::after, .ourstory-inner-cont:before{display: none}   
}
@media (max-width: 1199px){
input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
.sticky .alert_fixed{ top: 95px;}
.header_in{ padding: 0 }
.header_middle:before, header.small_sticky:before{display: none !important}    
.hmenu_actions{float: right}
.product-view-more {  margin-top: 15px;}    
.tbars header{ height: 153px;}
.breadcrumb{ padding: 25px 0 0;}
.Products .breadcrumb{ padding: 69px 0 0 0;}
.Products .bread_space { margin: 69px 0 0; }
.hmenu_actions > li.hsign_sec{ display: none;}
.mega_menuin [class*='col-']{width: 100%;}

.hmenu_actions li.horder_btn{display: none;}
.hsearch_bx .re_select .Select-control:before{ right: 14px;}
i.hdd_arrow{ position: absolute; right: 0; top: 0; bottom: 0; height: 45px; width: 45px; line-height: 45px; /*font-size: 16px; */text-align: center; background: #ecdfcc; color: #181818; margin: auto 0;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
.bakery_row_fixed{ top: 162px;}
.tbars .bakery_row_fixed{ top: 202px;}
.banner{ margin-top: 25px;}
.banner .container{ padding: 0 15px;}
.banner-top-text{position: static;left: 0;right: 0;margin: 0 auto 50px auto;width: 100%; padding: 0 50px;}    
.banner-text-box{margin-right: 0;}
.banner-info h2, .featured-products-main h2, .serving-passionately-right h3{/*font-size: 40px;*/ }
/*.banner-info p{font-size: 22px}*/
.featured-products-full{ width: 100%}
.delivery_total.delivery_total_number .delivery_total_left h2{/*font-size: 15px;*/}
.delivery_total.delivery_total_number .delivery_total_left h4{/*font-size: 20px;*/}
.innerproduct-col{width: 32.29061553985873%;margin-right: 1.5136226034308779%;margin-bottom: 15px;}
.innerproduct-col:nth-of-type(3n+3){margin-right: 0;}
.innerproduct-col:nth-of-type(5n+5){margin-right: auto;}
.chk_hea{/*font-size: 16px;*/}
.checkout-total .cdd-details-lhs{width: 100%;margin-bottom: 10px;} 
.cdd-details-lhs .checkout-heading:after{display: none}    
.check_pg_inner{padding: 20px 15px 10px 15px;}
.checkout-total .checkout-control-group-top{padding: 20px 0 20px 0;}
.checkout-total .cdd-details-rhs{width: 100%;}
.checkout-total .checkout-control-group-middle{padding: 10px 0 0px 0;}
.checkout-control-group-top [class*='col-md']{margin-bottom: 10px;}
.checkout-control-group-middle [class*='col-md']{margin-bottom: 10px;}
.checkout-total .checkout-control-group-top a{margin-top: 0;}
.order-detail-inner-icon{max-width: 100%;}
.order-detail-inner-icon-left.order-detail-inner-center::before{background-repeat: repeat;max-width: 100%;height: 6px;}
.order-detail-inner{padding-top: 27px;}
.main_tabsec_inner> .nav-tabs>li> a{text-align: left;}
.main_tabsec_inner, .rewards-tab-section .main_tabsec_inner, .promation-tab-section .main_tabsec_inner{padding: 20px 15px;}
.title_sec h2{/*font-size: 34px;*/}
.filter_tabsec .nav-tabs>li>a{/*font-size: 17px;*/}
.hme-product-inner .filter_tabsec .nav-tabs{margin-bottom: 20px;}
.banner-left{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.banner-text-box{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;width: 100%;}
.banner-right{margin-top: 0;}
.banner-right-content{text-align: left;}
.htopinfo{text-align: center;width: 100%;}
.delivery-item h2{/*font-size: 32px;*/}
.delivery-item span{/*font-size: 17px;*/}
.hme-product-inner{padding: 20px 15px;}
.outlet-list li{ padding-left: 0;}
.footer-inner [class*='col-']{width: 33.33%;}
.footer-inner .news-letter{width: 100%;margin-top: 10px;}
.cart_footer_totrow p{/*font-size: 20px;*/}
.cart_footer_totrow span{/*font-size: 20px;*/}
.delivery_info h4{/*font-size: 14px;*/}
.delivery_info p{/*font-size: 14px;*/}
.cart_action .btn_grey{/*font-size: 14px;*/}
.tnk-you{padding: 70px 0;}
.redeem-col{width: 100%;margin: 0 0 10px 0;padding: 20px 10px;}
.redeem-item-hea{padding-left: 0;}
.redeem-item-hea h4{/*font-size: 23px;*/}
.tnk-order h3{/*font-size: 28px;*/}
.tnk-order p{ /*font-size: 14px;*/}
.tick img{width: 60px;}
.tick h2{/*font-size: 34px;*/margin-top: 10px;}
.tick span{/*font-size: 16px;*/}
.delivery-inner{padding: 40px 0;position: relative;}
.delivery-inner-comman{position: relative;z-index: 2;}
.delivery-inner::after{content: "";background: rgba(255, 255, 255, 0.6);top: 0;left: 0;bottom: 0;right: 0;position: absolute;z-index: 1;}
.bts .form-group {min-width: auto;width: calc(100% - 200px) !important;}
/*promotion pages*/
.promo-earned-content{padding: 10px 10px; }
.promo-earned-content h2, .promo-earned-content span{ /*font-size: 20px;*/ }
.promo-earned-content h2 span sup {/*font-size: 20px;*/ }
.abc_code span{/* font-size: 17px;*/ }
.abc_code{ padding: 10px 0; }
.redeem_popsec .promo-earned-content h2 span sup {/*font-size: 18px;*/ }
.redeem_popsec .promo-earned-content h4{ /*font-size: 24px; */}
.redeem_popsec .promo-earned-content p{ margin: 0; }
.redeem_popsec .abc_code {padding: 6px 0 0px; }
.redeem_popsec .promo-earned-content{ padding: 20px 10px 20px; }
.redeem_popsec .promo-earned-content h2{/* font-size: 30px;*/}
/* Account information page */

.pkg_ginfo p{ max-height: 75px; overflow: hidden}    
.header-bottom .logo{display: none}    
.mobile-logo-div{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;text-align: center; position: absolute;left: 0;
    right: 0; max-width: 210px; margin: auto;top: 5px;bottom: 5px; -webkit-box-align: center; -ms-flex-align: center; align-items: center}       
.mobile-logo-div a{display: block;width: 100%;}    
.mobile-logo-div a img{ width: 100% }       
.productlist-main-div{ width: 100%} 

.login-popup-all .form-group a:before{ width: 120px; }   
}
@media (max-width: 1180px){
.pkselected_package .buffet-label {padding: 15.5px 15px 15.5px 60px;width: 130px;}
.pkselected_package .form-group {/*width: calc(100% - 135px);*/ min-width: auto;}
.pkselected_filtersec .time-label {width: 171px;}
.pkselected_break .form-group {min-width: auto; /*width: calc(100% - 175px);*/}
.row-pgk-list > li, .pkg_gcol { width: 33.333333333333%;}    
}
@media (max-width: 1100px){
.order_delivery_mobile_tooltip, .selfcollect_tooltip .order_delivery_mobile_tooltip{top: -119px; left:-13px; right:-13px; height: 110px; opacity:1; visibility:visible; pointer-events:auto;}
.order_delivery_mobile_tooltip:before, .selfcollect_tooltip .order_delivery_mobile_tooltip:before{ top: auto; right: auto; left: 50%; bottom: -16px; border-color: #181818 transparent transparent transparent; border-width: 10px 7px 10px 7px; margin: 0 0 0 -7px;}
.order_delivery_mobile_tooltip p{ /*font-size: 13px;*/ }    
.chekout_cart_bar .cart_extrainfo{padding-left: 0px;}
.ord-amount [class*='col-']{width: 100%;}
.earned_points{float: none;}
.ord-rec-sec{float: none;width: 100%; margin-top: 10px;}
.sprite_lst_icon{display: none}
.web_order_typecls li a h5{/*font-size: 16px*/}    
.f-nav > ul > li { padding-right: 20px;}    
.productlist-main-div{ padding: 25px 0px 20px;}    
/*.productlist-main-div .products-list-ulmain{justify-content: space-between;}*/
/*.productlist-main-div .products-list-ulmain > li{width:49%;margin: 0 0 20px 0;}*/    
.product-rhs { width: 350px;}    
/*.product-lhs { width: calc( 100% - 365px );} */   
}

@media (max-width: 1080px){

.header_bot{display: block; margin: 15px 0 0 0;}       
.hordertype_sec{display: none}  
.logo-main-section{-webkit-box-align: center;-ms-flex-align: center;align-items: center}    
/*.hsign_sec{display: none}  */   
.htico_cart { margin-left: 7px;}     
/*.mobile-login ul{right: 0; left: inherit;}*/   
/*.mobile-login {position: absolute; right: 70px;} */   
/*a.controller-nav img { width: 28px;} */     
 
.menu-section-mobile-inner, .mobile-menu-section-left{display: block}    
.spm-lhs{width: 340px}    
.product_det_cards > ul{justify-content: space-between}    
.product_det_cards > ul li.det_card{width: 49%; margin: 0 0 20px 0;}    
.choose-option-hover{width: 260px}    
.imp-button a{/*font-size: 12px*/}
.imp-button a img { max-width: 24px; max-height: 22px;}
.choose-option-hover ul{padding: 5px}
.choose-option-hover ul li a{/*font-size: 13px;*//*font-family: 'poppinsregular';*/}
.choose-option-hover ul li { margin: 4px 0;}
.pdr_right.price-fixed{top: 136px}    
}

@media (max-width: 1024px){
.delivery{margin-top: 30px;}    
.prd_chosen_row{padding: 25px 20px 20px 20px;}
.congrats.redeem-promation-body{padding: 24px 0;}    
.prd-sub{max-width: 100%;}
.prd-sub .slick-prev{z-index: 1; background: rgba(255,255,255,0.75); left: 0;}
.prd-sub .slick-next{z-index: 1; background: rgba(255,255,255,0.75); right: 0;}
.product_chosen_hea h6{/*font-size: 14px;*/}
.tnk-you{padding:45px 0 30px 0;}
.number-break .back_bx {left: 15px; width: 55px; height: 60px; /*font-size: 14px;*/ padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; /*font-size: 26px;*/}
.number-break {padding: 25px 30px 25px 45px;}  
.award_galimg{ height: 250px }  
.head_left h4{/*font-size: 19px*/} 
.head_right h4{/*font-size: 16px*/}    
.order_amt h3{/*font-size: 21px*/}    
}
@media (max-width: 991px){
    .menu-section-left, .product-menu-full{display: none}   
    .hcart_scrollarea .del_address .form-control {
    padding: 10px 30px 10px 10px;
    height: 50px;
}

.login-popup-all .form-group a:before {
    width: 110px;
}

.hcart_scrollarea .custom_select:after{
    width: 35px;
 
}

.hbslider_sec h2{ /*font-size: 20px;*/} 
.container_720 { max-width: 100%;}

.contact_wrap .bg-black .contactus_rhs{
        max-width: 100%;
}
.contact_wrap .bg-black .contactus_lhs{
    flex: 1 1 57%;
    max-width: 100%;
    margin-bottom: 25px;
    margin-top: 25px;
}
.prodetailinner-main-div{
    margin-top: 15px;
}
/*--category--*/
.pkg_ginfo h4 {/*font-size: 16px;*/margin: 0 0 10px;}
.pkgselect_dd .chosen-container {/*font-size: 14px;*/}
.pkgselect_dd .chosen-container-single .chosen-single {/*font-size: 14px;*/}
.pkg_gbtsec .btn {/*font-size: 14px;*/ min-width: 170px;height: 42px;}
.pkg_ginfo .btn {/*font-size: 14px;*/ padding: 9px 20px 7px;height: 34px;}
/*--update your package--*/
.pkg_listsec .bx_in {padding: 15px;}
.pkglist_itemtable > div {display: block;}
.pkglist_img {width: 100%; padding: 0px 0px 10px;}
.pkglist_info {width: 100%; padding: 15px 0px 0px;}
.pkglist_pricebx {width: 100%; border-left: 1px solid transparent; padding: 0px 0px; text-align: left;}
.pkselect_tabin {padding: 15px 0;}
.package_checkbx .bx_in {padding: 15px 15px;}
.package_formtt {/*font-size: 17px;*/}
.package_checkitem {padding: 15px 15px;}
.package_checkbx .package_formchecklist .custom_checkbox span {/*font-size: 14px;*/padding: 5px 0 0 40px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {/*font-size: 14px;*/}
.package_addonbx .bx_in {    padding: 20px 15px;}
.package_setupbx .bx_in { padding: 20px 15px;}
.package_descbx .bx_in { padding: 20px 15px;}
.title4 {  /*font-size: 16px;*/}

.form-group [class*="col-md-"]{ margin-bottom: 8px;}
.form-group [class*="col-md-"]:last-child { margin-bottom: 0; }    
.delivery-item span{ /*font-size: 14px;*/}
.delivery-item h2{ /*font-size: 24px;*/}
.delivery-item p{/* font-size: 16px;*/}
/* Tab sec */
.nav-tabs{ display: none;}
.tab-content>.tab-pane{ opacity: 1; margin: 0 0 15px;}
.tab_mobtrigger{ display: block; position: relative; padding-right: 45px; margin: 0; cursor: pointer;}
.tab_mobtrigger i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.tab_mobtrigger i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.tab_mobtrigger.active i:after{ content: "\f068";}
.tab_mobrow{ display: none; margin: 0px 0 0;}
.tab-content > .tab-pane.active > .tab_mobrow{ display: block; padding-bottom: 0; padding-top: 0px;}
/* Main Tab */
.main_tabsec .nav-tabs{ display: none;}
.main_tabsec .tab_mobtrigger{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; /*font-size: 16px; */ }
.filter_tabsec .tab_mobtrigger{ padding: 5px 45px 8px 10px; /*font-size: 16px;*/ }
.filter_tabsec .tab_mobtrigger:after{ 
content: ""; background: #ecdfcc; height: 3px; position: absolute; left: 0; right: 0; bottom: 0;  pointer-events: none; -webkit-transition: all 400ms; transition: all 400ms;}
.tab-content > .tab-pane.promation-tab-section.active > .tab_mobrow{padding-top: 0;}
.small_responsive_row .filter_tabsec .tab_mobtrigger.inner_tab_border{display: none;}
.small_responsive_row .tab-content > .tab-pane.active > .tab_mobrow{display: block;}
.main_tabsec .small_responsive_row  .nav-tabs{display: block; } 
.mobiCategory .nav-tabs{ display:none;}
.sub_tab_mobtrigger.active {background: #0b0405; color: #fff;}
.sub_tab_mobtrigger {background:#b69a6b; padding: 15px 45px 15px 15px; /*font-size: 16px;*/  color: #fff; display: block; position: relative; margin: 0; cursor: pointer;margin: 0px 15px;}
.sub_tab_mobrow{ display: none; margin: 10px 0px 0px;}
.tab-content > .tab-pane.active > .sub_tab_mobrow {display: block; padding-bottom: 0; padding-top: 10px;}
.sub_tab_mobtrigger i {position: absolute;width: 45px;height: 25px;line-height: 25px;right: 0;top: 0;bottom: 0;margin: auto 0;
text-align: center;}
.sub_tab_mobtrigger i:after {content: "\f067";font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.sub_tab_mobtrigger.active i:after {content: "\f068";}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{/*font-size: 15px;*/}    
.main_tabsec .small_responsive_rewards  .nav-tabs{display: block;}
.small_responsive_rewards .filter_tabsec .tab_mobtrigger{display: none;}    
.delivery-inner-comman{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.delivery-item{margin-top: 10px;}    
.delivery_total.delivery_total_number .delivery_total_left h4{/*font-size: 18px;*/}
.delivery_total.delivery_total_number .delivery_total_left h2{/*font-size: 14px;*/}
.delivery_total_left h3{/*font-size: 15px;*/}
.delivery_total_left span{line-height: 1.2;}
.redeem-item-hea .points{/*font-size: 15px;*/}
.pop-whole-lhs{padding: 80px 15px;position: static;-webkit-transform: translateY(-0%); transform: translateY(-0%); }
.pop-whole-lhs-inner{max-width: 100%;margin: 0 auto;}    
.login-popup .mfp-close, .signup-popup .mfp-close, .signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{right: 0px;background: #fff;opacity: 1;width: 30px;height: 30px;line-height: 30px;}
.signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{padding: 50px 20px 15px 20px;}
.inside-popup-rhs{width: 100%;max-width: 100%;margin: 0 auto;float: none;}
.popup_equalrw{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.pop_title{text-align: center;padding-left: 0;margin: 0 auto 10px auto;}
.pop_title i{position: static;text-align: center;margin: auto;}
.pop_title h2{margin-top: 10px;}
.self_pop_item_loc p{padding: 0 10px; }
.product_chosen_item_left{width: 100%;margin: 10px 0;}
.product_chosen_item_left_second {width: 100%;margin: 0;}
.inn_product_row{margin-top: 20px;}
.inn-product-popup-bg, .inn-product-popup-background{background-image: inherit !important;padding-top: 0;text-align: center;}
.inn-product-popup-inner{max-width: 100%;}
.other-add-row li{width: 100%;}
.account_sec{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.accmenu_sec{width: 100%;}
.accsec_right{width: 100%; padding: 20px 15px;}    
.mainacc_menuout{margin: 0 0 10px; }
.mainacc_menuico{ display: block; position: relative; padding: 9px 0 9px 50px; color: #191919; /*font-size: 17px;*/ cursor: pointer; overflow: hidden; z-index: 1;  }
.mainacc_menuico b{ font-weight: normal;}
.mainacc_menuico:after{content: ""; position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: #ecdfcd; -webkit-transition: all 0.5s; transition: all 0.5s; z-index: -1;}
.mainacc_menuico.active:after{ top: 0;}
.mainacc_menuico i{ position: absolute; left: 1px; top: 1px; /*font-size: 18px;*/ width: 40px; height: 40px; line-height: 40px; background: #ecdfcd; text-align: center; -webkit-box-shadow: 0 0 1px  0 #555; box-shadow: 0 0 1px  0 #555;}
.mainacc_menuico i:before{ -webkit-transition: all 0.5s; transition: all 0.5s;  display: block;}
.mainacc_menuico.active i:before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
.mainacc_menulist{ display: none;}
.mainacc_menulist > li{ display: block; width: auto; border-left: 0; text-align: left; border-top: 1px solid #ddd1c0;}
.mainacc_menulist > li >a{ text-transform: none; padding: 10px 15px 10px 50px;    background: #ecdfcd; color: #191919;}
.mainacc_menulist > li >a:after{ display: none;}
.mbacc_mslidersec{ display: block; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px}
.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a{padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #c3c1c1;
    /*font-family: 'avantgarde_bk_btdemi';*/
    text-transform: capitalize;
    /*font-size: 17px;*/
    line-height: normal;
    display: block;
    height: 100%;
    text-align: center; }

.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow{ background: rgba(0,0,0,0.6); border: 0; width: 30px; height: 30px; position: absolute;
 left: 2px; top: 0; bottom: 0; margin: auto 0; text-align: center; overflow: hidden; font-size: 0;color: #fff; z-index: 1;padding: 0}
.mbacc_mslidersec .slick-next{ right: 2px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before{ 
    content: "\f104"; display: inline-block; font: normal normal normal 14px/1 FontAwesome;font-size: inherit;
    text-rendering: auto;-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; line-height: 25px; font-size: 24px; }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before{ content: "\f105";}
.mbacc_mslidersec .slick-track {
    border-bottom: 4px solid #e7e6e6;
}    
.mbacc_mslide a:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #fdd18c;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}    
.mbacc_mslide.active a{color: #000; font-weight: 600;}   
.mbacc_mslide.active a:before{  left: 0;right: 0;}    
    
.main_tabsec_inner{ padding: 10px 0px;}
.mainacc_menusec .main_tabsec_inner .nav-tabs{ margin-bottom: 10px;}
.main_tabsec_inner > .tab-content > .tab-pane.active > .tab_mobrow{ padding-top: 0; margin-top: 0;}
.mainacc_moblink{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; /*font-size: 16px;*/  
color: #fff; display: block; position: relative; margin: 0 0 10px; cursor: pointer; display: block;}
.mainacc_moblink:hover, .mainacc_moblink:focus{ color: #fff;}
.mainacc_moblink i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.mainacc_moblink i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.mainacc_moblink.active i:after{ content: "\f068";}

.filter_tabsec .tab_mobtrigger.inner_tab_border{background: inherit;border: 0;color: #000;text-align: center;text-transform: uppercase;}
.myacc_filter{padding-bottom: 0;}
.main_tabsec_inner .nav-tabs{ display: block; border: 0;}
.main_tabsec_inner .tab_mobtrigger{ display: none;}
.main_tabsec_inner> .nav-tabs>li{ margin: 0; padding: 0 2.5px;}
.main_tabsec_inner> .nav-tabs>li> a{ text-align: center; /*font-size: 16px;*/ height: auto; padding: 13px 15px; border-radius: 4px;}
.main_tabsec_inner> .nav-tabs>li> a > span > b{ display: none;}
.main_tabsec_inner> .nav-tabs>li > a > i{ display: none;}
.main_tabsec_inner> .nav-tabs>li > em { display: none;}
.acc-inform .form-group [class*="col-md-"]{ margin-bottom: 11px;}
.acc-inform .form-group [class*="col-md-"]:last-child{ margin-bottom: 0;}
.redeem_pro li h2{/*font-size: 30px;*/}
.redeem_pro li h3{/*font-size: 21px;*/}
.redeem_pro li{margin: 0 15px;}
.promation_btn li{width: 100%;margin-right: 0;margin-bottom: 5px;}
.promo-earned-content h4{/*font-size: 20px;*/}
.promo-earned-content h2{/*font-size: 26px;*/}
.promo-earned-content p{/*font-size: 15px;*/}
.abc_code span{/*font-size: 18px;*/}
.bg_top_slider h3{/*font-size: 17px;*/}
.banner-inner h5{/*font-size: 15px;*/}
.banner-inner span{/*font-size: 13px;*/}
.delivery-inner{padding: 20px 0;}
.tnk-delivery{padding: 15px;}
.chk-payment-btn-row .row{display: block;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.chk-payment-btn-row .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.chk-payment-btn-row .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.delevery_popup .btn_sec .row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.delevery_popup .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.delevery_popup .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.back_click{position: static;margin-bottom: 10px;}
/* Thank You */
.tick h2{ /*font-size: 24px;*/}
.tnk-detail h2{ /*font-size: 16px;*/}
.tnk-order h3{ /*font-size: 18px;*/}

/* Contact us */
.contact_wrap .row{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;}
.contact_wrap .contact_left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
.contact_wrap .contact_right{ -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin: 0 0 20px;}
.cms-page h3{ /*font-size: 22px;*/}
.award_galinfo h3{/*font-size: 17px*/}
/*promo*/
.promation_btn{ text-align: center; }
.promo-earned-col {width: 100%; display: block; margin: 0 0 15px; }    
.redeem_popsec .promo-earned-content{ width: 100%; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ width: 35%; }
.innersection_wrap {  padding: 20px 0 0 0;}
.count_num_t{display: none}    
.web_order_typecls li a{padding: 13px 10px}    
.web_order_typecls{margin-bottom: 30px}    
.promo-form h3 span{/*font-size: 16px*/}    
.promo-form h3{/*font-size: 24px*/}    
.mypromo-main-div .myacc_order_details{-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between}    
.mypromo-main-div .myacc_order_details .promo-earned-col{ width: 49%; margin: 0 0 20px 0 }    
    
.mobile-catering-slider{ display: block !important; padding: 0 40px 0; position: relative}  
.row-pgk-list > li, .pkg_gcol {  width: 50%;}     
.mobile-catering-slider button.slick-arrow{ opacity: 1; width: 40px; height: 52px }     
.mobile-catering-slider button.slick-prev { left: -40px;}
.mobile-catering-slider button.slick-next { right: -40px;left: inherit}    
.mobile-catering-slider .slick-track .slick-slide > div{ padding: 0 }    
.mobile-catering-slider .slick-track .slick-slide a{ padding: 10px }  

.reservation-full{padding: 25px 0px;}
.back_click{display: none}
.back_click_mbl {  position: absolute; display: block; top: 10px; left: 10px;} 
.back_click_mbl a{color: #fff;}    
.chk-payment .form-group.custom-tarms-div {  padding: 0 5px 20px;}    
.membership_status img{ max-height: 72px }    
.membership_status span{top: 18px}        
.setting_menu_list, .promo-earned-info:before{ display: none;}    
.accprofile{ padding: 20px 10px 20px;}    
footer { margin: 20px 0 0 0;}    
.promo-earned-col-image {  width: 100%;}    
.promo-earned-info { width: 100%; flex-flow: column wrap; padding: 20px 10px;}    
.home-banner .banner-info img { width: 60%;}

.tab_mobrow .myacc_head_sec .head_right h4 {
    /*font-size: 14px;*/
    margin-top: 5px;
}

.tab_mobrow .myacc_head_sec .head_right::before{
    width: 40%;
}

.head_left h4 {
    /*font-size: 14px;*/
    margin-top: 5px;
}
    
}

@media (max-width: 980px){ 
.product-menu-listing .product-rhs{ 
    background: #fff;
    width: 100%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    z-index: 99;
    max-width: 620px;
    padding: 45px 0 55px 0;}
body.cart-items-open .product-menu-listing .product-rhs{
    -webkit-transform: translateX(0);
    transform: translateX(0);
}    
.product-menu-listing .product-rhs .hcart_dropdown{ overflow-y: auto;
    height: 100%;  }    
.product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout { position: fixed; bottom: 0; left: 0; right: 0;}    
    .product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout a{border-radius: 0}    
    
.product-rhs .product_orders_top{display: flex}        
/*.product-lhs{width: 100%;padding-top: 10px}*/
.spm-lhs .search-rest, .spm-rhs .filter-area{/*font-size: 15px;*/}  
.spm-lhs { width: 300px;}    
.product-lo-top { min-width: 300px;}    
.product-lo-top a { padding: 15px 20px 12px; /*font-size: 14px;*/}    
}
@media (max-width: 880px){ 
.product-lo-top { width: 100%; order: 1; margin-bottom: 15px;}
.search-product-main{order: 2; width: 100%; }    
.backto-rest {width: 48px;}    
.spm-lhs { width: calc(100% - 54px - 172px);}    
}

@media (max-width: 850px){          
.hme-product-inner .title_sec{margin-bottom: 5px;}
.delivery_bg{ background-size: cover; }
.popup_ttsec, .news_postal_popup .popup_ttsec{width: 100%; padding: 30px 15px; height: auto;}
.popup_right{width: 100%; padding: 20px 15px;}
.news_postal_popup .popup_right{width: 100%; height: 300px;}
.awesome_del h2{margin-bottom: 10px;}
.title_sec h2{/*font-size: 29px;*/}
.title_sec small{/*font-size: 14px;*/}
.hme-prt-slidecontent{padding: 10px 15px; top: 0; right: 0; bottom: 0; left: 0;}
.hme-prt-slidecontent-inner p{/*font-size: 13px;*/}

.tnk-header{max-width: 100%;}
.tnk-you .container{ padding-left: 0; padding-right: 0;}
.tnk-delivery .cart_footer.tnk_cart_footer{margin-top: 10px;}
.order-cat-table table{max-width: none;}
.ord-amount-item{max-width: 97%;}
.htopinfo p{/*font-size: 10px;*/}
.htopinfo p span{ /*font-size: 8px;*/}
.htopinfo{width: 100%; text-align: center;}
.htmenu_sec{width: 100%;text-align: center;}
.horder_btn .btn{width: 100%;}
.inn-product-popup{margin-top: 20px;}
.detail-pg-inner{padding: 50px 10px 0;} 
/*upadate package*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; /*font-size: 14px;*/ padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; /*font-size: 26px;*/}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {/*font-size: 16px;*/ margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.tbars header{ height: 147px;}
}
@media (max-width: 780px){

/*h1{ font-size: 35px}
h2{ font-size: 30px}    
h3{ font-size: 26px;}        
h4{ font-size: 21px;} */   
.home-outlets-list {padding: 40px 0 40px 0;}        
.date_list {padding: 20px 15px;}
.time_slot {padding: 20px 15px 20px;}
.add_time {margin: 10px 15px 20px 15px;}
.choose_location {padding: 20px 15px 20px; margin-bottom: 20px;}
.cedele-location {padding: 2px 15px 0px 0px;}
.location-list ul li {/*font-size: 14px;*/ line-height: 1.3; height: auto; padding: 10px 15px;}
.catering_checout .checkout-total .cdd-details-lhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.catering .ttsplitter {margin: 0 auto 10px;}
.catering_checout .checkout-total .checkout-control-group-top {padding: 20px 0px 20px 0px;}
.checkout-total .checkout-control-group-middle {padding: 20px 0px 0px 0px;}
.catering_checout .checkout-total .cdd-details-rhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.event-time .title4 {/*font-size: 16px;*/}
.inner_banner {  height: 250px;}    
.myacc_order_details .current_order{width: 100%}    
.web_order_typecls{ margin: 0 0 20px 0 }    
.order_delivery_item{ padding: 10px 20px 0 20px;}    

.checkout-total{margin: 0 auto}    
.hmenu-login-section {position: static;top: inherit; transform: none;}
.birthday-info-div{margin-bottom: 20px;     padding: 0px 5px 0px 5px;}    

.home-banner .slick-slider .slick-prev:before, .home-banner .slick-slider .slick-next:after{margin-top: -10px;background-size: contain; width: 10px; height: 20px;}
.home-banner .slick-arrow { margin-top: -25px; width: 30px; height: 50px;}
 /*.logo img { width: 110px;}  */ 
   
/*.mobile-login ul { left: 0; right: inherit;}*/   
.header-top-cls {padding: 5px 0;}    
    
    
.f-nav{display: none}    
.mobile-footer-nav { display: block; width: 100%;}    

.footer-nav-info .container-full{flex-wrap: wrap}    
.copyright-section { padding: 10px 10px;}    
.copyright-section p { /*font-size: 13px;*/}    
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link{/*font-size: 15px;*/ display: flex; justify-content: center; align-items: center;}
/*.common-inner-banner{height: 170px} */   
.accsec_right h1 { /*font-size: 30px;*/}
.accsec_right p { /*font-size: 16px;*/}    
.home-banner .btn-act-clss { padding: 11px 16px 8px; /*font-size: 14px;*/}    
.package_details{flex-wrap: wrap; }
.package_details_left { width: 100%;margin-bottom: 10px}
.package_details_right { width: 100%;}
.pdr_right.price-fixed { top: 133px;}    
.abt-cms-lhs { width: 100%; /*font-size: 15px;*/ order: 2;}
.abt-cms-rhs { width: 100%;order: 1; margin-bottom: 60px;}   

    .login-popup-all .full-login-new-body { padding: 20px 40px 45px;  } 
}
@media (max-width: 767px){   
.title1{/*font-size: 25px;*/}
.title3{ /*font-size: 18px;*/}
.htico_search{ position: static;}
.hsearch_sec{ left: 15px; right: 15px; width: auto;top: 50px;}
.banner .container, .delivery .container, .hme-product .container, .outlet .container{ padding-left:0; padding-right: 0; }

.location-mapsec > div{ height: 350px;}
.main_tabsec .nav-tabs{ margin: 0 0 25px; border-width: 1px;}
.main_tabsec .nav-tabs>li>a{ /*font-size: 14px; */padding: 10px 10px;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.video_popup .youtube_player_iframe{ height: 300px;}
.selfpop_mapouter{ height: 280px !important;}
.chekout_cart_bar .cart_info{width: auto;}
.chekout_cart_bar .order-detail-inner-icon{margin: 0 auto;}
.redeem-col{padding: 15px 10px;}
.redeem-item-hea h4{/*font-size: 20px;*/}
.pop-whole-lhs{padding: 40px 15px;}
.inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{padding: 15px 20px;}
.signup-corparatetwo-popup .inside-popup-rhs{padding:50px 20px 15px 20px;}
.popup-header{padding: 10px 0 10px 0;}
.guest_chk_out{margin-left: 0;margin-right: 0;}
.guest_chk_out .btn{white-space: inherit;height: auto;}
.pop_title h2, .order-body h2{/*font-size: 25px;*/}
.pop_title small{/*font-size: 17px;*/}
.self_pop_row{ height: auto;}
.self_outlet{margin-top: 15px; height: auto;}   
.self_pop_col{width: 100%;  margin-bottom: 0;}
.self_pop_col.self_pop_col_right{width: 100%;margin-left: 0;max-width: 100%;}
.self_popup_map_col{ height: auto; margin: 0;}
.self_pop_item_map{width: 100%; margin: 0 auto; display: none;}
.self_popup .self-back{ display: none;}
.self_outlet_inner{/*height: 330px;*/ height: auto;}
.self_pop_item_loc .or-seperator-row{display: none;}
.pouup_in{padding: 30px 20px 20px;}
.redeem_popsec .pouup_in{ padding: 30px 20px 20px;}
.redeem_popsec .title3{ /*font-size: 20px;*/}
.hme-product-inner .ttsplitter{margin-bottom: 0;}
.product_chosen_col{width: 100%;margin: 10px 0;}
.product_chosen_col_inner{margin-bottom: 15px;margin-left: 0;margin-right: 0;}
.accsec_right h1{/*font-size: 27px;*/}
.accsec_right p{/*font-size: 14px;*/}
.accsec_right .ttsplitter_md{margin-top: 20px;}
.redeem_pro li.pro-subscribe button{/*font-size: 16px;*/}
.outlet_inner{padding: 23px 20px 20px 20px;}
.breadcrumb_left{width: 100%;display: inline-block;}
.breadcrumb_right{width: 100%;float: none;display: inline-block;margin: 10px 0;}
.breadcrumb_right .chosen_left{ left: 0; right: auto;}
.breadcrumb_right .re_select{max-width: calc(100% - 73px);}
.bakery_inner h3{/*font-size: 23px;*/}
label{/*font-size: 14px;*/}
.process_right p{/*font-size: 15px;*/}
.product_tag{float: none;}
.product_chosen_col .text-box{margin-left: 0;}

/*----Venue Details page----*/  
.myacc_hea h3 {/*font-size: 20px;*/}
.ttsplitter span {/*font-size: 14px;*/}
.choose_location h4 {/*font-size: 14px;*/}
.choose_location .custom_radio span {/*font-size: 14px;*/ line-height: 30px;}
.choose_location .custom_radio span:before {width: 26px; height: 26px;}
.cedele-location .form-group {margin: 20px 0 20px;}
.date_list h3 {/*font-size: 14px;*/}
.res-datetime-picker ul li dl dd, .catering_days_ul li dl dd {/*font-size: 17px;*/}  
.res-datetime-picker ul li dl.time_inner dd {/*font-size: 20px;*/}
.res-datetime-picker ul li dl dt {/*font-size: 12px;*/line-height: 1;}
.res-datetime-picker ul li dl.cl-next-days span {/*font-size: 14px;*/}
.res-datetime-picker ul li dl.cl-next-days {line-height: 1;}
.time_slot .time {padding: 0px 0px 10px 0px;}
.add_time {margin: 10px 15px 10px 15px;}
.time_slot .order-btn {padding: 20px 0px 15px;}
.time_slot h4 {/*font-size: 14px;*/}
.time_slot .order-btn .no {height: 42px; /*font-size: 20px;*/ padding: 7px 50px; width: 49%;}
.time_slot .order-btn .yes {height: 42px; /*font-size: 20px;*/ padding: 7px 50px; width: 49%;}
.time_slot .add_time .label {float: none;/* font-size: 14px;*/ display: block; width: auto; text-align: center}
.time_slot .time label {/*font-size: 14px;*/}
.location-list ul li {/*font-size: 13px;*/}
/*----Checkout page----*/
.order_details .panel-title>a {/*font-size: 14px;*/}
.order_details .panel-body {padding:15px 10px;}
.package .package_cnt {padding: 0px 0px 15px;}
.package .package-list {padding: 0px 0px 10px 0px; color: #000000;}
.checkout_time {padding: 0px 0px 10px;}
.checkout_time label {/*font-size: 14px;*/} 
.package .title {/*font-size: 14px;*/}
.package .package-list .one {/*font-size: 14px;*/}
.package .package-list .two {/*font-size: 14px;*/}
.package .qty_bx {margin-top: 10px;}    
   
.package .amt {margin-top: 15px;}
.amt span {/*font-size: 14px;*/}
.addon {padding: 15px 0px 15px 0px;}
.addon h4 {/*font-size: 14px;*/}
.addon_list h5 {margin: 10px 0px 15px 0px;}
.addon_list h5 .title {/*font-size: 14px;*/}
.addon_list p {margin: 0px 0px 0px 0px; /*font-size: 14px;*/}
.total_amt {padding: 15px 15px 10px 15px;}
.total_amt .total {/*font-size: 20px;*/}
.total_amt .subtotal {/*font-size: 14px;*/}
.buffet_setup {padding: 10px 0px 15px;}
.buffet_setup h5 .title {/*font-size: 14px;*/}
.buffet_setup p {/*font-size: 14px;*/ margin: 0px 0px 7px 0px;}
.download-quote .btn {/*font-size: 14px;*/height: 30px;}
.catering_checout .redeem-item-hea h4 {/*font-size: 18px;*/}
.catering_checout .redeem-item-hea span {/*font-size: 14px;*/}
.catering_checout .redeem-item-hea .points {/*font-size: 13px;*/}
.catering_checout .redeem_apply .form-control {height: 40px;}
.catering_checout .focus-out .btn_minwid {min-width: 100px; padding-left: 15px; padding-right: 15px;}
.catering_checout .focus-out .btn {/*font-size: 14px;*/ height: 40px;}
/*-----Thank You page------*/
.catering_thak_you .package .title {padding-left: 0px;}
.catering_thak_you .order_details .qtybxs {/*font-size: 14px;*/line-height: 1.6;}
.catering_thak_you .package .amt {margin-top: 0px;}
.catering_thak_you .addon_list h5 .title {padding-left: 0px;}
.catering_thak_you .addon_list h5 {margin: 10px 0px 5px 0px;}
/*-----Categories------*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; /*font-size: 14px;*/ padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px;/* font-size: 26px;*/}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {/*font-size: 15px;*/ margin: 0px 0px 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {/*font-size: 14px;*/}
.event-time .title4 {/*font-size: 15px;*/margin-bottom: 7px;}
.title4 {/*font-size: 14px;*/}
.bts.text-right {text-align: left;}
.pkselected_filtersec label {/*font-size: 14px;*/}
.pkg_gimg img{width:100%;}
.pkg_ginfo h4 {/*font-size: 16px;*/margin: 0 0 10px;}
.pkgselect_dd .chosen-container {/*font-size: 14px;*/}
.pkgselect_dd .chosen-container-single .chosen-single {/*font-size: 14px;*/}
.pkg_gbtsec .btn {/*font-size: 14px;*/ min-width: 170px;height: 42px;}
.pkg_ginfo .btn {/*font-size: 14px;*/ padding: 9px 20px 7px;height: 34px;}  
/*Addon*/
.pkgaddon_checklist li {width: 100%; padding: 0px 3px;}
.pkgaddon_checklist .custom_checkbox span {padding: 15px 15px 15px 70px; /*font-size: 16px;*/}
/*SETUP*/
.package_controltt .custom_radio span {/*font-size: 14px;*/}
.pkgsetup_list .package_formchecklist {padding-left: 0px;margin: 20px 0px 0px;}
.pkgsetup_list .package_formchecklist .custom_checkbox span {/*font-size: 14px;*/}
.package_form_helptext {/*font-size: 14px;*/}
.package_formchecklist .custom_checkbox span b {/*font-size: 14px;*/line-height: 1.5;}
.pkselected_package{margin-bottom: 10px;}   
.pkglist_pricebx > b {/*font-size: 16px;*/}
.pkselected_package .chosen-container-single .chosen-single {height: 42px;line-height: 42px;}
/*promo*/
.promation_btn .btn {/*font-size: 14px;*/ height: 35px; padding: 7px 10px; min-width: 100px; }
.prodet_bansec{ display: block;}
.back_arrow{ width: 43px; height: 43px; line-height: 43px; }
.back_arrow img{ max-width: 11px;}
.prodet_banleft{width: auto; padding: 15px 15px 10px 15px;}
.prodet_baninfo{width: auto;}
.product_modifiers_listinner .modifiers_main_list{ width: 100%;}
.package_checkbx .package_formchecklist .custom_checkbox span{ /*font-size: 16px*/}    
.pkglist_img img{ width: 100% }
.package_checkrow {margin-bottom: 0px;}    
.pkg-detail-top-row{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: wrap column; flex-flow: wrap column; 
    -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
.pkselected_filtersec .form-control{ padding-right: 46px }    
.datelist_bracktime h3, h3.choose-eventtype { /*font-size: 20px;*/margin: 0 0 10px 0;}    
.pkselected_catgrytext {  text-align: center; margin: 10px 0;}    
.checkout-total .checkout-control-group-bottom textarea{height: 110px}    
footer{ margin: 10px 0 0 0 }    
#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls{/*font-size: 16px*/}    
#CartListMdl .Cart-list-mdlheader h2, #PackageEditMdl .Cart-list-mdlheader h2 {/*font-size: 26px;*/ margin: 5px 0 10px 0;}    
.locate-lirow{ display: block }    
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .venue_popup .close-icon-but{ top: 9px; right: 10px; }    
.guest-checkout-popupdiv{margin: 0 -20px;padding: 25px 25px;}    
.login-popup .inside-popup-rhs{padding-bottom: 0}    
.productlist-main-div .products-list-ulmain{justify-content: space-between;}    
/*.productlist-main-div .products-list-ulmain > li{width: 48.5%; margin: 0 0 20px 0;} */   
.common-inner-banner p{/*font-size: 32px*/}    
.chk-payment-title h3, .chk-addonpro-title h3 { /*font-size: 19px;*/}    
.chk-payment { padding: 10px 0px 30px 0px;}    
.mainacc_toptext { padding:0px 0 5px 0;}    
 .products_sidebar_inner ul li a{/*font-size: 14px;*/    line-height: 1.2;}    
}
@media (max-width: 680px){ 
.delivery_bg{ width: 100%;}
.innerproduct-col{width: 100%; margin: 0 0 15px;}
.cart_body{padding: 10px;}
.cart_left{/*width: 100% !important;*/ text-align: left;}    
.tnk-delivery .cart_row{padding-top: 5px;}
.cart-header-first .cart_right{margin: 20px 0 0 0; text-align: left; }    
.menu_icon{margin-right: 0;}    
.delivery-inner{ padding: 0;}
.delivery-item{ width: 100%; max-width: 100%; padding: 20px 15px 16px;  margin-top: 0; border: 0;border-bottom: 1px solid #f0e8dd;}
.delivery-item:last-of-type{border-bottom: 0;}
.hme-prt-row .slick-slider, .detail-pg-slider .slick-slider{padding: 0 0px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next{z-index: 1; background: #f5f5f5;}
.detail-pg-slider-comman .slick-prev, .detail-pg-slider-comman .slick-next{ z-index: 1;background: #f5f5f5; }
.inn-product-popup-inner{max-width: 100%;}
.accpoints_bxin{padding: 20px 13px 55px;}
.accearn_pointtop span{/*font-size: 35px;*/}
.accsec_right .ttsplitter span{z-index: 1;}
.ord-amount .row{width: 100%;}
.ord-rec-sec li{width: 100%; max-width: 100%;}
.ord-amount [class*='col-']{padding-right: 0;}
.ord-amount{padding: 10px 0 0 10px;}
.order-cat-table{padding: 0 10px;}   
.order-cat-hea{max-width: 100%; padding: 8px 10px; margin-top: 10px;}
.ord-amount h2{/*font-size: 22px;*/}
.order-cat h2{/*font-size: 18px;*/}
.ord-amount-item{max-width: 100%;}
.cur-ord-rec{float: none;  padding: 0 10px;   width: 100%;  max-width: 100%; }
.cur-order-del{ max-width: none; padding: 0 10px; }
.cur-ord-rec li{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.click_view{text-align: center; margin: auto; padding: 0 10px; left: 0; right: 0;}
.cur-order-del li{margin-bottom: 10px; width: 100%; padding-left: 0; /*font-size: 19px;*/}
.cur-order-del li.cur-order-del-time{ width: 45%; text-align: right; margin-bottom: 0;}
.cur-order-del li.cur-order-del-date{ width: 55%; margin-bottom: 0;}
.cur-order-del li a.btn{ display: block;}
.cur-ord-rec-desktop, .click_view_desktop{ display: none;}
.cur-ord-rec-mobile{ position: relative; padding: 0 0 10px; border-bottom: 1px solid #efefee; margin: 10px 0;}
.myacc_dropmenu{position: absolute; width: 100%;left: 0;top: 100%; padding: 15px;}
.myacc_dropmenu::after{top: -16px; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 33px; -webkit-transform: rotate(448deg); transform: rotate(448deg);}
.check-pg-slider .slick-slider{padding: 0;}
.check_pg_inner .slick-prev, .check_pg_inner .slick-next{background: #f5f5f5;}
.banner-text-box-left{width: 100%; margin-bottom: 10px;}
.banner-inner h5{ height: auto;}
.banner-inner span{ height: auto;}
.banner-text-box-right{width: 100%;}
.banner-text-box-right .btn_minwid{margin-bottom: 0;}
.banner-text-box{padding: 20px 18px 20px 20px;}
.banner-right-content .btn{min-width: 100%;}    
.checkout-total .checkout-control-group-bottom textarea{height: 100px;}
.web_order_typecls li a{  padding: 15px 5px;}    
.promo-earned-info{     -webkit-box-orient: vertical;     -webkit-box-direction: normal;     -ms-flex-flow: column;     flex-flow: column; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;text-align: center}    
.promation_btn .button {margin: 5px 5px 0 0;padding: 10px 22px 8px;/*font-size: 14px;*/}    
.product-details .row .cart_left{width: 100%; padding: 0}    
.product-details .row .cart_right{width: 100%; padding: 0; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center }    
.product-details .row .cart_right .cart_price{ margin: 0 0 0 10px }    
.function-rooms ul li{ width: 49% }    
.function-rooms ul li:nth-child(3n){ margin-right:2% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }    
a.view-rooms img{ min-height: 190px }    
.birthday-info-top h3 img{ width: 26px}
.birthday-info-top h3{/*font-size: 24px*/}    
.checkout-body-section { padding: 10px;}    
.checkout-right-body-section { padding:10px 10px 10px 10px;}    
.ourrest_info h4 { /*font-size: 18px;*/}    

.login-popup-all .form-group a:before{ width: 100px; top: 18px; }
}

@media (max-width: 580px){ 
.Products .breadcrumb{ padding: 64px 0 0 0;}
.Products .bread_space { margin: 64px 0 0; }
.htico_cart{ padding-right: 3px;}   
.mobile_account_item > a{ /*font-size: 15px;*/padding: 14px 40px 10px 40px;}
.mobile_account_item > a > i{ width: 20px; height: 20px; }  
.mobile_mainacc_menutrigger, .mobile_account_menu_click i.arrow_myaccount{ background-size: 16px auto; width: 45px; height: 49px;}
.mobile_account_item a.myacunt_act{padding: 15px 35px 11px 35px; /*padding: 12px 45px 12px 45px;*/}
.mobile_account_delivery_item a, .mobile_account_item_login > a, .mobile_mainacc_menulist li a{  /*font-size: 17px;*/}

.loc-cont-part{ display: none;}
.bakery_row_fixed{ top: 150px;}  
.tbars .bakery_row_fixed{ top: 184px;}
.footer-inner [class*='col-']{width: 100%;margin-bottom: 10px;}
.footer-inner h4{margin-bottom: 10px;}
.chk-payment-col-radio li{margin: 0 0 10px 0;width: 100%;max-width: 95%;}
.chk-payment-col-radio li:last-child{margin-bottom: 0;}
.self-back{top: 5px;/*font-size: 16px;*/}
.pop-whole-lhs{padding: 40px 15px 20px;}
.pop-whole-lhs h3, .signup-popup .pop-whole-lhs h3{/*font-size: 30px;*/}
.pop-whole-lhs p{/*font-size: 16px;*/}
.order_delivery_item li:last-child{margin-bottom: 0;}
.order-body{padding: 30px 0;}
.video_popup .order-body{padding: 50px 30px 25px 30px;}
.self_outlet h2{/*font-size: 21px;*/}
.self_outlet_inner li a{/*font-size: 14px;*/}
.self_pop_item_map{width: 100%;}
.self_pop_item_map img{width: 100%;}
.self_pop_item_loc .loc_btn{height: 52px;/*font-size: 14px;*/min-width: 100%;}
.self_pop_item_loc p{/*font-size: 15px;*/}
.chosen_adn_left span, .product_chosen_col_right .custom_checkbox span{/*font-size: 14px;*/}
.inn_product_hea p{margin-bottom: 10px;/*font-size: 13px;*/}
.prd_chosen_row{padding: 20px 10px 15px 10px;}
.prd_chosen_sub_item_left{width: 100%;margin: 5px 0;}
.prd_chosen_sub_item_right{width: 100%;}
.prd_chosen_sub_item_right button{max-width: 100%;}
.footer-inner .news-letter button{padding: 15px 12px;/*font-size: 14px;*/}
.footer-inner h4{/*font-size: 17px;*/}
.footer-inner ul li a{/*font-size: 14px;*/}
.earned_points li h2{/*font-size: 20px;*/}
.tag span{/*font-size: 12px;*/}
.htmenu_sec > li{padding: 0 15px; }
.megaimg_item{width: 100%;  max-width: 364px;}
.pkselect_tabsec > .nav-tabs>li>a {/*font-size: 15px;*/ padding: 10px 10px;}
.bts .form-group {width: 100% !important;}
.bts label {margin: 5px 0px 10px; display: block;}
.pkselected_filtersec label { display: block;  width: 100% !important; margin:5px 0px 0px;padding: 0; text-align: center}
.pkselected_package .form-group { width: 100%;} 
.pkselected_filtersec .time-label {display: block;  width: 100% !important;text-align: left; margin:5px 0px 5px;}
.pkselected_break .form-group {width: 100%;} 
.chk-payment-btn .btn {padding: 10px 22px; height: 37px;/*font-size: 14px;*/}
.chk-payment-btn .btn_download {/*font-size: 14px;*/ padding: 10px 20px 10px;height: 35px;} 
.inner_banner {    height: 200px;}
.mbacc_mslide a{ padding: 15px 10px;    /*font-size: 16px*/}
.web_order_typecls li a h5 {/* font-size: 14px;*/}
.chk_hea > span{display: block; float: none; clear: both; margin: 5px 0 0 0}  
.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a{/*font-size: 17px*/}
    span.myaccount_separate{height: 14px}
/*21-5-19*/
.reservation-summary ul li span{/*font-size: 13px;*/}
.reservation-summary{padding: 15px;}
.reservation-summary-full{background: none; padding-top: 0px; padding-bottom: 0px;} 
.reservation-full{padding: 20px 0px;}   
.reservation-full h2{margin-bottom: 15px; /*font-size: 30px;*/}
.reservation-summary-full h3{/*font-size: 28px;*/ margin-bottom: 15px;}
.go-back a, .continue a{/*font-size: 17px;*/}
.reservation-summary-button{max-width: 425px; margin-left: auto; margin-right: auto;}
/*.reservation-counter, .choose-outlet, .choose-datetime-full, .special-instructions{background-color: transparent; padding-top: 0px;}*/
.reservation-counter-full{display: block;}
.reservation-counter-left, .reservation-counter-right{float: none; width: 100%;}
.reservation-counter-single:first-child{margin-top: 30px; margin-bottom: 30px;}
.goto-chkpage-cls a, .viewmenu-btn-row a{ width: 100%; margin: 0}
.goto-chkpage-cls a:first-child, .viewmenu-btn-row a:first-child{ margin-bottom: 5px }    
.popup_ttsec, .news_postal_popup .popup_ttsec{ min-height: inherit}    
.two-button-row{-ms-flex-wrap: wrap;flex-wrap: wrap}    
.delevery_popup .go_second{margin-top: 5px}  
.products_sidebar_inner .no-slide-res{display: none}    
.products_sidebar_inner .slick-slider{ display: block;padding: 0 30px; }
    
.reservation-summary-button .go-back, .reservation-summary-button .continue{width: 100%;} 
.reservation-summary-button .continue{margin-bottom: 10px; margin-top: 5px;}
.reservation-summary-button{display: -webkit-box;display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap-reverse; flex-wrap: wrap-reverse;}
.common-top-div.productpage-main-div {  padding-top: 155px!important;}    
.left-input-div, .right-input-div {max-width: 48.5%;}    
.subtle{display: none}    

/*.checkout-heading { padding: 15px 10px 0px;}*/
.cart_row.tnkorder-first > div h5 { /*font-size: 14px;*/} 
.cart_row.tnkorder-first > div p {/* font-size: 13px;*/}    
.congrats-inner { padding: 30px 15px 20px;}    
    
.choose-option-hover{display: none}    
.mobile-choose-option-hover{display: block} 
.mobile-choose-option-hover ul li ul{padding: 5px 0}    
.mobile-choose-option-hover ul li ul li a{/*font-size: 15px*/}    
.pdr_right.price-fixed { top: 118px; left: 0 !important;}  
.fix-only-lhs h3{text-align: left; margin-bottom: 10px; /*font-size: 15px;*/}    
.pdr_right.price-fixed h4 { /*font-size: 16px;*/}    
.abt-cms-lhs h2 { /*font-size: 35px;*/}    
.abt-cms-lhs { /*font-size: 14px;*/}    
.outletul>li {  width: 100%; margin: 0 0 30px;} 

.login-popup-all .order_delivery_item li .header-ordernow-single-img img {
    height: 40px;
}

.login-popup-all .full-login-new-body {
    padding: 20px 15px 45px;
}

}
@media (max-width: 550px){    
.bg_top_slider h3{ /*font-size: 12px;*/}
.bg_top_slide p { /*font-size: 11px;*/ }
.promo-earned-col-center{ display: block; }
.promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.promo-earned-content{ display: block; width: 100%; }
.promo-earned-content p {/*font-size: 13px;*/ }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.redeem_popsec .promo-earned-content{ width: 100%; display: block; }
.redeem_popsec .promo-earned-content h4 {/*font-size: 20px;*/ }
.redeem_popsec .promo-earned-content h2, .redeem_popsec .promo-earned-content h2 span{/*font-size: 25px;*/ max-height: inherit; overflow: visible;}
.promo-earned-col-center .innerproduct-item-image {display: block; width: 100%; height: 250px; }
.award_galcol{width: 100%; padding: 0}    
.award_galcol:last-of-type{ margin-bottom: 0 }    
.award_galinfo{margin: 0;    top: 70px;}
.banner-info h2, .featured-products-main h2, .serving-passionately-right h3{/*font-size: 28px*/}
.hmenu_list{ width: 200%; max-width: inherit}
.mypromo-main-div .myacc_order_details .promo-earned-col { width: 100%;  margin: 0 0 15px 0;}    
.filter_tabsec .nav-tabs>li>a:after{max-width: 50%}    
.hook{margin-bottom: 30px} 
.choose-outlet, .special-instructions, .choose-datetime-full{padding-left: 10px; padding-right: 10px;}
.featured-products-main > ul > li{width: 100%; margin: 0 0 20px 0}    
.tnk-chk-order .button { padding: 15px 10px 15px; }     
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{width: 100%; margin: 0 0 20px 0;}    
.tnk-order { padding: 20px 15px;}    
}

@media (max-width: 480px){ 
.hcart_scrollarea .syd_merge .form-group222 {
  width: 100%;
}
.hcart_scrollarea .timeslot_info_main .syd_time.delivery_submit_cls{
    width: 100%;
}
.hcart_scrollarea .timeslot_info_main .syd_date{
    width: 100%;
}
.menu_icon{ width: 30px;}
.innerproduct-row{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; text-align: center;}
.innerproduct-item-text{margin-bottom: 15px;}
.spilter{text-align: center; margin: 5px auto; vertical-align: middle;}
.two-links a{ margin: 0 2px; vertical-align: middle;}
.popup-body .sign_reg a{white-space: inherit; height: auto; /*font-size: 14px;*/}
.news_subscribe p{/*font-size: 14px;*/}
.pop_title h2{/*font-size: 26px;*/}
.news_postal_popup .pop_title p{/*font-size: 14px;*/ line-height: normal;}
.title1{/*font-size: 20px;*/}
.inn_product_hea_left h3{/*font-size: 18px;*/}
.modal-dialog-tgrfromhome .inn-product-popup .product-detail-topmaindiv .productdetail-rightside-maindiv .inn_product_hea .inn_product_review
.inn_product_hea_left h3{
    /*font-size: 18px;*/
}
.text-box textarea{/*font-size: 14px;*/}
.popup_social_icon{width: 100%; padding-left: 0;}
.popup_social_icon li a{padding: 0 8px;}
.popup_social span{color: #fff;}
.order-cat-table h4{/*font-size: 14px;*/}
.order-cat-table table td{/*font-size: 14px;*/}
.main_tabsec_inner> .nav-tabs>li> a{ /*font-size: 12px;*/}
.delivery_time [class*='col-']{width: 100%;}
.chosen_adn_left{width: 280px;}
.delivery_total.delivery_total_number{padding-bottom: 0;}
.redeem_apply .btn{ min-width: auto; padding-left: 10px; padding-right: 10px;}
.redeem_apply label{ /*font-size: 12px;*/}
.redeem_apply.focused label{ top: -7px;}
.redeem_popsec .btn{ height: 46px; }  
.order_delivery_item li .header-ordernow-single-img img { height: 50px;}    
.order_delivery_item h3{/*font-size: 14px*/}    
.footer-bottom-left{ width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center;  margin: 10px 0 10px 0;}    
.footer-bottom-right{width: 100%;    display: -webkit-box;    display: -ms-flexbox;    display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}    
.footer-bottom-right ul{padding: 0}    
.footer-bottom-right h3{margin: 0 5px 0 0}    
.featured-products-section{padding-bottom: 20px}    
.web_order_typecls li a h5 { /*font-size: 13px;*/}    
.delivery_total_text::before{display: none}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {/*font-size: 14px;*/}    
.catering_days_ul li dl dt{/*font-size: 10px*/}    
.catering_days_ul li dl.cl-next-days span{/*font-size: 11px*/}    
.event-type-maindiv.two-button-row a {  /*font-size: 16px;*/ padding: 15px 10px;}
.ownvenue-inner-div{max-width: inherit; margin: 20px auto 0}   
.order-select-brktime ul .time-cls{-ms-flex-wrap: wrap;flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center}    
.order-select-brktime ul .time-cls label{width: 100%; text-align: center; margin: 0 0 5px 0}    
.more-time-slots{display: -webkit-box;display: -ms-flexbox;display: flex}    
.more-time-slots a{ -webkit-box-flex: 1; -ms-flex: 1; flex: 1; padding: 10px 10px 10px; /*font-size: 16px*/}    
.checkout-total .form-group.controls-three > div{ width: 100%; margin: 0 0 10px 0 }    
.checkout-total .form-group.controls-three > div:last-of-type{ margin-bottom: 0 }    
.catering_checout .checkout-total .checkout-control-group-top { padding: 10px 0px 20px 0px;}    
.checkout-total .checkout-control-group-middle .form-group .input_area{ width: 100%;margin-bottom: 10px}    
.chk-payment-btn-row.checkout-continue-cls .row-replace, #PackageEditMdl .chk-payment-btn-row .row-replace{-ms-flex-wrap: wrap;flex-wrap: wrap} 
.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls{ width: 100%} 
.chk-payment-btn-row.checkout-continue-cls .row-replace .go_second{ margin-top: 5px }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .button{ /*font-size: 18px;*/ padding: 14px 10px;}
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls:first-of-type{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 5px}   
.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.phone-show-dskp{ display: none;}
.phone-show-mbl{display: inline-block}    
.locate-lirow .phone-show-mbl a{ color: #000 }    
.locate-lirow.active .phone-show-mbl a{ color: #fff }  

.backto-rest {margin-bottom: 5px;}    
.spm-lhs {width: calc(100% - 54px); margin-bottom: 5px;}   
.spm-rhs {width: 100%; margin-left: 0;}   
/*.product-menu-listing { padding: 10px 0 10px 0;}*/

.search-product{padding-top: 20px}  
.common-filter ul { justify-content: space-between;}    
.common-filter ul li { width: 49%;    margin: 0 0 10px;/*font-size: 14px;*/}    
.mainacc_toptext p { /*font-size: 15px;*/}    
.common-inner-banner span { line-height: 1; margin-top: 5px;}    
.package_details_right_inner{flex-wrap: wrap}    
.pdr_left{width: 100%}    
.pdr_right{width: 100%; margin-top: 15px}
.pdr_right h4 { margin-bottom: 10px;/*font-size: 20px;*/}    
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{/*font-size: 15px*/} 
.product-lhs .product-edit-half a{  /*font-size: 15px  !important;*/padding: 12px 10px 12px !important;}    
.product_acc_grp .accordion .accordion_title span{/*font-size: 11px*/} 

.login-popup-all .order_delivery_item{ justify-content: center; }   
.login-popup-all .order_delivery_item li{ width: 100%; max-width: 48%; margin: 0 10px 10px 0;}
.login-popup-all .order_delivery_item li:nth-child(2n){ margin: 0 0px 10px;}
.login-popup-all .order_delivery_item li:nth-child(3n){  margin: 0;}
.login-popup-all .controls.two-links { margin-top: 10px;}
.login-popup-all .form-group a:before { width: 90px; top: 15px;}
} 

@media (max-width: 420px){
.hcart_tt{ padding-left: 20px;}
.hcart_tt h3{ /*font-size: 16px;*/}
.hclear_cart{ /*font-size: 12px;*/}
.location-list ul li {/*font-size: 12px;*/}
.cedele-location P {/*font-size: 12px;*/}
.catering_checout .checkout-total .cdd-details-rhs { padding: 30px 10px 25px 10px;}
.res-datetime-picker .tag {left: -7px;width: 125%;}
.number-break .back_bx {left: 10px; width: 42px; height: 42px; /*font-size: 12px;*/ padding: 26px 6px 6px;}
.number-break .back_bx:after {top: 6px;/*font-size: 16px;*/}
.number-break .break-time {margin-top: 50px;}
.pkselect_tabsec > .nav-tabs>li>a {/*font-size: 13px;*/ padding: 7px 7px;}
.title4 {/*font-size: 14px;*/}
.pkglist_infott h3 {/*font-size: 16px;*/}
.pkglist_infott p{/*font-size: 13px;*/}
.package_checkbx .package_formchecklist .custom_checkbox span {/*font-size: 13px;*/}
.package_checkbx .package_formchecklist .custom_checkbox span b {/*font-size: 13px;*/}
.pkglist_info p{/*font-size: 13px;*/}
/*.web_order_typecls li a h5 { font-size: 11px;} */   
.main_tabsec .nav-tabs>li>a { /*font-size: 13px;*/ padding: 5px 4px;}        
.function-rooms ul li{ width: 100%; margin:0 0 10px 0 }    
.function-rooms ul li:nth-child(3n){ margin-right:0% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }        
.function-rooms ul li:last-child{ margin-bottom: 0 }    
.function-rooms ul li a{height: auto}    
a.view-rooms img{min-height: inherit; max-height: inherit; height: auto}    
.catering_days_ul li dl.cl-next-days span {/*font-size: 10px;*/}    
.catering_days_ul li dl dt { /*font-size: 9px;*/}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {/*font-size: 12px;*/}    
#PackageEditMdl .pkselected_filtersec .row-replace{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-flow: column;flex-flow: column} 
.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
/*.hcart_round { top: 13px; }*/
 .product-lhs-inner .products_list { padding: 10px 12px 20px 12px;} 
}

@media (max-width: 380px){  
.tbars header{ height: 154px;}
.mobile_mainacc_menutrigger{ background-size: 12px auto; width: 25px;}
.mobile_account_item > a{padding: 14px 25px 10px 40px;  /*font-size: 14px;*/}
.mobile_account_item > a > i{ left: 5px; width: 22px; height: 22px;}    
.mobile_mainacc_menulist li a{ padding: 12px 5px 12px 35px; /*font-size: 14px;*/}
.mobile_mainacc_menulist .setting_menu_list_icon{ left: 7px;}
.mobile_account_item_login > a{ padding: 14px 0 10px 35px;}
.mobile_account_menu_click{     padding: 12px 30px 12px 10px; /*font-size: 14px*/}
    
.mobile_account_menu_click i.arrow_myaccount{ background-size: 12px auto; width: 32px;}
.delivery_total_left{float: none; margin-bottom: 10px;}
.ttsplitter span{z-index: 1;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{/*font-size: 14px;*/}
.mobile-logo-div{ max-width: 160px }
.addon_poptop{padding: 0}    
.addon_popbot{ margin: 0 }    
.addon_popbot .row-replace{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }    
.addon_linfo, .addon_lright, .pkselected_package {width: 100%;}    
#CartListMdl .total_amt { padding: 15px 15px;}    
.number-break { padding: 15px 5px 15px 5px;}    
.number-break .making-catering { padding: 60px 0 0;}   
.number-break .event-time{padding: 5px 0}    
.container { padding-left: 10px; padding-right: 10px;}    
a.go-bck-icon{top:-4px}    
h3.choose-eventtype { padding-left: 20px; }  
/*.hcart_round { top: 13px; }*/
/*.web_order_typecls li a h5 { font-size: 10px;} */  
}
@media (max-width: 360px){ 
.reservation-summary ul li span{/*font-size: 11px;*/}
.reservation-summary{padding: 10px;}
.reservation-full h2{margin-bottom: 10px; /*font-size: 30px;*/}
.reservation-summary-full h3{/*font-size: 26px;*/ margin-bottom: 12px;}
/*.web_order_typecls li a h5 { font-size: 9px;}  */ 
}

.close-icon-but {background: #000;}

/************************************** MIN WIDTH CSS START *************************************/


@media (min-width: 992px) and (max-width: 1050px) {
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p {
        right: 480px;
        left: inherit;
    }
}

@media (min-width: 1051px) and (max-width: 1350px) {
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p {
        right: 620px;
        left: inherit;
    }
}

@media (min-width: 1850px) {
  .menu-section-inner {
    max-height: 788px;
  }
}

@media (min-width: 1601px) {
  .menu-section-inner {
    max-height: 665px;
  }
}

@media (min-width: 1400px) {
  .menu-section-inner {
    max-height: 530px;
  }
}

@media (min-width: 1350px){
    .product-lhs .prodetailinner-main-div .modal-full-xl .modal-content .modal-body{
        padding: 35px 0 35px;
    }
}

/************************************** media CSS START *************************************/

@media (min-width: 2900px){
    .reservation-fullmain-div, .contactusmain-top-div, .career-maindiv, .press-maindiv, .productpage-main-div{
        /*background-size: cover;*/
    }

    .hsign_sec a{ line-height: 3; }
}

@media (min-width: 1600px){
    .reservation-Rhs-heading h4{ font-size: 22px; }
    .reservation-Rhs-heading h2{  font-size: 35px; }
    .menumain-div .menu-blckdiv h3{ font-size: 38px; }
    .controls.two-links a, .product-short-description{  font-size: 15px; }
    .checkout-main-container{ max-width: 900px; margin: 0 auto; }
    .reservation-Rhs-heading h4{ font-size: 18px; }
    .contactusmain-top-div .common-inner-banner p{ font-size: 42px; }
    ul.hmenu_list > li > a, .hsign_sec a, .cafe-lhs-filter .filter-act{ font-size: 14px; }
    .product-title h5{ font-size: 20px; }
    .product-price h3{ font-size: 26px; }
    .products-ordernow-action .button{ font-size: 16px; }
    .common-inner-banner h2{ font-size: 38px; }
    .innerproduct > h3{ font-size: 18px; }
    .product-menu-sidebar .menu-section-left-inner .menu-list-intvl a{ font-size: 16px; }
    .product-menu-listing .addcart_row .btn{ font-size: 15px; }
    .filter-act:after{ font-size: 28px; }
    .hsign_sec>span{ font-size: 17px; }
    .h-r-i-heading{ font-size: 52px; }
    .homebanner-content-inner h2{ font-size: 58px; }
    .homebanner-content-inner a{ font-size: 15px; }
    .homebanner-content-inner h4{ font-size: 22px; }
    .home-restaurant h2, .home-review h2, .home-menu h2{ font-size: 38px; }
    .h-r-i-sub-heading{ font-size: 20px; }
    .menumain-div .inner-banner-content p{ font-size: 42px; }
    .home-popup-block .order-body .H-popup-inner-content h2{ font-size: 52px; }
}

@media (max-width: 1600px){
    .checkout-main-container{  max-width: 900px;  margin: 0 auto; }
}

@media (max-width: 1400px){

  .brunch-main{ background-size: cover;}

  .menu-blckdiv, .contact_wrap, .privacy-policy-div{ max-width: 780px; }

}

@media (max-width: 1350px){
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p {
        right: 450px;
        left: inherit;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
    .prd_chosen_sub_row .prt-sct{
      left: inherit;
      right: inherit;
     }

    .prodetailinner-main-div .productdetail-rightside-maindiv .compo_addcart_cls .compo_add_div{
        width: 50%;
     }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec {
        display: block;
        max-width: 592px;
        padding: 0;
        text-align: center;
        margin: 0 auto;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec {
        max-width: 100%;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .product-detail-topmaindiv .productdetail-rightside-maindiv{
        float: none;
        width: 100%;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .prodet_bansec .inn-product-img-bg .slick-slider .slick-dots li {
        max-width: 148px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .productdetail-rightside-maindiv .prd_chosen_sub_row p.prt-sct {
        top: 3px;
        color: #535353;
        left: inherit;
        margin-bottom: 0;
    }

    .product-detail-box{ width: 100%; }

}

@media (max-width: 1300px) {
    .menu-section-innerr { max-height: 430px;}
    .f-nav { width: 820px;}
    .f-nav > ul > li{ padding-right: 0;}

    .promation_btn .promation_btn-one{ width: auto; }
}

@media (max-width: 1200px){
    .memb-foot{ margin: 15px;}
    .membership-banner-inner h3 {   max-width: 585px;  text-align: right;    }
    .member-prog-bar, .membership-banner-inner h2 {  margin-bottom: 0;    }
}

@media (max-width: 1199px){

    .tab_mobrow .myacc_head_sec .head_right h4 { margin-top: 5px; }
    .tab_mobrow .myacc_head_sec .head_right::before{  width: 40% ;}

    .head_left h4 { margin-top: 5px; }

    .hcategory_sec { background: #ebebeb; position: relative; }
    .hcategory_sec.header { margin-top: 0px;  margin-bottom: -10px; }

    .hcategory_selected_text {
        padding: 12px 180px 12px 15px;
        color: #000;
        text-transform: uppercase;
        display: block;
        position: relative;
        font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
        font-family: 'caviar_dreamsbold';
    }
    .hcategory_trigger {
        position: absolute;
        right: 15px;
        top: 17px;
        -webkit-transition: all .4s;
        transition: all .4s;
        line-height: 1.2;
        padding-right: 30px;
        z-index: 11;
    }

    .reservation-fullmain-div, .contactusmain-top-div, .career-maindiv, .privacy-policy-maindiv, .press-maindiv,
    .checkout-main-div, .thankyou-main-div{
        background: url(../images/home-res-bg.jpg) repeat-y;
        background-size: contain;
        background-attachment: fixed;
    }
   
    .product-menu-full { top: 62px; }

    .cdd-details-lhs.fl .checkout-control-group-top{ padding-top: 10px; }  

    .hcategory_trigger:after{ 
        position: absolute; background: url(../images/menu_ico.png) no-repeat; background-size: contain; width: 22px;
        height: 15px; content: "";  right: 0; top: 2px;
    } 
    .hcategory_trigger.active:after{ 
        background: url(../images/close-block.png) no-repeat; background-size: contain; width: 16px;
        height: 16px; top:1px; right:4px;
    }
    
    .hcategory_menu{background: #f4f4f4; position: absolute; left: 0; top: 0; right: 0; max-height: 400px; opacity: 0;
        visibility: hidden; -webkit-transition: all 400ms; transition: all 400ms; overflow: auto;}
    .hcategory_menu .mCSB_scrollTools{ opacity: 1;}
    .hcategory_menu .mCSB_scrollTools .mCSB_draggerRail{ /*background-color: #6e5546;*/}
    .hcategory_menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ /*background-color: #191919;*/}
    .hcategory_menu.open{ opacity: 1; visibility: visible;z-index: 9;}
    .hcategory_menulist{ list-style: none; padding: 0; margin: 0;}
    .hcategory_menulist > li > a{background: #f8f8f8; color: #000; padding: 12px 45px 12px 15px; 
        display: block; text-transform: uppercase; font-family: 'caviar_dreamsbold';}
    .hcategory_menulist > li > a:hover{ color: #fdd18c;}
    .hcategory_submenu{ list-style: none; padding: 0; margin: 0; }

    .hcategory_submenu >li >a{ color: #fff; padding: 12px 15px 12px 35px; display: block; 
        font-family: 'caviar_dreamsbold'; }
    .hcategory_submenu >li >a:hover{ color: #fdd18c;}
    .hcategory_submenu {background: #561c4e;}
    .hsearch_trigger:before{ top: 36px; right: 12px;}
    .hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
    .hsearch_bx .re_select .Select-input{ height: 49px;}
    /* Sticky Header */

    .hmenu_sec{display: none;}

    .menu_icon.trigger_menunav_act{ 
        display: block; width: 30px; height: 19px; margin: 3px 0px; padding: 0px 0px; cursor: pointer;
    }
    .mobile_accountdel_body, .mobile-order-nowbtn{  display: block; }

    .menu_icon .icon-bar{ 
        display: block; background-color: #fff; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;
    }

    .menu_icon .icon-bar+.icon-bar {   margin-top: 6px;  }  

    .logo {   width: 85px; height: 80px; position: absolute; left: 0; right: 0; margin: auto; }
    .logo-main-section {  height: 75px; }
    .logo img{  height: 100%; width: 100%; }

    .f-nav { width: 700px; }

    .mobile-menu .mobile-menu-body .f-socail-btm{ margin: 0; }

    .hcategory_sec .hcategory_menu.open{ top: 45px; }

    ul.hmenu_list>li>a { padding: 12px 15px;  text-align: left; border-bottom: 1px solid #e5e5e5; color: #333; }
    ul.hmenu_list>li {  display: block; z-index: 3; }
    ul.submenu_list { left: 0; }
    ul.hmenu_list>li.open-submenu a{  border-bottom: 1px solid #e5e5e5; }
    .mobile_hmenu_list ul.submenu_list li a{ border-bottom: 1px solid #e4e4e4; }
}  

@media(max-width: 1105px){
    .order_btns .button {  padding: 12px 5px; }
    
    .mainacc_menusec .main_tabsec_inner .nav-tabs {  margin-bottom: 25px; }
}

@media (max-width: 1100px){
    .product-menu-listing .product-lhs { padding-top: 10px; }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .addon_rewive_product_topmaindiv .addon-prolist-topdiv {
        width: 100%;
        padding-right: 0;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .addon_rewive_product_topmaindiv .products-reviews-maindiv{
        width: 100%;
        padding-top: 40px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .addon_rewive_product_topmaindiv .addon-prolist-inner .addonproducts-list-ulmain{
        padding-right: 0;
    }

    .home-banner .banner-info img { width: 200px; }

    .featuredpro-section .featured-products-main .products-list-ulmain > li{ width: 49%;  margin: 0 0 20px; }
    .featuredpro-section .featured-products-main .products-list-ulmain {  justify-content: space-between; }

    .home-main-div .home-testimonials .testimonials-title-text {  padding-bottom: 0; }
}

@media (max-width: 1080px){
    body{
        background-size: cover;
    }
    .home-main-div .header-top-cls .logo-main-section .hmenu-login-section .hmenu-login-act li .desktop-login{
        display: none;
    }

    .desktop-login {  display: none; }

    .home-main-div .home-testimonials { padding: 50px 0 50px; }
    .home-main-div .home-testimonials .testimonials-title-text h2{  margin-bottom: 0; }

    .product-lhs .menu-section-mobile-inner .menu-list-intvl { height: 100%;  padding: 0 30px; }

    .product-menu-full{ top: 124px; }

    .product-menu-listing .product-lhs .menu-section-mobile-inner{
        position: relative; top: inherit;  padding: 0;  position: -webkit-sticky;  position: sticky;  top: 120px;
    }

    .f-nav {  width: 600px; }
    /*.menumain-div .menu-blckdiv h3:after{ top: 50px; }*/
    .hcategory_sec { display: block; }

    .product-menu-left{ display: none; }

    .product-menu-listing .product-lhs-container{  width: 100%; }

    .menumain-div .menu-blckdiv .products-list-ulmain>li {  width: 32%;  margin: 0 2% 20px 0; }
    .menumain-div .menu-blckdiv .products-list-ulmain > li:nth-child(3n) {  margin-right: 0; }
    .menumain-div .menu-blckdiv .productlist-main-div .products-list-ulmain>li:nth-child(4n) {  margin: 0 2% 20px 0; }

    .product-menu-sidebar{  display: none; }

    li.hsign_sec {  position: absolute; left: 84px;  }

    .checkout-main-container{ max-width: 780px; }

    .reservation-fullinner-div{ max-width: 780px; }

    .menumain-div .press-body-div, .menumain-div .award-body-div{  max-width: 780px; }

    .promotion-newdesign .mypromo-main-div .promo-earned-col-image img{
        max-height: 100%;
    }

}

@media (max-width: 1080px) and (min-width: 991px){
    .mypromo-main-div .promo-earned-col-item .promo-earned-info .promation_btn .button{  padding: 10px 3px 7px; }
}

@media (max-width: 1024px){
    .product_orders_top span.close_product_orders {  opacity: 1; }

    .accsec_right{ padding: 40px 20px; }

    .memb-first {  text-align: center; }
    .memb-first img { max-width: 90%;    }
}



@media (max-width: 991px){
   
    .foot-img img { max-width: 75%; margin-bottom: 25px; }

    .notice-board {  /*font-size: 15px;*/ }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .inn_product_row .prd_chosen_row {
        padding: 30px 0px 0px 0px;
    }

    .mfp-wrap .mfp-container .mfp-content .login-popup .mfp-close, .signup-popup .mfp-close, .signup-corparateone-popup .mfp-close,
     .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{
        background: #000;
     }

    .myacc-main-div .innersection_wrap .tab-content .account_sec .accsec_right .accsec_right p{ text-align: center; }

    .hacrt_sec_title{ padding-top: 5px; }

    .myadmin_wrap .mbacc_mslidersec{  background: #efefef; }

    .myacc-main-div .mbacc_mslidersec .slick-arrow{ border-radius: 3px; }

    /*header .hcart_dropdown .hcart_maiin_scrollarea .hcart_scrollarea {
        max-height: calc(100% - 42px);
        overflow-y: auto;
        height: 100%;
    }*/

    .tab_mobrow .account_sec { display: block; }

    .mainacc_menusec .account_sec { display: block; }

    .mbacc_mslidersec{ margin-bottom: 10px; }
    .mbacc_mslidersec .slick-track {  border-bottom: 4px solid #e7e6e6; }
    .mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow{
        border: 0;  border-radius: 0;  width: 30px;  height: 30px; position: absolute;  left: 2px;  top: 0;  margin: auto 0;
        text-align: center; overflow: hidden; font-size: 0; background: #561c4e; color: #fff; z-index: 1; padding: 0;
    }
    .mbacc_mslidersec .slick-next {  right: 2px; left: auto; }

    .innersection_wrap{ padding: 0; }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p{ left: 0 }
    .inn-product-popup .inn_product_row .prd_chosen_sub_row{ padding: 25px 0 25px; }

    /*.products-image-div, .det_inner_image{  height: 305px; } */
    /*.product-info-div, .det_inner_content{  height: calc(100% - 305px); }*/

    .awards-section .products-image-div, .awards-section .press-slider-top{ height: 100%; }

    /*header .hcart_dropdown .cart_footer .cart_footer_back .cart_footer_itme_pack{  margin-bottom: 15px; }*/
    .delevery_popup .two-button-row  .go_second,
    .delevery_popup .two-button-row .con_first{
        width: 49%;
    }
    
    .home-testimonials button.slick-arrow.slick-next{
        left: 55%; transform: translateX(-55%); top: inherit; bottom: -25px;
    }

    .home-testimonials .slick-arrow.slick-prev {
        left: 45%;  transform: translateX(-45%); top: inherit; bottom: -25px;
    }

    .f-nav > ul > li{  width: 33%; }
    .f-nav > ul > li:last-child{ padding-right: 0; }
    .f-nav{  width: 100%; margin-bottom: 20px; }
    .footer-nav-info .f-nav ul li .f-hours-ul li{  text-align: center; }

    .menumain-div .menu-blckdiv .products-image-div p{ top: 50%; transform: translateY(-50%); left: 0; }

    .press-body-div .press-slider-top .products-image-div p{
        bottom: 10px;  left: 10px;  transform: translateX(0);  padding: 0;  top: inherit;
    }

    .product-lhs{  margin-bottom: 100px; }

    .hmenu-login-section .hmenu-login-act .hcart_dropdown #cart-close-span{  top: 6px; }

    .myadmin_wrap .filter_tabsec,
    .myadmin_wrap .main_tabsec{
        margin: 0;
    }

    .cms-page-box{  margin-bottom: 30px }

    .menumain-div .menu-blckdiv .products-list-ulmain .products-image-div{  height: 100%; }

    .products-single-li:before,
    .products-single-li:after{
        display: none;
    }

    .footer-nav-info .f-nav ul li:first-child,
    .footer-nav-info .f-nav ul li .f-whats-ul li{
        text-align: left;
    }

    .footer-nav-info .f-nav ul li:last-child,
    .footer-nav-info .f-nav ul li .f-location-ul li{
        text-align: right;
    }

    /*.menumain-div .menu-blckdiv h3:after{  width: 80px;  top: 40px; }*/
    .menumain-div .award-body-div h3:after{  width: 100px; }

    .menumain-div .menu-blckdiv{  padding: 0;}

    .cms-page.privacy-policy-div, .contact_wrap, .reservation-body-div{  padding-bottom: 70px; }

    .product-lhs-container{  margin-bottom: 70px; }

    .foot-map{ margin-top: 20px; }
    .career-info-div .button{ width: 100%;}

    .mfp-wrap .mfp-container .mfp-content .login-popup .mfp-close, .signup-popup .mfp-close,  .guest-popup .mfp-close{
        right: 5px;
    }

    .myacc-main-div .common-inner-banner{ padding: 30px 20px 20px; }
    .memb-foot{ background: none; }
    
    .contact_wrap .bg-black .contactus_lhs{ margin-top: 0px; margin-bottom: 0px; height: 400px;}

    .membership-banner-inner h3{ max-width: 100%; text-align: center; }
    .member-prog-bar { margin-bottom: -10px;    }
    .membership-banner-inner h2 { margin-bottom: 0; }
    .membership-banner-inner h4{ text-align: center; margin-left: 0; margin-top: 20px;}

    .memb-foot { margin: 15px 0;}

    .account_sec.rewards-newdesign{ margin-top: 20px; }

    .order-newdesign .accsec_right{ padding: 0px 0px 20px;}

    .myadmin_wrap .accsec_right{ padding: 10px 0 0; }

    .promation_btn .promation_btn-one{  width: 49%; }

    .contact_wrap{  max-width: 100%; }

    .thank-order-detaildiv{ max-width: 420px; }

    .menu-blckdiv, .contact_wrap, .privacy-policy-div,  .checkout-main-container{  max-width: 580px;  }

    .innersection_wrap.tnk-you{  margin: 0 auto; }

    .checkout-main-container .left-input-div, .checkout-main-container .right-input-div {  max-width: 100%; width: 100%; }

    .reservation-fullinner-div, .menumain-div .press-body-div, .menumain-div .award-body-div{ max-width: 620px; }

    .career-maindiv .menu-blckdiv{  padding-bottom: 70px; }

    .career-info-div .contact_col .btn-block{  width: 100%; }
}

@media (max-width: 850px){
    .copyright-section{ flex-direction: column;}
    .copyright-section p{ width: 100%;  justify-content: center; margin-bottom: 10px;}
    .copyright-section ul{ width: 100%; justify-content: center; position: initial; top: inherit;}
}

@media (max-width: 780px){
    .notice-board {  overflow: hidden; padding: 10px 10px; }

    .nb-container span {  left: -40px;  font-size: 0; }

    .f-address p {  width: 100%; }
    .footer-nav-info .f-nav ul li.f-li-ophrs{ text-align: center; }
    .footer-nav-info .f-nav > ul > li {  width: 33.333%;  }
    .footer-nav-info .f-address {  width: 100%;  }

    .modal-dialog-tgrfromhome .modal-detail-box .inn-product-popup .product-detail-topmaindiv .productdetail-rightside-maindiv
     .prodet_baninfo {
        padding: 0px 0 0 0;
    }

    .mfp-wrap .mfp-content .pop-whole .full-login-lhs{ width: 100%; }
    .mfp-wrap .mfp-content .pop-whole .full-login-rhs{  width: 100%;  padding: 60px 60px; }
    .mfp-wrap .mfp-content .login-popup .pop-whole .full-login-lhs .button{  min-width: 80%;  }
    .mfp-wrap .mfp-content .login-popup .pop-whole .full-login-lhs img { width: 280px; }

    .commom-modal-topcls .modal-body .inn-product-popup .addon_rewive_product_topmaindiv{  padding: 45px 35px 30px; }

    .home-banner .slick-dots{ bottom: 15px; }

    .prodetailinner-main-div .productdetail-rightside-maindiv .compo_addcart_cls .compo_add_div{  width: 100%; }
    
    .footer-nav-info .f-address {  width: 50%;  padding: 0;  text-align: center;  margin-bottom: 15px;  }
    .footer-nav-info .f-nav > ul > li {  margin-bottom: 15px; }
    .footer-nav-info .f-nav { display: block !important; }
    .footer-nav-info .f-nav > ul {  /* justify-content: center;*/  flex-wrap: wrap; }
    .footer-nav-info .f-nav > ul > li:last-child {  padding-right: 0px; }
    .footer-nav-info .f-socail { width: 100%; text-align: center; }

    .home-restaurant{  padding: 50px 0; }

    .home-restaurant-left{ width: 50%; }
    .home-restaurant-left:last-child{  margin-bottom: 30px;  margin: 0 auto; }

    .h-menu-box ul li{ margin-right: 20px; }

    .home-menu {  padding: 40px 0 40px;  }

    .home-review {  padding: 50px 0 40px;  }
    .home-review h2{  margin-bottom: 30px; }

    .check_out_fourcommon .birthday-info-div{ padding: 20px 10px; }

    .thankyou-main-div .tnk-you .thank-order-detaildiv {  padding-bottom: 60px; }

    .copyright-section ul{  top: 6.5px; }

    .hcart_round{  right: 0; }

    .cafe-lhs-filter ul {  position: absolute;  }

    .cafe-lhs-filter{ width: 49%; }

    .home-menu .product-info-div-home .button{  padding: 14px 22px; }

    .member-prog-bar img { padding-right: 0; max-width: 16%;}
    .memb-first img { max-width: 40%;}
    .member-prog-bar { margin-left: 0;}
    .member-help-block { font-size: 12px; margin-left: 0; margin-top: 5px;}
    .membership-banner-inner h2 { text-align: center; margin-left: 0;}
    .checkout-title{  padding-top: 20px;}
    .home-menu { padding: 0px;}

    .reservation-fullinner-div{  flex-direction: column;  justify-content: center; }
    .reservation-lhs {  max-width: 550px;  width: 100%; margin: 0 auto; }
    .reservation-body-div iframe{  width: 550px; }
    .reservation-Rhs {  width: 100%;  margin-top: 30px; }
    .reservation-Rhs-heading{ text-align: center; }
    .reservation-Rhs-heading h2:after{  top: 40px; left: 50%; transform: translateX(-50%); }
    .reservation-Rhs-heading h4{ line-height: 1.4; }

    .promotion-newdesign .main_tabsec_inner, .rewards-newdesign .main_tabsec_inner{  padding: 30px 10px 0; }

    .reservation-Rhs { max-width: 500px;  margin: 30px auto 0; }

    .menu-blckdiv, .contact_wrap, .privacy-policy-div{  max-width: 480px; }

    .order_popup {  margin: 10px;  }

    .reservation-fullinner-div, .menumain-div .press-body-div, .menumain-div .award-body-div{ max-width: 480px; }

    .career-maindiv .menu-blckdiv{ padding-bottom: 50px; }

    .brunch-main{ background-size: contain; }

    .productpage-main-div{
        background: url(../images/home-res-bg.jpg) repeat-y;
        background-size: contain;
        background-attachment: fixed;
      }

      .full-login-block{
        padding: 60px 40px;
      }
}

@media (max-width: 768px){
    .cartaction_checkout { margin: 10px; }

    .hcategory_submenu>li>a{  padding: 12px 100px 12px 45px; }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right{
        width: 306px;
    }

    .menumain-div .menu-blckdiv p{  padding: 20px 0 25px; }
    .menumain-div .menu-blckdiv{ padding: 50px 0 50px; }
    .menumain-div .press-body-div{ padding: 0; }

    .cms-page.privacy-policy-div, .contact_wrap, .reservation-body-div{ padding-bottom: 50px; }

    .career-maindiv .menu-blckdiv { padding-top: 0 !important; }
    .career-top .choose-file-sec .form-group p{  padding: 10px 0 0; }
}

@media (max-width: 767px){
    .product-menu-listing .productlist-main-div .products-list-ulmain>li { width: 49%;  margin: 0 0 20px 0; }

    .productlist-main-div .products-list-ulmain>li:nth-child(2n), 
    .productlist-main-div .products-list-ulmain>li:nth-child(4n) {
        margin-right: 0;
    }

    .product-lhs { padding: 25px 20px 20px; }

    .check_out_fourcommon .chk-payment {  padding: 10px 0px 0px 0px; }

    .productlist-main-div{  padding: 0px 0px 20px; }
}


@media (max-width: 758px){
    .modal .modal-dialog.modal-lg {  width: 540px; }
}

@media (max-width: 704px){
    
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p{  top: 45px; }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col {
        margin-left: 140px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .inn-product-popup .inn_product_row .prd_chosen_sub_row{  padding: 25px 0; }

    .inn-product-popup .productdetail-rightside-maindiv .inn_product_row .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx {
        width: 115px !important;
        float: none;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col{
        margin-left: 0;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup 
    .productdetail-rightside-maindiv
     .prd_chosen_sub_row p.prt-sct {
        top: 3px;
        left: initial;
    }

    .home-main-div .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv .inn_product_row 
    .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left {
        margin-bottom: 0;
    }

    .modal-dialog-tgrfromhome .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv 
    .inn_product_row
     .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left{
        margin-bottom: 10px;
     }


    .accsec_right .order-tab-section .mainacc_mobrow .tab_sec .web_order_typecls .orderTypeli .count_num_t{
        width: 40px;
        font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    }
}

@media(max-width: 680px){
    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec{
        width: 500px;
        max-width: 100%;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec {
        max-width: 100%;
        width: 100%;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec
     .inn-product-img-bg .slick-slider .slick-dots li {
        max-width: 125px;
        padding-bottom: 0;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec
     .inn-product-img-bg .slick-slider .slick-dots li img {
        height: 125px;
    }

    .home-main-div .home-banner .banner-info img { width: 35%;  }
    .home-main-div .home-choose-type ul li { width: 100%;   margin: 0 0 20px; }

    .featuredpro-section { padding: 40px 0; }

    .slick-active.slick-center.slick-current .inner-testimonials{  text-align: center; }

    .testi-author-name {  width: auto;  padding-left: 15px;  }

    .home-testimonials button.slick-arrow.slick-next{
        left: 55%;
        transform: translateX(-55%);
        top: inherit;
        bottom: -25px;
    }

    .home-testimonials .slick-arrow.slick-prev {
        left: 45%;
        transform: translateX(-45%);
        top: inherit;
        bottom: -25px;
    }

    .testi-author { padding: 20px 0 0; justify-content: center; }

    .tab-content .product_chosen_col_inner .compo_acc_innerdiv .custom-radio-btncls .components_selct .custom_radio span {
        padding: 0;
    }

    .tab-content .product_chosen_item_left .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio {
        padding: 8px 30px 8px 0;
    }

    .home-promotions .hp-parent .promobig-lhs { max-width: 400px; }

    .custom_center_alertcls { max-width: 360px; padding: 20px; }

    .mypromo-main-div .promo-earned-col-item .promo-earned-info .promation_btn .button{  padding: 10px 10px 8px; }

    .modal-full-xl .modal-detail-box .inn-product-popup .product-detail-topmaindiv { padding: 0 20px; }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p { right: inherit; left: 0; }

    .mobile-login { left: 50px; top: 23px;right: inherit; position: absolute; display: none; }

    li.hsign_sec{  display: none; }

    .mobile-login-list li.hsign_sec {  position: initial; left: inherit;  display: block; }

    .mobile-login-list .hsign_sec>span{ display: none; }

    .f-socail-btm { margin: 0 10px 0 20px; }
    .f-socail-btm ul li:last-child{  margin-right: 0; }

    .h-r-i-sub-heading{ padding: 10px 0 10px; margin-bottom: 5px; }
    .h-r-i-heading{  margin-bottom: 10px; }
    .h-r-i-para-space{  margin-top: 10px; padding-top: 0; }

    .home-restaurant h2 {  margin-bottom: 10px; }
    .home-restaurant { padding: 30px 0; }

    .footer-nav-info .f-socail{
      width: 50%;  text-align: center;  position: absolute; top: 35%; right: 15px; padding-left: 15px; 
    }

    .footer-nav-info .f-nav ul li.f-li-location-box:last-child,
    .footer-nav-info .f-nav ul li.f-li-location-box .f-location-ul li,
    .footer-nav-info .f-nav ul li.f-li-ophrs,
    .footer-nav-info .f-nav ul li .f-hours-ul li{
        text-align: left;
    }

    .footer-nav-info .f-nav{  margin-bottom: 0; }
    .footer-nav-info .f-nav > ul > li{ width: 50%; margin-bottom: 30px; }
    /*.menumain-div .menu-blckdiv h3:after{ top: 40px;  width: 60px; }*/
    .menumain-div .award-body-div h3:after{ width: 100px;  top: 40px; }

    .cafe-lhs-filter .filter-act{ letter-spacing: 0; }

    .homebanner-button{  justify-content: center;  }
    .homebanner-content-inner a{ margin-right: 15px; }

    .checkout-main-container{ max-width: 480px; }

    .thank-order-detaildiv{  max-width: 360px; }

    .press-slider-top .slick-next{ right: 0px; }

    .press-body-div .press-slider-top .products-single-li{ padding-right: 0; }

    .awards-section .press-slider-top .products-single-li{ padding-right: 10px; }

    .redeem-item-hea .points { /*font-size: 15px; */position: relative; width: 100%; max-width: inherit; top: inherit; margin: 7px 0;}    
    .redeem-item-hea-inner { width: 100%;}

    .h-menu-box ul{
        flex-wrap: wrap;
    }

    .h-menu-box ul li{
        width: 48%;
        margin: 0 0 20px 0;
    }

    .home-menu-inner {
        margin: 0px 20px;
    }

}

@media (max-width: 620px){
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p{  width: 150px; }

    .modal-dialog-tgrfromhome .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p{
        width: inherit;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right{
        width: 306px;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .cart_update_div.compo_update_div {
        width: 170px;
    }

    .acc-inform .form-group .row .col-md-6 { width: 100%; margin: 0; }

    .career-info-div { padding: 40px 10px; }
    .career-top { padding: 15px 20px 0px; }

    .payment-info-popup .inside-dine-popup-rhs{  padding: 40px 20px 20px; }

    .career-maindiv .career-head{ padding: 0 20px 20px; }

    .reservation-fullinner-div, .menumain-div .press-body-div, .menumain-div .award-body-div{  max-width: 380px; }

    .reservation-Rhs, .menu-blckdiv, .contact_wrap, .privacy-policy-div, .checkout-main-container {  max-width: 360px; }
    .homebanner-button a img{ height: 20px;}

    .checkout-billing-address .chk_hea>span {  text-align: right; }
    .checkout-billing-address .chk_hea .custom_checkbox{  top: 0; }

    .cart_info .cart_into-text {
        display: flex;
        flex-direction: column;
    }

    .full-login-block {
        padding: 60px 20px;
    }

}
  
@media (max-width: 580px){
    .mobile_mainacc_menulist li a {
    padding: 12px 10px 12px 40px;
    font-size: 14px;
    }
    .promo-form input[type=text] { height: 46px;  padding: 5px 20px;  border-radius: 4px; }

    .promo-form .applyBtn { padding: 14px 20px; height: auto; min-width: 160px; margin: 10px auto 0; position: relative; }

    .modal .modal-dialog.modal-lg {  width: 94%!important;  margin: 10px auto!important; }

    .checkout-billing-address .chk_hea span { margin-top: 0; }

    .foot-img img {  max-width: 50%; }

    .check_out_fourcommon .chk-payment-col-radio li:last-child{margin-bottom: 12px;}

    .home-banner .slick-dots{ bottom: 10px; }

    .delevery_popup .two-button-row  .go_second,
    .delevery_popup .two-button-row .con_first{
        width: 100%;
    }

    .hcategory_selected_text { padding-right: 120px;  }
    .hcategory_trigger:after { width: 16px; height: 15px; }
    .hcategory_trigger {  padding-right: 20px; top: 14px; }

    .hcategory_submenu>li>a {  padding-left: 35px; }
    
    .homebanner-content-inner a{  padding: 10px 18px; }

    .footer-nav-info .f-address {  width: 100%; }
    .footer-nav-info .f-address p { width: 100%;  }
    .footer-nav-info .f-nav {  width: 100%; }

    .home-restaurant-left{  width: 100%;  padding: 0 5px; }
    .home-restaurant-left:last-child,
    .home-restaurant-left {  margin-bottom: 20px; }

    .h-r-i-para-space:last-child{  padding-bottom: 0; }

    .f-socail-btm{  display: none; }

    .mobile-menu-body .f-socail-btm{  display: block; }
    .mobile-menu-body .menu{  margin-bottom: 20px; }

    .order_popup .order-body{ padding: 0; }
    .order_popup .order_delivery_row {  padding: 30px 0 40px; }

    .product-lhs {  padding: 25px 15px 15px;  margin-bottom: 50px; }
    .product-lhs-container{ padding: 10px 10px 15px; }


    .product-detail-box .modal-content{  margin-bottom: 30px; }

    .menumain-div .menu-blckdiv .products-list-ulmain>li { width: 48.5%; margin: 0 0 20px 0; }
    .menumain-div .menu-blckdiv .products-list-ulmain{  justify-content: space-between; }

    .hcategory_trigger.active:after{ right: 0;  width: 14px;  height: 14px; }

    .ordernow_popup .order_delivery_item{ padding: 30px 10px; }

    .memb-payment-col-radio p {  font-size: 16px;   max-width: 250px; }
    .member-prog-bar {  padding: 0 20px; }

    .checkout-title h1 img { width: 35px; height: 35px; }

    .full-login-new-body {  padding: 20px 10px 30px;}

    .checkout-heading { padding: 10px 10px;}

    .contact_wrap .bg-black .contactus_lhs{ height: 200px; }

    .mobile_account_item_login>a { padding: 14px 0 12px 40px; }
    .mobile_account_item_login>a>i {  width: 20px;  height: 20px; }
    .mobile_account_menu_click {  font-size: 15px;  padding: 12px 40px 12px 15px; }
    .mobile_account_menu_click i.arrow_myaccount { background-size: 16px auto; width: 46px; height: 42px; }

    .mobile-order-nowbtn .mobile_account_delivery_item a, .mobile-order-nowbtn a {  padding: 13px 15px; }

    .login-popup-all .login-info-body{ margin: 0 15px 0 0; }

    .login-popup-all .login-info-body .button{ margin: 15px 10px 10px; font-size: calc(8px + (14 - 8) * ((100vw - 300px) / (1600 - 300))); }

    .login-popup-all .login-image {
        width: 100px;
        height: 100px;
        margin: 10px auto 0;
    }

    .login-info-body .button{ border-radius: 5px; padding: 12px 10px; }
}


@media(max-width: 576px){
    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec{
        width: 400px;
        max-width: 100%;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec {
        width: 100%;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .addon_rewive_product_topmaindiv .addon-prolist-inner .addonproducts-list-ulmain .addon-products-single-li {
        width: 100%;
        padding: 0 15px 20px 0;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec
     .inn-product-img-bg .slick-slider .slick-dots li {
        max-width: 100px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec
     .inn-product-img-bg .slick-slider .slick-dots li img {
        height: 100px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
     .productdetail-rightside-maindiv .prd_chosen_sub_row p {
        left: 0px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup 
    .inn_product_row .prd_chosen_sub_row{
        padding: 25px 0;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup 
    .inn_product_row .prd_chosen_sub_row{
        padding: 0 0 25px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv .inn_product_row
     .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left{
        width: 100%;
        padding: 0;
     }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx {
        float: none;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .prd_chosen_sub_row .prd_chosen_sub_col {
        margin-left: 0;
        width: 100%;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .prd_chosen_sub_row p.prt-sct {
      
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv 
    .prd_chosen_sub_row p.prt-sct{
        text-align: right;
        right: 180px;
    }


    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .prd_chosen_sub_row p.prt-sct {
       text-align: center;
    }

    .home-main-div .home-banner .banner-info img { width: 35%; }

    .footer-top-info ul li {  max-width: 100%;  width: 100%; }
    .footer-top-info ul {  display: block;  overflow: hidden; }

    .footer-main .footer-nav-info .container-full .f-address { text-align: center; max-width: 100%; }
    .footer-main .footer-nav-info .container-full .f-socail h5{  text-align: center; }
    .footer-main .footer-nav-info .container-full .f-socail-btm{  text-align: center; }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .addon_rewive_product_topmaindiv
     .addon-prolist-inner .addonproducts-list-ulmain {
        padding-right: 15px;
    }

    .home-choose-type { padding: 35px 0 10px 0;  }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec .inn-product-img-bg {
        height: 320px;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec{
        height: 320px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx {
        float: none;
        position: absolute;
        right: 175px;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx {
        float: none;
        position: relative;
        right: inherit;
        top: inherit;
     }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .cart_update_div.compo_update_div{
        width: 170px;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right{
         width: 260px;
    }
}

@media (max-width: 560px){
    .modal .modal-dialog.modal-lg {  width: 440px;  margin: 1.75rem auto; }
}

@media(max-width: 550px){
    .featuredpro-section .featured-products-main .products-list-ulmain > li{ width: 100%; margin: 0 0 20px; }
}

@media(max-width: 527px){

    .home-testimonials button.slick-arrow.slick-next{ top: inherit; }
    .home-testimonials button.slick-arrow{  top: inherit;  bottom: -25px; }
    .home-testimonials .slick-arrow.slick-prev{  top: inherit;  bottom: -25px; }

    .mfp-wrap .mfp-content .pop-whole .full-login-rhs{ width: 100%;  padding: 40px 30px; }
    .mfp-wrap .mfp-content .login-popup .pop-whole .full-login-lhs img {  width: 220px; }
    .mfp-wrap .mfp-content .login-popup .pop-whole .full-login-lhs .button{  padding: 15px 6px;}
}

@media (max-width: 520px){
    .chk-payment-btn {  padding: 0 10px; }
}

@media (max-width: 500px){
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p{ width: 100%; text-align: center; }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row p{
        top: 20px;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right{
        width: 100%;
        margin-top: 40px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
    .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx{
        top: 88px;
        right: inherit;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
    .prd_chosen_sub_row p.prt-sct{
        top: 42px;
        left: 0;
        text-align: left;
    }
}

@media(max-width: 480px){
    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec{
        width: 100%;
        max-width: 300px;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec {
        max-width: 100%;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec
     .inn-product-img-bg .slick-slider .slick-dots li {
        max-width: 75px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec
     .inn-product-img-bg .slick-slider .slick-dots li img {
        height: 75px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv 
    .prd_chosen_row .product_chosen_inner .tab-content .product_chosen_col_inner .compo_acc_innerdiv .compo_minmax_maindiv .chosen_adn
     .chosen_adn_left {
        width: 150px;
    }

    .nb-container span { display: none; }

    .home-main-div .home-banner .banner-info img { width: 35%; }
    .home-main-div .home-banner .banner-info{  padding: 0; }
    .home-main-div .home-banner .banner-info a{ margin-top: 8px; padding: 8px 8px 6px; border-radius: 4px; }
    .home-main-div .home-banner .banner-info .white-logo-img { margin-bottom: 10px; }
    .home-main-div .home-banner .slick-slider .slick-list .banner-info p {  padding: 0 10px; }

    .modal-dialog-tgrfromhome .modal-detail-box .inn-product-popup .product-detail-topmaindiv .productdetail-rightside-maindiv .prodet_baninfo{
        padding: 0px 0 0 0;
    }

    .delivery_total.delivery_total_text:before{ display: block; border: 1px dashed #dfdad7; }

    .commom-modal-topcls .modal-body .inn-product-popup .addon_rewive_product_topmaindiv { padding: 45px 15px 30px; }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row p{
        display: inherit;
    }

    .modal-dialog-tgrfromhome .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col 
    .prd_chosen_sub_item_right{
        margin-top: 0;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
    .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx{
        top: inherit;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .prd_chosen_sub_row p.prt-sct {
       text-align: center;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .cart_update_div.compo_update_div{
        width: 200px;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row p{
        top: 0;
    }

    .home-testimonials button.slick-arrow.slick-next {  left: 60%; transform: translateX(-50%); }

    .cart_info .cart_into-text {  display: flex;  flex-direction: column; }

    .profile-info-div { margin: 20px 0;}

    .mfp-container .mfp-content .pop-whole .full-login-rhs-header h3 { line-height: 1.5;}

    .home-banner .slick-dots{ line-height: 1;}

    .current_order .pickup-order-div::before { display: none;}

    .promotion-newdesign .main_tabsec_inner,  .rewards-newdesign .main_tabsec_inner {  padding: 20px 0 0; }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv{
        padding: 30px 15px 0;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv .inn_product_row .prd_chosen_row {
        padding: 10px 00px 0px 00px;
    }
    
    .footer-nav-info .f-nav > ul > li { width: 100%;}
    .footer-nav-info .f-nav > ul{ flex-direction: column;}

    .footer-newletter{ margin-top: 0;}

    .f-socail{ margin: 0 auto;}

    .f-newletter-bottom label{ text-align: left;}

    .footer-nav-info .f-nav ul li h5{ margin-bottom: 10px;}

    .footer-nav-info .f-nav .f-location-ul li{ margin-bottom: 0;}

    .footer-nav-info .f-nav .f-whats-ul li:last-child,
    .footer-nav-info .f-nav .f-hours-ul li:last-child{
        margin-bottom: 0;
    }

    .footer-nav-info{ padding-bottom: 35px;}

    .footer-main{ padding-top: 35px;}

    .featuredpro-section { padding: 20px 0;}

    .home-restaurant-button a { padding: 8px 18px;}

    .home-menu { padding: 30px 0 30px;}
    .home-menu h2{ margin-bottom: 20px;}

    .home-review { padding: 30px 0 30px;}
    .home-review h2{ margin-bottom: 20px;}

    .menumain-div .menu-blckdiv h3{    margin-bottom: 15px;}

    .myacc-main-div .common-inner-blckdiv .common-inner-banner p{  margin-bottom: 0; }

    .footer-nav-info .f-nav ul li:first-child, 
    .footer-nav-info .f-nav ul li .f-whats-ul li{
        text-align: center !important;
    }

    .footer-nav-info .f-nav ul li.f-li-location-box:last-child, 
    .footer-nav-info .f-nav ul li.f-li-location-box .f-location-ul li, 
    .footer-nav-info .f-nav ul li.f-li-ophrs, 
    .footer-nav-info .f-nav ul li .f-hours-ul li{
        text-align: center;
    }

    .footer-nav-info .f-socail {
        width: 100%; text-align: center; position: inherit;  top: inherit;  right: inherit;  padding-left: 0;
    }

    .product-detail-box .prodetailinner-main-div .modal-full-xl .modal-content .modal-body{
        padding: 30px 15px;
    }

    .product-detail-box .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box 
    .inn-product-popup .prodet_bansec{
        max-width: 100%;
    }

    .checkout-total .checkout-body-section .syd_date,
    .checkout-total .checkout-body-section .syd_time.delivery_submit_cls {
        width: 100%;
    }

    .cafe-lhs-filter{ padding: 10px 0px 15px;}

    .product-menu-listing .productlist-main-div .products-list-ulmain>li { width: 100%; margin: 0 0 30px 0; }

    .products-single-li:before,
    .products-single-li:after{
        display: block;
    }

    .productpage-main-div .inner-banner-content{  flex-flow: column;}
    .menumain-div .award-body-div h3:after{ width: 80px; top: 30px; }
    /*.menumain-div .menu-blckdiv h3:after{ top: 30px; width: 60px; }*/
    /*.menumain-div .press-body-div { margin: 50px 0 50px; padding: 25px 0 25px; }*/
    .home-menu .product-info-div-home .button{ padding: 12px 18px; }
        
    span.spilter { margin: 0 6px; height: 14px;}

    .controls.two-links a{ margin: 0; }

    .cur-order-body .current_order .delivery_total_text .delivery_total_left,
    .cur-order-body .current_order .delivery_total_text .delivery_total_left.delivery_total_right{
        width: 100%;
        float: none;
        text-align: center;
    }

    .cur-order-body .current_order .delivery_total_text .delivery_total_left{ margin-bottom: 20px; }
    .cur-order-body .current_order .delivery_total.delivery_total_text:before{ display: none; }

    .foot-map iframe{ width: 100%; }

    .home-menu-inner{ margin: 0 15px; }

    .career-button{ padding: 0; }

    .reservation-Rhs-heading h2:after { top: 35px; width: 70px; }
    .reservation-Rhs-heading h4{ margin-bottom: 10px; }
    .reservation-body-div{ padding: 40px 0 30px; }

    .cms-page.privacy-policy-div, .contact_wrap, .reservation-body-div{ padding-bottom: 40px; }

    .productlist-main-div{ padding-bottom: 0; }

    .filter_by_brand_tag .filter-act{ max-width: 100%; }

    .career-maindiv .career-head{ padding-bottom: 10px; }

    .homebanner-content-inner a{padding: 8px 10px;}
    .cms-page-box.brunch-cms{ margin-top: 30px; }

    .menu-blckdiv, .contact_wrap, .privacy-policy-div, .checkout-main-container {  max-width: 300px; }

    .reservation-fullinner-div{ max-width: 280px;  }

    .contact_wrap .bg-black .contactus_rhs{ padding: 25px 15px 25px; }

    .white-popup.delevery_popup {  max-width: 480px;  margin: 10px auto; }

    .common-inner-banner{  padding: 20px 20px; }

    .menumain-div .menu-blckdiv.press-body-div{  padding: 30px 0; }
    .menumain-div .awards-section:nth-child(2n){ padding: 0; }
    .homebanner-content-inner{  padding: 15px 35px; }

    .menumain-div .awards-section:first-child{
        padding-top: 0;
    }

    .home-banner .slick-arrow{
        width: 25px;
        height: 40px;
    }

    .home-banner button.slick-arrow.slick-prev:after, .home-banner button.slick-arrow.slick-next:after{ font-size: 22px; }

    .homebanner-content-inner a:last-child{ margin-right: 0; }

    .footer-nav-info .f-nav ul li:last-child{ margin-bottom: 0; }

    .hcart_dropdown .cartaction_bottom{
        padding-bottom: 60px;
    }

    header .hcart_dropdown .hcart_scrollarea .cart_body .cart_footer{
        padding-bottom: 20px;
    }

    .h-menu-box ul li{  width: 100%; }
    .h-menu-box ul{  flex-direction: column; }


    /* CSS specific to iOS devices only */ 
    @supports (-webkit-overflow-scrolling: touch) {
     .hcart_scrollarea { max-height: calc(100% - 60px); /*padding-bottom: 220px;*/ padding-bottom: 160px;}
     header .cartaction_bottom {
       padding: 10px 0px 120px 0px !important; position: fixed; bottom: 0px; left: 0; right: 0; background: #fff;}
    }

    .login-popup-all .login-info-body {
        width: 146px;
        margin: 0 15px 20px 0;
    }

    .login-popup-all .full-login-block{
        justify-content: center;
        flex-wrap: wrap;
    }

    .login-popup-all .login-info-body:nth-child(2n){
        margin-right: 0;
    }

    
}


@media (max-width: 450px){
    .modal .modal-dialog.modal-lg { width: 320px; }
}

@media (max-width: 420px){
.mobile_mainacc_menulist li a {
    padding: 12px 5px 12px 43px;
    font-size: 14px;
}
    .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup{ margin-top: 5px; }

    .order_delivery_item li a {  padding: 20px 10px; }

    .hcart_dropdown .hcart_row_head .hcart_box { text-align: center; max-width: 100%; }
    .hcart_dropdown .hcart_row_head .hcart_add_item.hcart_box p:last-child{  margin-bottom: 15px; }
    .hcart_dropdown .hcart_row_head .hcart_add_loca .hcart_add_innr_loca{ justify-content: center; }

    .home-promotions .hp-parent .promobig-lhs {  max-width: 300px; }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec {
        height: 250px;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .prodet_bansec .inn-product-img-bg {
        height: 250px;
    }

    .inn-product-popup .addon_rewive_product_topmaindiv .products-reviews-maindiv .productreviews-list-ulmain .mCSB_inside>.mCSB_container {
        margin-right: 0px;
    }

    .inn-product-popup .addon_rewive_product_topmaindiv .products-reviews-maindiv .productreviews-list-ulmain{
        padding: 10px 0px 0 15px;
    }

    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .cart_update_div.compo_update_div{
        width: 150px;
    }

    .custom_center_alertcls {  max-width: 280px; }

    .redeem_apply .btn {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        min-width: 110px;
        padding: 12px 18px;
        position: inherit;
    }

    .home-banner .slick-dots{  bottom: 0; }
    .delevery_popup_datetime .syd_date { width: 100%; }
    .delevery_popup_datetime .syd_time.delivery_submit_cls {  width: 100%; }
    .delevery_popup .popup_right .form-group .form-control{  padding: 10px 10px 10px 5px; }

    .custom_select:after{  width: 30px; }
    .custom_center_alertcls{ padding: 15px; }

    .thankyou-main-div .thank-order-detaildiv .overall-parent .product-details {  padding: 20px 0px; }
    .thankyou-main-div .thank-order-detaildiv .cart_footer.tnk_cart_footer {  padding: 20px 0px 0px 0px; }

    .homepopup .index-modal .modal-button .btn {  width: 260px; padding: 0 20px; }

    .home-menu { padding: 20px 0 25px; }
    .home-menu h2 {  margin-bottom: 15px; }

    .cart_header_title .cart_innr_header img{  width: 30px;  height: 30px; }

    .menumain-div .menu-blckdiv{  padding: 30px 0 30px; }
    .menumain-div .menu-blckdiv p{ padding: 20px 0 15px; }

    .menumain-div .press-body-div{  padding: 0;}

    .career-top { padding: 15px 10px 30px; }

    .career-info-div .form-control { margin-bottom: 15px; }

    .home-menu { padding: 0px; }

    .cart_info .cart_into-text img{ width: 60px;  height: 40px;  }
    .homebanner-button a img{ height: 19px;}
    .homebanner-content-inner a{  padding: 5px; margin-right: 5px;} 

    .mobile-order-nowbtn span.myaccount_separate{ height: 11px; }

    .menumain-div .press-body-div, .menumain-div .award-body-div{ max-width: 320px; }

    .home-banner img{
        height: 500px;
        object-fit: cover;
    }

    .checkout-title h1>div{
        font-size: 18px;
    }

    .accsec_right .welcome-title h1>img{
        width: 30px;
    }

}

@media (max-width: 380px){
    .thankyou-main-div .innersection_wrap .thank-order-detaildiv .tnk-delivery .delivery_total .delivery_total_right{
        float: right;
        text-align: right;
        width: 50%;
    }

    .mobile_mainacc_menulist li a {
    padding: 12px 5px 12px 43px;
    font-size: 13px;
    }

    .thankyou-main-div .innersection_wrap .thank-order-detaildiv .tnk-delivery .delivery_total .delivery_total_left{
        float: left;
    }

    .footer-nav-info .f-nav > ul > li {  width: 100%; }

    .delivery_total_left {  float: left; }

    .full-login-new-body .form-group .custom_checkbox_content{ display: flex; }
    .full-login-new-body .sign_reg p{ line-height: 1.2; margin-bottom: 10px; }

    .mobile_account_item_login>a { padding: 14px 0 12px 35px; } 
    .mobile_account_delivery_item a{ /*font-size: 14px; padding: 10px 10px;*/ }
    .mobile-order-nowbtn .mobile_account_menu .mobile_account_delivery_item a{ padding: 13px 10px 11px }

    .reservation-fullinner-div{ max-width: 240px; }

    .menumain-div .press-body-div, .menumain-div .award-body-div{  max-width: 280px; }

    .menu-blckdiv, .contact_wrap, .privacy-policy-div{  max-width: 240px; }

    .checkout-main-container{ max-width: 260px; }

    .chk-payment-col-radio li{
        padding: 15px 10px;
    }
}

@media (max-width: 361px){
.mobile_mainacc_menulist li a {
    padding: 12px 5px 12px 35px;
    font-size: 11px;
}
    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .product-detail-topmaindiv .productdetail-rightside-maindiv .prodet_baninfo {
        padding: 0px 0 0 0;
    }

    .prodetailinner-main-div .modal-full-xl .modal-detail-box .inn-product-popup .product-detail-topmaindiv{
        padding: 0;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row p {
        left: 10px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right {
        margin: 40px 10px 0;
    }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row p{
        left: 0;
     }

    .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right{
        margin: 0;
     }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup
    .addon_rewive_product_topmaindiv {
        padding: 60px 12px 30px;
    }

    .home-main-div .home-banner .slick-slider .slick-list .banner-info .white-logo-img .banner-info h2{
        /*font-size: 22px;*/
        margin-bottom: 8px;
    }

    .home-main-div .home-banner .banner-info img { width: 25%; }
    .home-main-div .home-banner .banner-info .white-logo-img { margin-bottom: 8px; }
    .home-main-div .home-banner .slick-slider .slick-list .banner-info h2 { /*font-size: 18px;*/ margin-bottom: 5px; }
    .home-main-div .home-banner .banner-info a {  margin-top: 5px; }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv 
    .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_left {
        padding: 0 15px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
    .prd_chosen_sub_row p.prt-sct{
        left: 18px;
        width: 120px;
     }

     .modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
      .prd_chosen_sub_row p.prt-sct{
        width: 100%;
      }

    .inn-product-popup .addon_rewive_product_topmaindiv .products-reviews-maindiv .productreviews-list-ulmain .product-reviews-list-li
    .product-reviews .product-reviews-info{
        padding-left: 10px;
    }
}

@media (max-width: 360px){
    
    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col 
    .cart_update_div.compo_update_div{
        width: 120px;       
    }


    .inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col 
    .prd_chosen_sub_item_right button{
        max-width: 100%;
        margin: 0;
    }

    .self_popup .self_popup_hea_row .self_popup_hea_col{  margin-top: 10px; }
    
    .compo_addcart_cls .compocart_success_msg{ margin-left: 10px; margin-right: 10px; }

    .chk-payment-btn {  padding: 0 8px; }

    .cur-order-body .myacc_head_sec,
    .pst-order-body .myacc_head_sec{
        padding: 15px 10px;
    }

    .prodetailinner-main-div .modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup .productdetail-rightside-maindiv
     .prd_chosen_sub_row p.prt-sct{
        left: 13px;
     }

    .homebanner-content-inner h2{ margin-bottom: 5px; }
    .homebanner-button{  margin-top: 10px; }

    
    .homebanner-content-inner {  padding: 10px 30px;}
    .homebanner-content-inner p{  line-height: 1.2; }

    .H-popup-inner-content a{  padding: 8px 20px; }

    span.spilter {  margin: 0 4px;  height: 12px;  width: 1px; }

    .reservation-Rhs{  max-width: 250px; }

    .thankyou-main-div .tnk_cart_footer .cart_row p{ text-align: left; }

    .login-popup-all .login-info-body {
        width: 100%;
        margin: 0 0px 20px 0;
    }

}

@media (max-width: 350px){
    .cdd-details-lhs.fl .checkout-billing-address .chk_hea .custom_checkbox {  top: 4px;  right: 8px; }
}

@media (max-width: 330px){
    .homebanner-button a img{ height: 16px;margin-right: 5px} 
}












@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

@font-face {
    font-family: 'caviar_dreamsbold';
    src: url('../fonts/caviar_dreams_bold-webfont.woff2') format('woff2'),
    url('../fonts/caviar_dreams_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'caviar_dreamsregular';
    src: url('../fonts/caviardreams-webfont.woff2') format('woff2'),
    url('../fonts/caviardreams-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'caviar_dreamsbold_italic';
    src: url('../fonts/caviardreams_bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/caviardreams_bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'caviar_dreamsitalic';
    src: url('../fonts/caviardreams_italic-webfont.woff2') format('woff2'),
    url('../fonts/caviardreams_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'impactregular';
    src: url('../fonts/unicode.impact-webfont.woff2') format('woff2'),
    url('../fonts/unicode.impact-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'autographyregular';
    src: url('../fonts/autography-webfont.woff2') format('woff2'),
    url('../fonts/autography-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  -webkit-text-size-adjust: none;
}

:focus {
  outline: 0;
}

ol,
ul {
  margin: 0;
  padding: 0px 0px 10px 40px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

embed,
iframe,
object {
  max-width: 100%;
}

body {
  color: #000;
  background: #000;
  word-wrap: break-word;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  overflow: hidden auto!important;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;

  background: url(../images/home-body.jpg);
  /*background-repeat: no-repeat;*/
  background-repeat: repeat-y;
  background-position: center;
  background-color: #000;
  background-attachment: fixed;
}

/*body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  background: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #98684a;
  outline: 0px solid slategrey;
}
*/
cite,
em,
i {
  font-style: italic;
}

input,
textarea {
  -webkit-border-radius: 0px;
}
.btn {
  color: #fff;
  padding: 12px 20px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  -webkit-transition: all 400ms;
  transition: all 400ms;
  text-transform: uppercase;
  position: relative;
  /*background: #6e1d24;*/
  font-family: 'caviar_dreamsbold';
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
  padding: 12px 10px;
  border: 1px solid #e4e4e4;
  width: 100%;
  margin: 0 0 10px;
  background-color: #fff;
  border-radius: 0px;
  height: 48px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #555555;
  font-family: 'Montserrat', sans-serif;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
  display: inline-block;
  border-radius: 8px;
  margin: 15px 0 10px;
  padding: 16px 24px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  border: 0;
  background: #561c4e;
  color: #fff;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
  font-family: 'caviar_dreamsbold';
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.btn:hover,
.button:hover,
button:hover {
  color: #fff;
  background: #fdd18c;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
  color: #561c4e;
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:active,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #000;
}

figure {
  margin: 0;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  font-weight: normal;
  line-height: 1.1;
  color: #000;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}

h1 {font-size: calc(17px + (42 - 17) * ((100vw - 300px) / (1600 - 300)));}
h2 { font-size: calc(17px + (35 - 17) * ((100vw - 300px) / (1600 - 300)));}
h3 { font-size: calc(15px + (26 - 15) * ((100vw - 300px) / (1600 - 300)));}
h4 { font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1600 - 300)));}
h5 { font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));}
h6 { font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));}


.show {
  display: block;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.rel {
  position: relative;
}

.textcenter {
  text-align: center;
}

.textleft {
  text-align: left;
}

.textright {
  text-align: right;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.clear,
.clearfix {
  clear: both;
}

.clearfix:after {
  clear: both;
  display: table;
  content: "";
}

strong,
b {
  font-family: 'caviar_dreamsbold';
}

.required_star {
  color: #f00;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.slick-slider,
.slick-slider * {
  outline: none;
}

p {
  margin: 0 0 15px;
}

.title_sec {
  margin: 0 0 45px;
  color: #040507;
}

.title_sec h2 {
  font-size: calc(24px + (44 - 24) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1;
  margin: 0 0 5px;
}

.title_sec small {
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  font-weight: normal;
  margin: 0 0 13px;
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.dropdown-menu > li > a {
  padding: 7px 20px;
  border-top: 1px solid #e9ebf5;
}

.dropdown-menu > li:first-child > a {
  border-top: 0;
}

.form_grouptt {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 20px 2px;
  color: #000;
  text-transform: capitalize;
  font-weight: 700;
  font-family: 'caviar_dreamsbold';
}

.form-group {
  margin: 0 0 8px;
}

label {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #959595;
}

label span {
  color: #f00;
  margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
  content: "*";
  color: red;
}

.form-control {
  height: 48px;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding: 6px 18px;
  background: #fff;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 0;
  color: #615f5f;
  width: 100%;
  display: block;
}

textarea.form-control {
  height: 160px;
}

textarea.form-control.sm {
  height: 90px;
}

.form-control.error,
.Select-control.error {
  border-color: #ffbaba;
  color: #686a78;
}

.form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #000000;
}

.focus-out {
  position: relative;
}

.focus-out label {
  position: absolute;
  left: 18px;
  top: 14px;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 3;
  height: 24px;
  overflow: hidden;
  color: #595656;
}

.focused label {
  padding: 0 6px;
  font-size: calc(10px + (11 - 10) * ((100vw - 300px) / (1600 - 300)));
  background: #fff;
  top: -7px;
  height: auto;
}

.clock_icon,
.ileft_ico {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
}

.clock_icon img,
.ileft_ico img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
}

.profile-info-div {
  margin: 35px 0;
}

.other-add {
  margin: 30px 0 0 0;
}

.mCSB_inside > .mCSB_container {
  margin-right: 20px;
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
  display: table;
  clear: both;
  content: "";
}

.acc-inform .form-group .row .col-md-6 {
  width: 49%;
  float: left;
  margin: 0 2% 0 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
  margin-right: 0;
}

.re_select input[type="text"] {
  margin: 0;
  height: 34px;
}

.acc-inform .form-group .row .button {
  width: 100%;
  display: block;
  margin: 0;
  height: 48px;
  border-radius: 5px;
}

.acc-inform .form-group .row .myaccount_update:hover{
  background: #fdd18c;
  color: #000;
}

.mainacc_menusec .other-add .add-address-more i{
  color: #561c4e;
}

.re_select .Select-control {
  border: 1px solid #e2e2e2;
  border-radius: 0;
  height: 47px;
  outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
  padding: 0 22px;
  line-height: 45px;
  text-align: left;
  color: #72624f;
}

.re_select .Select-input {
  padding: 0 20px;
  height: 45px;
}

.re_select .Select-input > input {
  line-height: 27px;
  padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
  border-color: #d5d7e0;
}

.re_select .Select-arrow {
  background: url(../images/arrow-down.png) no-repeat center;
  border: 0;
  width: 18px;
  height: 10px;
}

.re_select .is-open .Select-arrow {
  background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
  z-index: 4;
  text-align: left;
}

.re_select .Select-menu-outer {
  background: #ecdfcc;
}

.re_select .Select-arrow-zone {
  padding: 0;
  width: 42px;
}

.re_select .Select--multi .Select-value {
  background-color: #ecdfcc;
  border-color: #dcd8d8;
  color: #181818;
  position: relative;
  padding-right: 19px;
  border-radius: 10px;
  overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
  border-color: #dcd8d8;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
  color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
  font-size: 0;
}

.re_select .Select--multi .Select-value-label {
  padding: 2px 5px 2px 9px;
}

.custom_select {
  position: relative;
}

.custom_select:after {
  content: "";
  background: #fff url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  pointer-events: none;
  height: 46px;
}

.react-datepicker {
  font-family: "Calibri" !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
  display: block;
}
.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container input {
  border-radius: 0;
}
.react_datepicker .react-datepicker {
  font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
  font-size: 1.2rem;
  font-weight: bold;
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
  width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
  width: 120px;
}

.react_datepicker  .react-datepicker__time-container  .react-datepicker__time  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0;
}

.react_datepicker  .react-datepicker__time-container  .react-datepicker__time  .react-datepicker__time-box {
  width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #fdd18c !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
   background-color: #fdd18c !important;
}

.react-datepicker__header {
   background-color: #fdd18c !important;
}

.react-datepicker__navigation {
  margin: 0;
  box-shadow: none;
}

.react-datepicker__navigation--previous {
  border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
  border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
  background: none;
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
  height: 36px;
  padding: 5px 10px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 50% !important;
  background-color: #561c4e !important;
}

.react-datepicker__time-container  .react-datepicker__time  .react-datepicker__time-box  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #561c4e !important;
}

.custom_checkbox {
  position: relative;
  display: inline-block;
  line-height: 25px;
}

.custom_checkbox input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 50%;
  position: absolute;
  cursor: pointer;
}

.custom_checkbox input[type="checkbox"]:checked + span:before {
  content: "";
  text-rendering: auto;
  text-align: center;
  border-color: transparent;
  background: url(../images/tick-20.png) no-repeat;
  border: 0;
}

.custom_checkbox .disableactive + span:before {
  content: "\f00c";
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #ff8400;
  text-align: center;
  text-align: center;
  border-color: #ff8400;
}

.custom_checkbox span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 1px;
  pointer-events: none;
  line-height: 20px;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
}

.custom_checkbox span {
  position: relative;
  padding: 0 0 0 25px;
  pointer-events: none;
  color: #3d3d3d;
  display: inline-block;
}

.custom_radio {
  position: relative;
  display: inline-block;
}

.custom_radio input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

.custom_radio input[type="radio"]:checked + span:before {
  background: url(../images/orange-tick.png) no-repeat;
  z-index: 1;
  border: 0px;
}

.custom_radio span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #d0cfcb;
  left: 0;
  top: 1px;
  pointer-events: none;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.custom_radio span {
  position: relative;
  padding: 0 0 0 30px;
  pointer-events: none;
  color: #353535;
  display: block;
}

.ckbox_custmze {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ckbox_custmze input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: -12px;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
  background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
  background-color: #72624f;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
  display: block;
}

.ckbox_custmze .checkmark:after {
  left: 10px;
  top: 5px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.error {
  color: #f00;
  font-weight: normal;
}

span.error {
  background: #ffbaba;
  color: #d8000c;
  padding: 5px 10px;
  font-size: calc(10px + (11 - 10) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  display: inline-block;
  font-weight: normal;
  z-index: 4;
  line-height: 1.1;
}

span.error:before {
  content: "";
  border-bottom: 5px solid #ffbaba;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 0;
}

.alert {
  padding: 10px 15px;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  display: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.alert_fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  max-width: 1260px;
  margin: 0 auto;
  padding: 10px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.alert_success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert_danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert_fixed p {
  margin: 0;
  font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.alert_fixed a {
  font-size: calc(22px + (25 - 22) * ((100vw - 300px) / (1600 - 300)));
  right: 20px;
  top: 5px;
  position: absolute;
}

.display_inblock {
  display: inline-block;
}

.display_block {
  display: block;
}

.relative {
  position: relative;
}

.vmid_sec:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.vmid_in {
  display: inline-block;
  vertical-align: middle;
  width: 98%;
}

.tab_mobtrigger {
  display: none;
}

.filter_tabsec,
.main_tabsec {
  margin: 0 0 40px;
}

.main_tabsec > .nav-tabs {
  border: 0;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-radius: 50px;
  margin: 0 0 20px;
}

.main_tabsec > .nav-tabs > li {
  margin: 0;
  display: table-cell;
  float: none;
  text-align: center;
  width: 50%;
  vertical-align: middle;
  border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs > li:first-child {
  border-left: 0;
}

.main_tabsec > .nav-tabs > li > a {
  background: #b69a6b;
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #fff;
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  text-transform: uppercase;
  padding: 17.5px 15px;
}

.main_tabsec > .nav > li > a:focus {
  background: none;
}

.main_tabsec > .nav-tabs > li.active > a,
.main_tabsec .nav-tabs > li.active > a:focus,
.main_tabsec .nav-tabs > li > a:hover {
  border: 0;
  background: #0b0405;
  color: #fff;
}

.main_tabsec > .nav-tabs > li > a:after {
  content: "";
  border: 18px solid #0b0405;
  border-color: #0b0405 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -36px;
  margin-left: -18px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.main_tabsec .nav-tabs > li.active > a:after {
  opacity: 1;
}

.main_tabin {
  border-radius: 4px;
}

.filter_tabsec {
  margin: 0 0 40px;
}

.filter_tabsec .nav-tabs {
  margin: 0 0 30px;
}

.filter_tabsec .nav-tabs > li {
  width: 17%;
  margin: 0;
  display: inline-block;
  float: none;
  text-align: center;
  vertical-align: middle;
}

.filter_tabsec .nav-tabs > li:first-child {
  border-left: 0;
}

.filter_tabsec .nav-tabs > li > a {
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #272626;
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  text-transform: uppercase;
  padding: 0 0 5px;
  display: block;
  font-family: 'caviar_dreamsbold';
}

.filter_tabsec .nav > li > a:focus {
  background: none;
}

.filter_tabsec .nav-tabs > li:hover > a,
.filter_tabsec .nav-tabs > li.active > a,
.filter_tabsec .nav-tabs > li.active > a:focus,
.filter_tabsec .nav-tabs > li.active > a:hover {
  border: 0;
  background: none;
  color: #561c4e;
}

.filter_tabsec .nav-tabs > li > a:after {
  content: "";
  background: #561c4e;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.filter_tabsec .nav-tabs > li:hover > a:after,
.filter_tabsec .nav-tabs > li.active > a:after {
  opacity: 1;
}

.modal-dialog {
  margin: 0.5rem;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
  min-height: -webkit-calc(100% - (0.5rem * 2));
}

.modal-content {
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 14px;
  /*border:1px solid #b79a6c*/
  border: 0;
}

.poup_wrap {
  width: 660px;
}

.popup_in {
  padding: 28px 60px 54px;
}

.close_popup {
  font-size: calc(36px + (38 - 36) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  line-height: 32px;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 32px;
  width: 32px;
  color: #231f20;
  text-align: center;
}

.chosen-container .chosen-results .highlighted {
  background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
  background: none;
}

.tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 45px;
  height: 22px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.tgl + .tgl-btn:after {
  left: 1px;
}

.tgl + .tgl-btn:before {
  display: none;
}

.tgl:checked + .tgl-btn:after {
  left: 50%;
}

ul.tg-list {
  float: left;
  padding-top: 7px;
  list-style: none;
  padding-left: 0;
}

.tgl-light + .tgl-btn {
  background: #f9f9f9;
  padding: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #b4b4b4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 22px;
  height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
  background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
  display: none;
}

.mobile_hmenu_list .submenu-arow {
  display: block;
  width: 40px;
  background: #e6e6e6;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
}

.submenu-arow:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  top: 50%;
  line-height: 1;
  margin-top: -11px;
  font-size: calc(22px + (22 - 22) * ((100vw - 300px) / (1600 - 300)));
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  display: block !important;
}

.open-submenu .submenu-arow:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: block !important;
}

.hsearch_sec,
.hsearch_sec_mbl {
  width: 380px;
  position: absolute;
  right: 0px;
  top: 40px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 3;
  visibility: hidden;
}

.hsearch_sec.open,
.hsearch_sec_mbl.open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.hsearch_bx {
  position: relative;
}

.hsearch_bx .form-control {
  height: 48px;
  border: 0;
}

.hsearch_bx .btn {
  background: #ecdfcc url(../images/search.png) no-repeat center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 65px;
  border-radius: 0;
  margin: 0;
  height: auto;
  padding: 0;
}

.hsearch_bx .btn:hover {
  background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
  border: 0;
  border-radius: 8px;
  -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
  box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
  overflow: visible;
  height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
  opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
  line-height: 52px;
  padding: 0 26px;
  color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
  height: 54px;
  padding: 0 26px;
  float: left;
}

.hsearch_bx .re_select .Select-input > input {
  line-height: 34px;
}

.hsearch_trigger_img img {
  width: 26px;
}

.hsearch_trigger_img {
  margin: 5px 0 0 10px;
}

.hmenu_sec ul {
  padding: 0;
  list-style: none;
  text-align: initial;
  margin-top: 3px;
}

.hmenu_list {
  margin: 0;
}

.hmenu_list > li {
  display: inline-block;
  position: relative;
}

.hmenu_list > li > a > span {
  position: relative;
}

.hmenu_actions > li {
  list-style: none;
  position: relative;
}

.hordertype_sec a {
  display: block;
  padding: 15px 22px;
  color: #000;
  background: #561c4e;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  position: relative;
  letter-spacing: 0.5px;
  margin: 0 15px 0 25px;
  line-height: 1;
  border-radius: 5px;
}

.hordertype_sec a:hover {
  color: #fff;
  background: #fdd18c;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
  margin: 0;
  padding: 0px;
  background: #fff;
  z-index: 99;
  width: 220px;
  position: absolute;
  left: 0px;
  top: 50px;
  display: none;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li:hover a{
  background: #000;
  color: #fdd18c;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover > ul {
  display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
  font-family: 'caviar_dreamsbold';
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #1b0000;
  text-transform: uppercase;
  padding: 10px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.078);
}

/*.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
  color: #ab2225;
}*/

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
  display: none;
  background: #f7f7f7;
}

.mobile_hmenu_list ul.submenu_list li a {
  display: block;
  padding: 10px 15px;
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #333;
  border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
  color: #561c4e;
}

.hsign_sec > a {
  display: inline-block;
  position: relative;
}

.hsign_sec > a:hover {
  color: #000;
}

.hsearch_sec .search_result,
.hsearch_sec_mbl .search_result_mbl,
.product_search_result {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  max-height: 300px;
  overflow-y: auto;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: none;
}
.product_search_result {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 100%;
}

.search_result li,
.search_result_mbl li,
.product_search_result li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.search_result li a,
.no-pro-found,
.search_result_mbl li a,
.product_search_result li a {
  color: #333;
  display: block;
  padding: 9px 15px;
}

.search_result li a:hover,
.search_result_mbl li a:hover,
.product_search_result li a:hover {
  background: #fdd18c;
  color: #fff;
}

.hsearch_sec.open .input-sec,
.hsearch_sec_mbl.open .input-sec {
  position: relative;
}

.hsearch_sec.open .input-sec input,
.hsearch_sec_mbl.open .input-sec input {
  padding-right: 10px;
  margin-bottom: 0;
  border-color: rgba(0, 0, 0, 0.3);
}
.htico_search {
  position: relative;
  margin-left: 13px;
}
.search_text_clear {
  position: absolute;
  right: 10px;
  top: 11px;
  display: none;
}

.htico_cart {
  position: relative;
  margin-left: 10px;
  padding-left: 5px;
}

.htico_cart > a {
  position: relative;
  display: block;
}

.hcartdd_trigger.active:before {
  opacity: 1;
  visibility: visible;
}
header .hcart_dropdown {
  display: none;
}

body.cart-items-open {
  overflow: hidden !important;
}
body.cart-items-open .logo-main-section:before {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.68);
  z-index: 9;
}
.cart_info {
  display: inline-block;
  vertical-align: top;
  color: #3d3d3d;
}

.cart_info .cart_into-text h4 {
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  margin: 0 0 6px;
  color: #161616;
  text-transform: capitalize;
}

.cart_left .help-block {
  font-style: italic;
  color: #a7a4a4;
  margin: 5px 0 0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.cart_extrainfo {
  color: #3d3d3d;
}

.cart_extrainfo p b {
  color: #959595;
  font-style: italic;
  font-weight: normal;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.cart_extrainfo p,
.notes-block {
  margin: 0;
  color: #959595;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  padding-left: 10px;
}

.cart_price {
  position: relative;
  margin: 0;
  padding-right: 17px;
}

.cart_price p {
  margin: 0;
  color: #161616;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}

.cart_remove {
  background: #b0b0b0;
  position: absolute;
  top: 22px;
  right: 15px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cdd-details  .chekout_cart_bar  .checkout-right-body-section  .product-details-parent  .cart_row  .cart_remove {
  right: 17px;
  top: 27px;
}

.cdd-details  .chekout_cart_bar .checkout-right-body-section .overall-parent .product-details-parent .cart_row .cart_right
  .cart_price {
  padding-right: 22px;
}

.cdd-details  .chekout_cart_bar .checkout-right-body-section .overall-parent .product-details-parent .cart_row .cart_right
  .cart_price  p {
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.cdd-details-rhs .check_out_fourcommon .cart_info .cart_into-text  h4 {
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.cart_remove:hover {
  background-color: #000;
}
.cart_remove img {
  width: 7px;
}

.qty_bx {
  width: 110px;
  height: 40px;
  position: relative;
  padding: 0 36px;
  display: inline-block;
}

.qty_bx span {
  position: absolute;
  width: 34px;
  height: 100%;
  display: block;
  top: 0%;
  bottom: 0;
  text-indent: 9999px;
  overflow: hidden;
  cursor: pointer;
  margin: auto;
  background: #fff;
  border: 2px solid #d68407;
  background: #dfc288;
  transition: 0.3s linear all;
  border-radius: 5px;
}

.qty_bx span.qty_minus {
  left: 0px;
}

.qty_bx span.qty_plus {
  right: 0px;
}

.qty_bx input {
  height: 100%;
  border: none;
  color: #060203;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  margin: 0;
  padding: 2px;
  /*background: #060203;*/
  border-radius: 5px;
  font-weight: bold;
}

.qty_bx span:after {
  position: absolute;
  width: 9px;
  height: 9px;
  display: block;
  top: 50%;
  margin-top: -5px;
  content: "";
}

.qty_bx span.qty_plus:after {
  width: 2px;
  height: 14px;
}

.qty_bx span.qty_minus:before {
  position: absolute;
  content: "";
  background: #fff;
  width: 14px;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}

.qty_bx span.qty_plus:before,
.qty_bx span.qty_plus:after {
  position: absolute;
  content: "";
  background: #dfc288;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}

.checkout-total .qty_bx span.qty_plus:before,
.checkout-total .qty_bx span.qty_plus:after{
  background: #fff;
}

.qty_bx span.qty_plus:before {
  width: 14px;
  height: 2px;
}

.cart_footer .cart_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.cart_footer .cart_row p {
  margin: 0;
}

.discount_info {
  margin-left: 15px;
}

.discount_info p {
  margin: 0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  color: #3d3d3d;
}

.promo_delico_parent {
  position: relative;
}

.promo_delico {
  position: absolute;
  top: 0;
  left: -21px;
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  color: #3d3d3d;
}

.promo_delico:hover {
  background-color: #b69a6b;
  color: #fff;
}

.cart_footer_totrow p {
  font-size: calc(18px + (25 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.cart_footer_totrow p.help-block {
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}

.cart_footer_totrow span {
  font-size: calc(18px + (25 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.indication_progress {
  height: 12px;
  overflow: hidden;
  border-radius: 10px;
  background: #561c4e;
  position: relative;
}

.progress_bar {
  content: "";
  height: 12px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  background: #f7d3bd;
  border-radius: 10px;
  -webkit-transition: 0.4s linear all;
  transition: 0.4s linear all;
}

.indication .help-block {
  color: #cdaf9d;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  margin: 7px 0 0;
}

.delivery_info {
  background: #f5f5f5;
  padding: 24px 35px;
}

.delivery_address {
  margin: 0 0 20px;
}

.delivery_info h4 {
  color: #838181;
  font-weight: 700;
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  text-transform: uppercase;
}

.delivery_info p {
  color: #3d3d3d;
  font-weight: 700;
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
}

.delivery_link {
  color: #3d3d3d;
  line-height: 1;
  border-bottom: 1px solid #b4b4b4;
  margin: 10px 0 0 0;
  display: inline-block;
  vertical-align: top;
}

.delivery_link:hover {
  border-color: #b69a6b;
}

.delivery_time {
  margin: 0 0 16px;
}

.delivery_handle {
  border-top: 1px solid #d3d1d1;
  padding: 20px 0 0 0;
}

.cart_action .btn_grey {
  margin: 0;
  border-color: #e6e3e3;
}

.cartaction_price {
  font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
  width: 30%;
  cursor: default;
}

.cartaction_price sup {
  font-size: 50%;
  left: -4px;
  top: -7px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

header .cart_action {
  padding: 20px 20px 0 20px;
}

.header_top {
  background: #181818;
  position: relative;
}

.htopinfo {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  padding: 10px 0;
}

.htopinfo p {
  margin: 0;
}

.pop-alert-icon {
  padding: 0 0 15px;
}

.header_bot {
  position: relative;
}

.sticky .alert_fixed {
  top: 95px;
}

.scrollToTop {
  background: rgba(0, 0, 0, 0.7);
  width: 40px;
  height: 40px;
  line-height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  font-size: calc(22px + (24 - 22) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 2px;
  text-align: center;
  color: #fff;
  z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
  background: rgba(0, 0, 0, 1);
  color: #fff;
}

.viewmore_poplink {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.hbslider_sec h2 {
  font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #363636;
}

.hbslider_sec .slick-dots {
  position: absolute;
  bottom: 15px;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.hbslider_sec .slick-dots li,
.home-outlets-list .slick-slider .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.hbslider_sec .slick-dots li button,
.home-outlets-list .slick-slider .slick-dots li button {
  background: none;
  border: 1px solid #fff;
  padding: 0;
  border-radius: 50%;
  font-size: 0;
  width: 12px;
  height: 12px;
}

.hbslider_sec .slick-dots li.slick-active button {
  background: #000;
}
.home-outlets-list .slick-slider .slick-dots li button {
  background: #000;
  border: 1px solid #000;
}
.home-outlets-list .slick-dots li.slick-active button {
  /*background: #f09509;*/
  border: 1px solid #f09509;
}

.hbslider_sec .slick-arrow {
  z-index: 1;
}

.hbslider_sec .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hbslider_sec .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -188px -60px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hbslider_sec .slick-next:hover {
  background: #f5f5f5;
}

.hbslider_sec .slick-next:hover::after {
  background-position: -78px -60px;
}

.hbslider_sec .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hbslider_sec .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -136px -61px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hbslider_sec .slick-prev:hover {
  background: #f5f5f5;
}

.hbslider_sec .slick-prev:hover::after {
  background-position: -26px -61px;
}

.white-popup .hbslider_sec .hbslide {
  text-align: center;
}

.hme-product-inner {
  padding: 50px 50px;
  background: #fff;
  margin: 25px 0 0 0;
}

.hme-product-inner .filter_tabsec .nav-tabs {
  margin-bottom: 64px;
  border-bottom: 0;
}

.hme-product-inner .title_sec {
  margin-bottom: 25px;
}

.hme-product-inner .ttsplitter {
  margin-bottom: 25px;
}

.hme-prt-row .slick-slider {
  padding: 0 90px;
}

.hme-prt-column {
  position: relative;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.hme-product-inner .slick-slide {
  padding: 0 10px;
  outline: 0;
}

.hme-product-inner .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hme-product-inner .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -78px -60px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hme-product-inner .slick-next:hover {
  background: #f5f5f5;
}

.hme-product-inner .slick-next:hover::after {
  background-position: -188px -60px;
}

.hme-product-inner .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hme-product-inner .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -26px -61px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hme-product-inner .slick-prev:hover {
  background: #f5f5f5;
}

.hme-product-inner .slick-prev:hover::after {
  background-position: -136px -61px;
}

.hme-prt-innerslide,
.hme-prt-column,
.hme-prt-inner {
  position: relative;
}

.hme-prt-slidecontent {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.86);
  opacity: 0;
  padding: 15px;
}

.hme-prt-hei {
  text-align: center;
}

.hme-prt-hei img {
  max-height: 100%;
  width: auto;
  height: auto;
}

.hme-prt-column:hover .hme-prt-slidecontent {
  opacity: 1;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.hme-prt-slidecontent-inner {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: relative;
  text-align: center;
}

.hme-prt-slidecontent-inner h3 {
  font-size: calc(15px + (19 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 3px;
  color: #556c11;
  height: 27px;
  overflow: hidden;
}

.hme-prt-slidecontent-inner span {
  font-size: calc(15px + (19 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #556c11;
}

.hme-prt-slidecontent-inner p {
  color: #556c11;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  line-height: 19px;
  margin-bottom: 23px;
  height: 40px;
  overflow: hidden;
  padding: 0 10px;
}

.outlet {
  position: relative;
}

.outlet_inner {
  padding: 63px 35px 76px 35px;
  background: #fff;
  margin: 25px 0 39px 0;
}

.innerproduct > h3 {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
}

.innerproduct-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.addcart_row {
  display: none;
  /*padding: 0 5px;*/
  padding: 0;
  margin: 0 auto;
}
.addcart-row-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-lg {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
}
.inn-product-popup {
  background: #fff;
  width: 100%;
}

.inn-product-popup .container {
  padding: 0;
}
.prodet_banleft {
  width: 100%;
}

.prodet_baninfo {
  width: 100%;
  padding: 30px 0 0 0;
  position: relative;
  text-align: center;
}

.prodet_baninfo .product-tags-list {
  position: relative;
  margin: 0 0 20px 0;
}

.prodet_baninfo .product-tags-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}
.prodet_baninfo .product-tags-list ul li {
  margin: 0 5px;
}

.inn-product-popup-bg {
  position: relative;
}

.inn-product-popup-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 345px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.inn-product-popup-inner {
  position: relative;
  padding-top: 0px;
  line-height: 1;
}

.inner_product_slide {
  outline: 0;
}

.inn-product-popup-inner .slick-next,
.inn-product-popup-inner .slick-prev {
  display: none !important;
}

.inn-product-popup-inner .custom-dots {
  padding: 0;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
}

.inn-product-popup-inner .custom-dots li {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
}

.inn-product-popup-inner .custom-dots li button {
  padding: 0;
  margin: 0;
}

.inn-product-popup-inner .custom-dots li button:before {
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
  background: #561c4e;
}

.inn-product-popup-inner .custom-dots li button {
  text-indent: -9999px;
  background: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
}

.inner_product_slide {
  position: relative;
}

.pthumb_slide {
  margin: 5px;
  border: 1px solid #a2a6ac;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
  -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
  box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
  border-color: #e77600;
}

.popup_social {
  position: absolute;
  bottom: 5px;
  z-index: 1;
  right: 0;
  right: 5px;
}

.popup_social span {
  color: #373535;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
}

.popup_social_icon {
  list-style: none;
  margin-bottom: 0;
  background: #fff;
  padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
  float: left;
}

.popup_social_icon li a {
  color: #2a2a2a;
  padding: 0 10px;
  display: inline-block;
}

.popup_social_icon li a:hover {
  color: #ccc;
}

.popup_social_inner {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup_social_left {
  padding-right: 10px;
}

.copied {
  position: absolute;
  right: 10px;
  top: -21px;
  background: #000;
  padding: 3px 7px;
  color: #fff;
  font-weight: normal;
  font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 2px;
  pointer-events: none;
}

.copied:before {
  content: "";
  border: 7px solid #000;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -11px;
  margin-left: -7px;
}

.inn_product_row {
  margin: 0 auto 0 auto;
}

.inn_product_hea p {
  color: #5b5b5b;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 300;
  margin-bottom: 20px;
}

.inn_product_hea_left h3 {
  font-size: calc(18px + (34 - 18) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 10px 0;
  color: #050505;
  text-transform: capitalize;
}

.prd_chosen_row {
  padding: 30px 30px 30px 30px;
}

.product_chosen_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product_chosen_col {
  width: 100%;
}

.product_chosen_col.product_chosen_col_right {
  margin-right: 0;
}

.product_chosen_col_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
  margin-bottom: 0;
}

.form-group-modifier {
  margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
  width: 100%;
  margin-bottom: 10px;
}

.product_chosen_item_left:nth-of-type(2n + 2) {
  margin-right: 0;
}

.product_chosen_item_left_second {
  float: left;
  width: 47%;
  margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n + 2) {
  margin-right: 0;
}

.select_full {
  width: 100%;
}

.product_chosen_hea h6 {
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #000000;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 0;
  z-index: 1;
  padding-right: 10px;
}

.product_chosen_hea {
  position: relative;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
  width: 100%;
  height: 47px;
  line-height: 40px;
  border: 1px solid #e6e5e5;
  font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
  background: url(../images/spirite_whole.png) no-repeat center;
  background-position: -246px -62px;
  width: 28px;
  height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
  background: url(../images/spirite_whole.png) no-repeat center;
  width: 28px;
  height: 28px;
  background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
  top: 10px;
  right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
  background: #ecdfcc;
}

.pmodifier_ddlist ul {
  padding: 0;
  margin: 7px 0;
  list-style: none;
}

.pmodifier_ddlist li {
  background: #ecdfcc;
  padding: 3px 35px 3px 17px;
  border: 1px solid #dcd8d8;
  border-radius: 20px;
  position: relative;
  font-weight: 700;
  color: #181818;
  margin: 0 0 4px;
}

.pmodifier_close {
  background: url(../images/close-modifier.png) no-repeat center;
  width: 48px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 20px 20px 0;
  margin: auto 0;
}

.pmodifier_close:hover {
  background-image: url(../images/cross1.png);
  background-color: #1c1c1c;
}

.product_chosen_addons {
  width: 100%;
}

.chosen_adn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 10px 0;
}

.chosen_adn_right {
  line-height: 0;
}

.chosen_adn_left span {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  z-index: 1;
  padding-right: 10px;
}

.radio.chosen_adn_right label {
  padding-left: 0;
}

.radio .qty_bx label {
  font-weight: 700;
}

.product_chosen_col_right .custom_checkbox {
  width: 100%;
}

.product_chosen_col_right .custom_checkbox span {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  color: #72624f;
  position: relative;
  display: block;
  margin-bottom: 0;
  background: #fbfbfb;
  z-index: 1;
  padding-right: 30px;
  padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
  right: 0;
  left: auto;
  top: -5px;
}

.product_chosen_col_right .chosen_adn {
  margin-bottom: 16px;
}

.text-box {
  width: 100%;
}

.text-box em {
  text-transform: uppercase;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #181818;
  text-align: right;
  float: right;
}

.text-box textarea {
  width: 100%;
  height: 120px;
  padding: 15px 15px;
}

textarea {
  resize: none;
}

.chosen_upload {
  float: right;
  text-align: right;
  width: 100%;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  outline: 0;
}

.inputfile + label {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 11.5px 15px;
  outline: 0;
  background: #ecdfcc;
  max-width: 160px;
  border: 1px solid #e6e5e5;
  text-overflow: ellipsis;
}

.inputfile + label:hover {
  background: #333;
}

.inputfile + label:hover span {
  color: #fff;
}

.no-js .inputfile + label {
  display: none;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.chosen_upload_inner label span {
  color: #72624f;
}

.inputfile-4 + label {
  color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
  color: #722040;
}

.inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #d3394c;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
  background-color: #722040;
}

.inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background-color: 722040;
}

.prd_chosen_sub_row {
  text-align: center;
  padding: 0 30px 20px;
}

.prd_chosen_sub_row > p {
  font-size: calc(22px + (35 - 22) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  font-family: 'caviar_dreamsbold';
  margin-bottom: 5px;
}

.prd_chosen_sub_row p > sup {
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.prd_chosen_sub_col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 576px;
  /* margin: 0 auto;*/
}
.popup_addcart_cls {
  max-width: 100%;
}

.prd_chosen_sub_col .alert {
  margin: 0;
  width: 100%;
}

.prd_chosen_sub_item_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.prd_chosen_sub_item_right {
  width: 100%;
}

.prd_chosen_sub_item_right button {
  border: 0;
  padding: 15px 15px;
  width: 100%;
  color: #fff;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  outline: 0;
  font-family: 'caviar_dreamsbold';
  margin-bottom: 0;
  border-radius: 0;
}

.prd_chosen_sub_item_right button:hover {
  background: #fdd18c;
}

.modal-dialog-tgrfromhome  .inn-product-popup  .productdetail-rightside-maindiv  .prd_chosen_sub_row
  .prd_chosen_sub_col  .prd_chosen_sub_item_right.prd_chosen_item_full {
  width: 100%;
}

.modal-dialog-tgrfromhome  .inn-product-popup  .productdetail-rightside-maindiv  .prd_chosen_sub_row  .prd_chosen_sub_col {
  margin-left: 0;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
  max-width: 100%;
  margin: 0;
  letter-spacing: 0.5px;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
  background: #fdd18c;
}

.inn-product-popup  .productdetail-rightside-maindiv  .inn_product_row  .prd_chosen_sub_col .prd_chosen_sub_item_left .qty_bx {
  width: 115px !important;
}

.prd_chosen_sub_item_left .qty_bx {
  width: 100px !important;
}

.prd_chosen_sub_item_left .qty_bx .qty_minus {
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.prd_chosen_sub_item_left .qty_bx .qty_plus {
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.skip {
  position: absolute;
  top: 0;
  right: 0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-weight: 700;
  background: #dbd9d9;
  max-width: 89px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 7px 15px;
  color: #181818;
}

.skip:hover {
  color: #fff;
  background: #333;
}

.prd-sub-bg {
  background: #fff;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 10px 0 10px 10px;
  -webkit-border-radius: 10px 0 10px 10px;
  box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
  position: relative;
}

.prd-sub {
  max-width: 862px;
  position: relative;
  margin: 0 auto;
}

.prd-sub h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
}

.prd-sub .slick-slide {
  padding: 0 5px;
}

.prd-sub .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: -69px;
  top: 23%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.prd-sub .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -27px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.prd-sub .slick-next:hover {
  background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
  background-position: -154px -123px;
}

.prd-sub .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: -69px;
  top: 23%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.prd-sub .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -86px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.prd-sub .slick-prev:hover {
  background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
  background-position: -213px -123px;
}

.prd-sub-slide-content {
  text-align: center;
  margin-top: 10px;
}

.prd-sub-slide-content h5 {
  font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  color: #363636;
  margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
  background: #f4b56b;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
  background: #e48f2c;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
  background: #333;
}

.prd-sub-slide-qty .qty_bx input {
  background: #fff;
  width: 62px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
  background: #ecdfcc;
  padding: 4px 0 0px 0;
  border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
  width: 146px;
  height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
  height: 40px;
}

.prd-sub-slide-submit {
  background: #181818;
}

.prd-sub-slide-submit a {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  padding: 13px 0;
  display: block;
  text-transform: uppercase;
  color: #fff;
  border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
  background: #e48f2c;
}

.slick-slide {
  outline: 0;
}

.prd-sub-slide-content .btn {
  width: 100%;
}

.checkout_hea {
  position: relative;
}

.checkout_hea::after,
.check_pg::after {
  display: block;
  content: "";
  clear: both;
}

.checkout_hea h3 {
  font-size: calc(18px + (27 - 18) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 5px;
}

.checkout_hea p {
  margin-bottom: 25px;
  color: #5b5b5b;
}

.checkout_hea_inner {
  margin-top: 8px;
}

.check_pg {
  position: relative;
}

.check_pg_inner {
  background: #fff;
  padding: 75px 0px 29px 0px;
  margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
  padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
  width: auto;
  margin-right: 0;
}

.check_pg_inner .slick-slide img {
  text-align: center;
  margin: auto;
}

.check_pg_inner .slick-slide {
  padding: 0 7.5px;
}

.check_pg_inner .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: 0;
  top: 32.5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
  z-index: 1;
}

.check_pg_inner .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -27px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.check_pg_inner .slick-next:hover {
  background: #f5f5f5;
}

.check_pg_inner .slick-next:hover::after {
  background-position: -154px -123px;
}

.cart_header_title {
  padding: 40px 0 0px;
  text-align: center;
  margin-top: inherit;
}

.thankyou-main-div .innersection_wrap .cart_header_title p {
  text-align: center;
  margin-bottom: 0px;
  text-transform: capitalize;
  color: #fff;
}

.thankyou-main-div .innersection_wrap .cart_header_title .cart_innr_header h4{
  color: #fff;
}

.thank-order-detaildiv .tnk-delivery .order-con-footer .tnk-chk-order .button:hover {
  background: #fdd18c;
}

.checkout-main-div .cart_header_title p {
  text-align: center;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.cart_header_title .cart_innr_header {
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.cart_header_title .cart_innr_header h4 {
  position: relative;
  font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  right: -10px;
  padding-right: 10px;
  margin: inherit;
  text-transform: uppercase;
}

.cart_header_title .cart_innr_header img {
  position: relative;
  left: 20px;
  top: -15px;
  width: 40px;
  height: 40px;
}

.check_pg_inner .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: 0;
  top: 32.5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
  z-index: 1;
}

.check_pg_inner .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -86px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.check_pg_inner .slick-prev:hover {
  background: #f5f5f5;
}

.check_pg_inner .slick-prev:hover::after {
  background-position: -213px -123px;
}

.checkoutpage_form_outer .form-group {
  margin-bottom: 15px;
}

.check_out_fourcommon {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
}

.checkoutpage_form_outer {
  width: 100%;
  margin: 0 auto;
}

.check_out_fourcommon .redeem-col .redeem-item .redeem_apply .focus-out input {
  border-radius: 0;
  width: 100%;
}

.check_out_fourcommon .redeem-col .redeem-item .redeem_apply button {
  border-radius: 0;
}

.cdd-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cdd-details .ttsplitter {
  margin-bottom: 0;
}
.checkout-total {
  width: 100%;
  margin: 25px auto 0px auto;
}
.checkout-total::after {
  display: block;
  content: "";
  clear: both;
}
.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {
  padding: 14px 20px;
  position: relative;
  background: #87311d;
  border-bottom: 0px;
  text-align: center;
  font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
  margin-bottom: 0;
}
.checkout-total .cart-header {
  padding: 0;
  background: inherit;
  border: 0;
  margin: 0;
}
.checkout-total .cdd-details-rhs .cart-header h4 {
  margin: 0;
  line-height: normal;
}
.checkout-total .cart-header:after {
  display: none;
}
.checkout-total .cdd-details-lhs {
  width: 49%;
  position: relative;
  padding: 0;
  background: #fff;
  -webkit-box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
}
.checkout-total .cdd-details-rhs {
  width: 49%;
  padding: 0;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
}
.checkout-right-body-section {
  padding: 10px 15px 20px 15px;
}
.checkout-body-section {
  padding: 20px;
}

.chk_hea {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  /*    padding: 0px 0px 5px;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px 0;
  line-height: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  color: #464646;
  position: relative;
}

.cdd-details .checkout-body-section .chk_hea {
  background: #fafafa;
  padding: 12px 10px;
}

.cdd-details .checkout-control-group-top .form-group input {
  border-radius: 0;
}

.chk_hea > span {
  color: #000000;
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-family: 'caviar_dreamsregular';
  padding-right: 30px;
}

.checkout-total .chosen-container .chosen-results .highlighted {
  background: #ecdfcc;
}

.checkout-total .checkout-control-group-top a {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  line-height: 1;
  border-bottom: 1px solid #b4b4b4;
  margin: 5px 0 0 0px;
  display: inline-block;
  vertical-align: top;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {
  /*color: #f09509;*/
}

.checkout-total .checkout-control-group-top {
  padding: 0px 0 25px 0;
  /*    border-bottom: 1px solid #eeeded;*/
}

.checkout-control-group-topband {
  border-bottom: 1px solid #939292;
  padding: 0 0 35px 0;
}

.checkout-control-group-topband span {
  float: right;
  font-weight: normal;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.checkout-total .checkout-control-group-middle {
  margin: 0px 0 0px 0;
  padding: 25px 0 0px 0;
  width: 100%;
  /*border-bottom: 1px solid #eeeded;*/
}

.checkout-total .checkout-control-group-bottom {
  padding: 25px 0 0 0;
}
.checkout-billing-address {
  padding: 25px 0 20px 0;
  width: 100%;
  border-bottom: 1px solid #eeeded;
}
.check-billing {
  padding: 20px 0 0 0;
}
.checkout-billing-address .chk_hea {
  padding-bottom: 0;
  margin-bottom: 0;
}
.checkout-total .checkout-control-group-middle .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.checkout-control-group-middle  .form-group1234  .react-datepicker__input-container  input {
  border-radius: 0;
}

.checkout-total .checkout-control-group-bottom textarea {
  border-radius: 0;
}

.cdd-details .checkout-body-section .would-like-bottom .chk_hea {
  padding-left: 30px;
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
  width: 48%;
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
  width: 49%;
}

.checkout-total .checkout-control-group-bottom textarea {
  padding: 10px 15px;
  height: 100px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
  outline: 0;
}

.checkout-total textarea::-webkit-input-placeholder {
  color: #595656;
}

.checkout-total textarea:-moz-placeholder {
  color: #595656;
}

.checkout-total textarea::-moz-placeholder {
  color: #595656;
}

.checkout-total .cart_body {
  padding: 0;
  box-shadow: none;
}

.checkout-total .form-group.controls-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.checkout-total .form-group.controls-three > div {
  width: 49%;
}

.checkout-total .form-group.controls-three > div input {
  margin-bottom: 0;
}

.order-detail-inner {
  padding: 82px 0 0 0;
}

.order-detail-inner-icon {
  position: relative;
  max-width: 604px;
}

.order-detail-inner-icon-left {
  position: absolute;
  left: 0;
  top: 2px;
}

.order-detail-scotter {
  position: relative;
  z-index: 2;
}

.order-detail-scotter::after {
  content: "";
  background: #fff url(../images/spirite_whole.png) no-repeat;
  background-position: -283px -126px;
  width: 41px;
  height: 24px;
  left: 0;
  top: -2px;
  position: absolute;
}

.order-detail-scotter::before {
  content: "";
  background: #fff url(../images/spirite_whole.png) no-repeat;
  background-position: -347px -126px;
  width: 22px;
  height: 22px;
  right: 0;
  top: -2px;
  position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
  position: absolute;
  right: 0;
  top: 3px;
  left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
  position: static;
  left: inherit;
  top: inherit;
  width: 100%;
  text-align: center;
  display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
  display: block;
  clear: both;
  content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  max-width: 497px;
  left: 0;
  right: 0;
  height: 10px;
  margin: auto;
  top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
  vertical-align: middle;
}

.delivery_total::after {
  display: block;
  content: "";
  clear: both;
}

.delivery_total {
  margin: 10px 0 0px 0;
}

.delivery_total_left {
  float: left;
  text-align: left;
  width: 50%;
}

.delivery_total_left h3 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 700;
  color: #1c1b1b;
  line-height: 22px;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}

.delivery_total_left span {
  display: block;
  color: #000;
  line-height: 20px;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.delivery_total_left.delivery_total_right {
  float: right;
  text-align: right;
}

.delivery_total.delivery_total_number {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.delivery_total.delivery_total_number .delivery_total_left h2 {
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #3d3d3d;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
}

.delivery_total.delivery_total_number .delivery_total_left h4 {
  color: #060203;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
  font-family: 'caviar_dreamsbold';
}

.pickup-order-div:before {
  display: none;
}

.pickup-order-div .delivery_total_left {
  width: 100%;
  text-align: center;
}

.pickup-order-div .delivery_total_left img {
  width: 25px;
}

.cart-product-desc-inner {
  padding-left: 20px;
}

.cart-product-desc-inner span {
  display: block;
  color: #3d3d3d;
  line-height: 19px;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.chekout_cart_bar .cart_footer {
  padding: 28px 15px 10px 15px;
  margin: 0;
}

.chekout_cart_bar .cart_left {
  padding-left: 0;
}

.chk_hea .custom_checkbox {
  position: absolute;
  top: -5px;
  right: 0;
}

.chk_hea .custom_checkbox span {
  vertical-align: middle;
}

.chk-payment-btn-row {
  /*margin: 10px 0 0 0*/
  margin: 30px auto 0 auto;
  max-width: 470px;
}

.check_out_fourcommon .chk-payment-btn-row a.button {
  border-radius: 0;
}

.chk-payment-btn-row .button {
  width: 100%;
  display: block;
  margin: 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  padding: 16px 10px;
  font-family: 'caviar_dreamsbold';
  background: #561c4e;
  color: #fff;
}

.chk-payment-btn-row .button:hover {
  color: #000;
  background: #561c4e;
}

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
  width: 49%;
}

.dwn_quotion {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.dwn_quotion a {
  background: #e6e3e3;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  display: block;
  padding: 10px 0;
  color: #0d0d0d;
  text-transform: uppercase;
  margin-bottom: 0;
}

.dwn_quotion a:hover {
  background: #333;
  color: #fff;
}

.redeem {
  position: relative;
  margin-bottom: 20px;
}

.redeem::after {
  display: block;
  clear: both;
  content: "";
}

.redeem-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.redeem-col {
  width: 49%;
  background: #fff;
  padding: 40px 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: 1px solid #e4e4e4;
}
.redeem-col-full {
  width: 100%;
}
.redeem-col-full .redeem-item {
  max-width: 660px;
  margin: 0 auto;
}
.redeem-col.redeem-col-right {
  background: #fff;
}

.redeem-item-hea {
  padding-left: 10px;
  position: relative;
}

.redeem-item-hea h4 {
  font-size: calc(18px + (27 - 18) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  color: #000;
  margin-bottom: 0;
}
.redeem-item-hea span {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
}
.redeem-col:first-of-type .redeem-item-hea h4,
.redeem-col:first-of-type .redeem-item-hea span {
  color: #000;
}

.redeem-item-hea .points {
  position: absolute;
  right: 0;
  background: #1c1c1c;
  padding: 8px 2px;
  max-width: 260px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  top: 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.redeem-item-hea .points b {
  color: #f4e329;
  font-weight: bold;
}
.redeem-col:first-of-type .redeem-item-hea .points b {
  color: #54abd4;
}

.redeem_apply {
  position: relative;
  margin-top: 5px;
}

.redeem_apply .btn {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  z-index: 11;
  margin: 0;
  background: #561c4e;
  padding: 16px 22px;
  border-radius: 0 5px 5px 0;
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.redeem_apply .btn:hover {
  background: #fdd18c;
  color: #000;
}
.redeem-item-hea-inner {
  width: 41.37770897832817%;
}

.chk-payment {
  text-align: center;
}

.chk-payment-col-radio {
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.birthday-info-div .birthday-info-middle p {
  font-family: 'caviar_dreamsbold';
}

.chk-payment-col-radio li {
  max-width: 257px;
  margin-right: 10px;
  margin-bottom: 12px;
  padding: 15px 20px 15px 20px;
  border: 1px solid #d0cfcb;
  border-radius: 7px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chk-payment .chk-payment-col-radio li .radio_con {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
}
.chk-payment .chk-payment-col-radio li label {
  padding-left: 35px !important;
}

/* 
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

*/
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  margin-top: -19px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #d0cfcb;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../images/tick-20.png) no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:after {
  width: 28px;
  height: 28px;
  background-position: -455px -122px;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:before {
  width: 28px;
  height: 28px;
}

.chk-payment-col-radio.promotion-ul-topcls li {
  width: auto;
  margin-right: 0;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  top: 0px;
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  top: 0px;
}

.chosen_radio_inner {
  width: 100%;
}

.expand {
  background: #eaeaea;
  padding: 10px 15px;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.expand h3 {
  color: #000;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  width: 100%;
}

.expand::after {
  content: "\f107";
  right: 10px;
  display: block;
  top: 3px;
  font-family: "FontAwesome";
  position: absolute;
  color: #000;
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.expand.active::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.expand_sub h1 {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  color: #72624f;
  position: relative;
  margin-bottom: 0;
  background: #fbfbfb;
  z-index: 1;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 10px;
}

.expand_sub h1 span {
  width: 80%;
}

.panel-heading.expand_hea {
  cursor: pointer;
}

.expand_sub h4.panel-title {
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.expand_sub_body {
  width: 100%;
}

.expand_sub h4::after {
  content: "\f107";
  right: 0;
  display: block;
  top: -7px;
  font-family: "FontAwesome";
  position: absolute;
  color: #000;
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.expand_hea.active h4.panel-title::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.chk-payment-btn {
  margin-bottom: 34px;
}

.chk-payment-btn-row .btn {
  width: 100%;
  border: 0;
}

.chk-payment-btn-row .row {
  margin-right: 0;
  margin-left: 0;
}

.chk-payment-btn-row [class*="col-"] {
  padding: 0;
}

.tnk-you {
  position: relative;
  padding: 110px 0;
}

.tnk-you::after {
  display: block;
  clear: both;
  content: "";
}

.tnk-header {
  max-width: 683px;
  margin: 0 auto;
  text-align: center;
}

.tnk-header .tick {
  padding: 0 15px;
}

.tick h2 {
  font-size: calc(24px + (44 - 24) * ((100vw - 300px) / (1600 - 300)));
  margin: 18px 0 0 0;
  text-transform: uppercase;
}

.tick span {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #0b0405;
}

.tnk-detail {
  margin-top: 20px;
}

.tnk-detail h2 {
  padding: 13px 10px;
  color: #464646;
  text-transform: uppercase;
  background: #fff;
  margin-bottom: 0;
  font-family: 'caviar_dreamsbold';
  font-size: calc(16px + (19 - 16) * ((100vw - 300px) / (1600 - 300)));
}
.Viewreceipt .tnk-detail h2 {
  color: #fff;
  background: #060203;
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.tnk-order {
  /*background: #000000;*/
  background: #343434;
  padding: 30px 15px;
}

.tnk-order h3 {
  margin-bottom: 10px;
  font-size: calc(18px + (36 - 18) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  color: #fff;
  font-family: 'caviar_dreamsbold';
}

.tnk-order p {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  margin-bottom: 0;
  font-family: 'caviar_dreamsbold';
}
.Viewreceipt .tnk-order {
  background: #f5f5f5;
}
.Viewreceipt .tnk-order h3 {
  color: #292929;
}

.Viewreceipt .tnk-order p {
  color: #292929;
  font-family: 'caviar_dreamsregular';
}

.tnk-delivery .order-detail-inner {
  padding-top: 0px;
}

.tnk-delivery {
  padding: 30px 15px;
  background: #fff;
}

.cart_row.tnkorder-first {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cart_row.tnkorder-first > div {
  width: 49%;
}
.cart_row.tnkorder-first > div h5 {
  margin-bottom: 5px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.cart_row.tnkorder-first > div p {
  margin: 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
  display: none;
}

.tnk-delivery .order-detail-inner-icon {
  margin: 0 auto;
}

.tnk-delivery .delivery_total {
  margin: 6px 0 20px 0 !important;
}
.tnk-chk-order {
  margin: 15px auto;
  max-width: 580px;
}

.tnk-delivery .cart_left .help-block {
  text-align: left;
}

.tnk-delivery .cart_info h4 {
  text-align: left;
}

.tnk-delivery .cart_extrainfo {
  text-align: left;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
  padding: 20px 15px 0px 15px;
  margin: 0;
}

.tnk-delivery .tnk_cart_footer .cart_row {
  text-align: left;
  padding: 0px 0px 5px 0px;
}
.tnk-delivery .tnk_cart_footer .cart_row.grant-total-cls {
  margin-bottom: 0;
  padding: 10px 0px 10px 0px; /*border-bottom: 3px solid #f4f4f5;*/
}
.tnk-chk-order .btn {
  width: 100%;
  border: 0;
}

.delivery_datetime_div {
  padding: 10px 0 10px 0 !important;
  margin: 10px 0 0px 0 !important;
}

.remark_notesec {
  margin: 20px 0 0;
  padding: 0 7px;
}

.remark_notesec h4 {
  margin: 0 0 3px;
  font-weight: 700;
  color: #3d3d3d;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.remark_notesec p {
  margin: 0;
}

.mfp-bg {
  z-index: 10042;
}

.mfp-wrap {
  z-index: 10043;
  /*top: 0!important;*/
}

.white-popup {
  max-width: 1026px;
  padding: 0;
  margin: 30px auto;
}

.vouchergift-popup{
  max-width: 780px;
}

.order_popup {
  max-width: 580px;
}
.pouup_in {
  padding: 60px 50px 40px;
}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {
  max-width: 450px;
}

.warning_popup .warning-popup-img {
  width: 75px;
  margin: 0 0 10px 0;
}

button.mfp-close,
button.mfp-arrow {
  margin: 0;
  padding: 0;
}

.alert_popup .mfp-close {
  display: none;
}

.alert_header {
  background: #00989a;
  color: #fff;
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
  padding: 12px 14px;
  position: relative;
  text-align: center;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}

.alert_body {
  text-align: center;
  padding: 20px;
}

.alt_btns a {
  margin: 0 5px;
}

.alt_btns a.button-left {
  background: #561c4e;
  color: #fff;
}

.popup_equalrw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.popup_ttsec {
  width: 42%;
  min-height: 310px;
  padding: 20px 20px;
  min-height: 310px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pop_title {
  position: relative;
  color: #fff;
}

.pop_title h2 {
  font-size: calc(16px + (38 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}

.pop_title small {
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
}

.popup_right {
  width: 58%;
  padding: 20px 60px 20px 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.innervmid_in {
  width: 100%;
}

.delevery_popup .popup_ttsec {
  background: #561c4e;
}

.delevery_popup .popup_ttsec {
  padding-left: 38px;
  padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
  border: 1px solid #dadada;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

.delevery_popup .popup_right .form-group input:-webkit-autofill {
  background: #fff !important;
}

.two-button-row {
  display: flex;
  max-width: 476px;
  justify-content: space-between;
  margin: 0 auto;
}

.two-button-row > div .button {
  display: block;
  margin: 0;
  width: 100%;
}

.two-button-row > div {
  width: 49%;
}
.go_second a {
  background: #a3a3a3;
  color: #fff;
}

.two-button-row .delivery_submit{
  background: #561c4e;
  color: #fff;
}

.two-button-row .delivery_submit:hover,
.go_second a:hover {
  background: #000;
  color: #fff;
}

.error_postal_popup .popup_right .button {
  margin: 0;
}

img.pop-scooter-img {
  max-width: 90px;
  margin-bottom: 15px;
}

.receipt_popup,
.receipt_cateringpopup {
  max-width: 620px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
  padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
  padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
  margin-top: 0;
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
  margin: 30px 0;
}

.error_postal_popup .popup_ttsec {
  background: #561c4e;
}

.error_postal_popup h4 {
  color: #fff;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.poptt_icontop {
  padding-left: 0;
}

.poptt_icontop i {
  position: static;
  margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
  height: 309px;
}

.news_postal_popup .popup_ttsec {
  background: #b79a6c;
  width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
  background: url(../images/news_popupright.png) no-repeat;
  background-size: cover;
  width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin: 6px 0 0 0;
}

.news_subscribe {
  position: relative;
  margin-top: 14px;
}

.news_subscribe .btn {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  z-index: 11;
  border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
  background: #54362c;
  color: #fff;
}

.news_subscribe .form-control {
  height: 51px;
  border: 1px solid #fff;
}

.news_subscribe p {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  margin-top: 12px;
  margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
  background: rgba(24, 24, 24, 0.5);
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 32px;
  line-height: 30px;
  opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
  content: "";
  background: url(../images/spirite_whole.png) no-repeat;
  width: 15px;
  height: 15px;
  background-position: -83px -190px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
  background: rgba(24, 24, 24, 0.9);
}

.order-body {
  text-align: center;
  padding-bottom: 64px;
  border: 1px solid #561c4e;
}

.order_popup .order-body{
  padding-bottom: 0;
}

.order-body .order-heading-top h2{
  color: #fff;
}

.order-body .order-heading-top p{
  margin-bottom: 0;
  color: #fff;
}

.mfp-wrap .mfp-content .order-body h2 {
  font-size: calc(16px + (36 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
  text-transform: uppercase;
  color: #efc789;
}

.mfp-wrap .self_popup .self_pop_col_right .self_outlet h2{
  text-transform: capitalize;
}

#delevery-popup .self_pop_item .self_outlet h2 {
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
  margin-bottom: 6px;
}

.order-body p {
  color: #333;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 20px 0;
}

.order_delivery_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 10px 30px 0 30px;
}

.order_delivery_item li {
  width: 48%;
}

.order_delivery_item li a {
  display: block;
  position: relative;
  background: #e5e5e5;
  padding: 30px 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
.order_delivery_item li a.active,
.order_delivery_item li a:hover {
  background: #561c4e;
}
.header-ordernow-single-img {
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}
.header-ordernow-single-img .order_type_imgwt {
  display: none;
}
.order_delivery_item li a.active .order_type_imgwt,
.order_delivery_item li a:hover .order_type_imgwt {
  display: block;
  margin: 0 auto;
}
.order_delivery_item li a.active .order_type_img,
.order_delivery_item li a:hover .order_type_img {
  display: none;
}
.order_delivery_item h3 {
  color: #000;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 15px 0 0 0;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}
.order_delivery_item li a:hover h3,
.order_delivery_item li a.active h3 {
  color: #fff;
}

.order_icon {
  display: block;
  text-align: center;
  margin: auto;
}

.order_delivery_item .tooltip {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.order_delivery_item .tooltip-inner {
  max-width: 260px;
}

.ordelivery_tooltip {
  position: relative;
}

.order_delivery_mobile_tooltip {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  left: -270px;
  top: 23px;
  background: #181818;
  padding: 10px;
  border-radius: 3px;
  width: 260px;
  margin: 0 auto 5px;
  z-index: 1;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
  opacity: 1;
  visibility: visible;
}

.order_delivery_mobile_tooltip:before {
  content: "";
  position: absolute;
  right: -16px;
  top: 50%;
  margin-top: -7px;
  border: 10px solid #181818;
  border-color: transparent transparent transparent #181818;
  border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
  margin: 0;
  color: #fff;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
  left: auto;
  right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
  border-color: transparent #181818 transparent transparent;
  left: -16px;
  right: auto;
}

.order_delivery_item li.disable .ordericon_link {
  display: none;
}

.order_delivery_item li.disable:hover {
  background: #f9f9f9;
  border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
  display: none;
}

.orderpop_address {
  position: relative;
  height: 100%;
  padding-top: 35px;
}

.orderpop_address p {
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
}

.orderpop_continue_shop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.orderpop_continue_shop .btn {
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
  height: 30px;
  padding: 7px 15px;
  margin: 0;
}

.orderpop_change_address {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}

.orderpop_change_address:hover {
  color: #000;
}

.order_delivery_item li .header-ordernow-single-img img {
  height: 70px;
}

.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
  background: #000;
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  top: 5px;
  right: 5px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
}

.mfp-wrap .warning_popup .mfp-close {
  display: none;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after,
.venue_popup .close-icon-but:after {
  content: "";
  background: url(../images/spirite_whole.png) no-repeat;
  width: 15px;
  height: 15px;
  background-position: -83px -190px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover {
  background: #561c4e;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
  background: rgba(1, 1, 1, 0.9);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
  background: rgba(0, 0, 0, 0.9);
}

.video_popup .order-body {
  padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
  width: 100%;
  background: #000000;
  padding: 30px 20px;
}

.awesome_popup .btn_sec {
  max-width: 500px;
  margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
  margin-bottom: 0;
  margin-top: 0;
  background: #fff;
  color: #000;
}

.awesome_popup .popup_ttsec .button:hover {
  background: #561c4e;
  color: #fff;
}

.awesome_del {
  margin-top: 13px;
  text-align: center;
}

.awesome_del h5 {
  font-size: calc(17px + (19 - 17) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 10px;
  color: #fff;
}

.awesome_del h2 {
  color: #fff;
  
}

.awesome_right h4 {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.awesome_del h5{ color: #000;}
.awesome_del h2{ color: #000; text-transform: inherit; display: inline-flex; padding-left: 30px; position: relative; 
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300))); line-height: 1.2;
  }
.awesome_del h2:before{ position: absolute; left: 0; top: -2px; width: 22px; height: 22px; content: ""; 
  background: url(../images/checked-white.png) no-repeat; }

.awesome_popup {
  max-width: 640px;
}

.datetime_selt_lbl {
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  padding-bottom: 10px;
  color: #a9a9a9;
  text-align: center;
  text-transform: uppercase;
}
.delevery_popup_datetime .btn_sec .button {
  display: block;
  margin: 0;
  width: 100%;
  background: #561c4e;
  color: #fff;
}

.delevery_popup_datetime .btn_sec .button:hover{
  background: #000;
  color: #fff;
}

.white-popup.self_popup {
  max-width: 560px;
  background: #00989a; 
}

.self_popup .self_pop_row{
  border-top: 1px solid #000;
}

.self-back {
  position: absolute;
  top: 13px;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  z-index: 1;
  left: 40px;
  text-transform: uppercase;
}

.self-back:hover {
  color: #000;
}

.self_popup .order-body {
  padding: 0 0 0px 0;
}

.self_popup_hea_row {
  background: #00989a;
  padding: 20px 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.self_popup_hea_col {
  text-align: left;
}

.order-body .self_popup_hea_row h2 {
  color: #fff;
}
.order-body .self_popup_hea_row .self_popup_hea_col h2 {
  text-transform: uppercase;
}

.order-body .self_popup_hea_row p {
  margin-bottom: 0;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
}

.self_popup_hea_col_left {
  padding-right: 15px;
}
.self_popup_hea_col_left img {
  width: 50px;
}

.self_pop_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 21px 15px;
}

.self_pop_col.self_pop_col_right {
  width: 100%;
  margin: 0 auto;
}

.self_pop_col {
  width: 50%;
}

.self_pop_item_loc {
  position: relative;
}

.self_pop_item_map {
  box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
  border: 1px solid #bba075;
  height: 100%;
}

.selfpop_mapouter {
  height: 100% !important;
}

.or-seperator-row {
  position: absolute;
  right: -9px;
  z-index: 1;
  top: 6px;
}

.or-seperator-row .or-seperator {
  position: relative;
  padding: 25px 0px 7px 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 300;
}

.or-seperator-row .or-seperator span {
  padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
  height: 82px;
  width: 1px;
  background: #e5cda6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.self_pop_item_loc p {
  color: #595656;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 170px;
  margin: 0 auto;
  min-width: 288px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 23px;
  border-radius: 10px;
  height: 62px;
}

.loc_icon {
  width: 33px;
  height: 33px;
  background: url(../images/location_btn.png) no-repeat;
  margin-right: 10px;
}

.self_pop_col_right h4 {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  color: #595656;
  text-align: left;
  margin-left: 30px;
}

.self_outlet {
  margin-top: 15px;
  height: calc(100% - 105px);
}

.self_outlet h2 {
  color: #000000;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-align: left;
  margin: 0px 0 10px 5px;
  font-family: 'caviar_dreamsbold';
  font-weight: 700;
}

.self_outlet_inner {
  padding-left: 0;
  list-style: none;
  text-align: left;
  overflow-y: auto;
  margin: 0 0 14px;
  max-height: 190px;
}

.self_outlet_inner li {
  margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
  margin-bottom: 0;
}
.self_outlet_inner li a {
  background: #561c4e;
  padding: 10px 15px;
  display: block;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
  background: #561c4e;
  color: #fff;
}

.self_outlet .btn.cnt_btn {
  width: 100%;
  background: #72624f;
  margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
  background: #333;
}

.self_pop_locbx .form-group .form-control {
  width: 100%;
}

.self_outlet .button {
  margin: 0;
  width: 100%;
  display: block;
  font-family: 'caviar_dreamsbold';
}

.self_pop_locbx {
  margin: 0 0 20px 0;
}

.white-popup.login-popup, .white-popup.signup-popup, .white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup, .white-popup.guest-popup {
  background: inherit;
  max-width: 1086px;
}

.pop-whole-lhs-inner {
  text-align: center;
  max-width: 318px;
}

.pop-whole {
  position: relative;
}

.login-popup .mfp-close, .signup-popup .mfp-close, .forgot-password-popup .mfp-close,
.signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  right: 49px;
  top: 0;
  font-size: 0;
  color: #afafaf;
  margin: 0;
  opacity: 1;
}
.login-popup .mfp-close, .signup-popup .mfp-close, .forgot-password-popup .mfp-close {
  background: #000;
  right: 5px;
  top: 5px;
}

.login-popup .mfp-close:hover, .signup-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover, .signup-corparatetwo-popup .mfp-close:hover, .guest-popup .mfp-close:hover {
  background: #561c4e;
}

.login-popup .mfp-close:after, .signup-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after, .signup-corparatetwo-popup .mfp-close:after, .guest-popup .mfp-close:after {
  content: "";
  background: url(../images/crosslg.png) no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.popup-footer .btn {
  display: block;
  max-width: 271px;
  margin: 0 auto;
  background: #2d4486;
  text-transform: inherit;
  border-radius: 7px;
  border: 0;
}

.popup-footer .btn:hover {
  background: #333;
  color: #fff;
}

.popup-footer .btn i {
  padding-right: 15px;
}

.popup-footer span {
  color: #000;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 3px;
}

.popup-footer .guest_btn {
  background: #70afa7;
  color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
  background: #333333;
  color: #fff;
}

.pop-whole:after {
  display: table;
  clear: both;
  content: "";
}

.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
  height: 20px;
  background-color: #000;
}

.loginsep-popup .mfp-close {
  right: 20px;
  top: -6px;
}

.white-popup.processing {
  max-width: 768px;
}

.process_inner {
  position: relative;
}

.process_col {
  position: relative;
  margin-bottom: 15px;
}

.process_left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.process_right {
  padding-left: 60px;
}

.process_right h5 {
  color: #b0aeae;
  margin-bottom: 5px;
}

.process_right p {
  margin-bottom: 0;
  font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.redeem_bansec {
  position: relative;
  overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
}

.redeem_popsec .pouup_in {
  padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
  font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
}

@-webkit-keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -25px);
    transform: translate(0px, -25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

.location-part-outer {
  padding: 0px !important;
  position: relative;
  overflow: hidden;
}

.location-part iframe {
  width: 100%;
  height: 680px;
}

.location-sidemenu {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 320px;
  height: 100%;
  z-index: 8;
  width: 100%;
}

.location-sidemenu .red-bar {
  background: #db4337;
  padding: 7px 40px;
}

.location-sidemenu .three-lines {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  width: 18px;
  height: 12px;
  background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px
    transparent;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 18px;
}

.location-sidemenu .three-lines:hover {
  background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
  position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
  margin: 0;
  height: 35px;
  border: none;
  padding: 7px 35px 7px 15px;
  background: transparent;
  color: #fff;
  outline: 0;
  font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
  color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
  color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
  color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
  color: #fff;
}

.loc-cont-part {
  position: absolute;
  width: 100%;
  left: 0;
  background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
  position: absolute;
  font-size: 0;
  right: 0;
  top: 0;
  margin: 0;
  width: 35px;
  height: 35px;
  padding: 5px;
  min-width: inherit;
  border: none;
  border-radius: 0;
  background: url(../images/search-icon.png) no-repeat scroll center center
    transparent;
}

.location-sidemenu .three-dots {
  width: 19px;
  height: 19px;
  background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px
    transparent;
  display: inline-block;
  position: absolute;
  right: 13px;
  top: 16px;
  cursor: pointer;
}

.outlet-list {
  max-height: 551px;
  overflow-y: auto;
}

.outlet-list > ul {
  padding: 20px 15px;
}

.outlet-list > ul > li {
  margin-bottom: 20px;
}

.outlet-list li {
  list-style: none;
  padding-left: 38px;
}

.outlet-list li span {
  display: block;
  font-weight: 700;
}

.outlet-list li .submenu {
  padding: 0;
}

.outlet-list li .submenu li {
  margin-top: 10px;
  list-style: none;
  padding: 0;
  line-height: normal;
  padding-left: 3px;
}

.outlet-list li .submenu li a {
  font-weight: 700;
  display: block;
  color: #272626;
  position: relative;
  padding-left: 14px;
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
}

.outlet-list li .submenu li a:hover {
  color: #ecdfcc;
}

.outlet-list .custom_checkbox {
  position: absolute;
  left: 0;
  font-size: 0;
}

.outlet-list .custom_checkbox span:before {
  width: 16px;
  height: 17px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type="checkbox"]:checked + span:before {
  background-position: -216px -189px;
}

.mapinfo_trigger {
  background: red;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.mapinfo_canvas {
  background: #fff;
  width: 100%;
  max-width: 320px;
  position: absolute;
  left: -320px;
  top: -49px;
  bottom: 0;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 9;
}

.mapinfo_canvas.open {
  left: 0;
}

.mapcanvas_ttsec {
  background: #db4337;
  padding: 15.5px 20px 15.5px 49px;
  position: relative;
}

.mapcanvas_close {
  background: url(../images/left-arrow.png) no-repeat center;
  position: absolute;
  width: 49px;
  height: 49px;
  line-height: 49px;
  font-size: calc(22px + (24 - 22) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  left: 0;
  top: 0;
}

.outlet-list li .submenu li .mapcanvas_close {
  position: absolute;
  padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
  display: none;
}

.mapcanvas_ttsec h3 {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.mapcanvas_bx {
  padding: 12px 18px;
  color: #000;
}

.mapcanvas_bx h5 {
  color: #888;
  margin: 0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.mainacc_menusec {
  margin: 0 0 40px;
}

.mainacc_menulist {
  padding: 0;
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 4px solid #e3e3e3;
}

.mainacc_menulist > li {
  width: 25%;
  position: relative;
}
.mainacc_menulist > li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -4px;
  left: 0;
  background: #561c4e;
  visibility: hidden;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.mainacc_menulist > li > a {
  background: #fff;
  color: #000;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  padding: 12px 15px 10px;
  display: block;
  position: relative;
  font-family: 'caviar_dreamsbold';
  letter-spacing: 0.1px;
  text-align: center;
}

.mainacc_menulist > li.active:before,
.mainacc_menulist > li:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.mbacc_mslidersec {
  display: none;
}

.mainacc_moblink {
  display: none;
}

.myacc_hea {
  position: relative;
  margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
  display: block;
  content: "";
  clear: both;
}

.myacc_hea h3 {
  font-size: calc(20px + (27 - 20) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 5px;
}

.myacc_hea p {
  margin-bottom: 25px;
  color: #5b5b5b;
}

.myacc_hea_inner {
  margin-top: 8px;
}

.box_in {
  background: #fafafa;
}

.account_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.accmenu_sec {
  width: 265px;
  position: relative;
}

.accprofile {
  padding: 26px 10px 0px;
}

.accprofile_img {
  width: 140px;
  height: 140px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  line-height: 134px;
  border-radius: 50%;
  margin: 0 auto 0;
  overflow: hidden;
}

.accprofile_img img {
  vertical-align: middle;
}

.accprofile_info p {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  font-weight: normal;
  margin: 0;
}

.accpoints_sec {
  padding: 15px 7px 0px;
}

.accpoint_tt span {
  background: #fff;
  padding: 0 4px;
  position: relative;
  display: inline-block;
}

.accearn_points {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px 15px 10px;
}

.accearn_pointtop {
  position: relative;
  padding-left: 52px;
}

.accearn_pointtop i {
  position: absolute;
  left: 0;
  top: 7px;
}

.accearn_pointtop h4 {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  color: #fff;
}

.accearn_pointtop span {
  font-size: calc(35px + (45 - 35) * ((100vw - 300px) / (1600 - 300)));
  display: inline-block;
  line-height: 0.8;
  color: #ffc800;
}

.accearn_pointtop sub {
  font-size: 60%;
  bottom: 0;
}

.accearn_points p {
  
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
}

.accpoint_ec {
  position: absolute;
  right: 13px;
  bottom: 8px;
}

.promotion-newdesign .accsec_right,
.rewards-newdesign .accsec_right {
  padding: 0 0 40px;
}

.accsec_right {
  width: calc(100% - 265px);
  width: -webkit-calc(100% - 265px);
  padding: 40px 30px;
  background: #f8f8f8;
  box-shadow: 0px 0px 4px 0px #ccc;
  color: #000;
  min-height: 450px;
}

.accsec_right h1 {
  font-size: calc(26px + (36 - 26) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 10px;
}

.accsec_right p {
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.acc-inform .form-group {
  margin-bottom: 11px;
}

.other-add-body {
  height: 175px;
  border: 1px solid #f1efef;
  border-radius: 7px;
  padding: 16px 14px 0px 14px;
  background: #fff;
}

.other-add-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}

.other-add-row li {
  width: 32.1875%;
  margin-right: 1.6666666666666666%;
  position: relative;
  margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n + 3) {
  margin-right: 0;
}

.other-add-row li a {
  background: #f9f9f9;
  padding: 15px 15px;
  display: block;
  border: 1px solid #f1efef;
  color: #252525;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 7px;
  cursor: default;
}

.other-add-row span {
  position: absolute;
  top: 6px;
  right: 8px;
  background: url(../images/spirite_whole.png) no-repeat;
  width: 10px;
  height: 10px;
  background-position: -35px -315px;
  cursor: pointer;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.other-add-row span:hover {
  opacity: 0.5;
}

.setting_menu_list {
  background: #282828;
}

.setting_menu_list > ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.setting_menu_list > ul li {
  position: relative;
}

.setting_menu_list > ul > li > a {
  padding: 14px 30px 14px 20px;
  color: #fff;
  display: block;
  text-transform: capitalize;
  margin-bottom: 0;
  font-family: 'caviar_dreamsbold';
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  z-index: 1;
}

.setting_menu_list > ul > li > a:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #fdd18c;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 0;
  border-radius: 0 8px 8px 0;
}

.setting_menu_list > ul > li.active > a:before,
.setting_menu_list > ul > li > a:hover:before {
  width: 105%;
}

/*.setting_menu_list > ul > li > a:hover{
  color: #000;
}*/

.setting_menu_list > ul > li > a span {
  position: relative;
  display: inline-block;
  padding-right: 40px;
}

.setting_menu_list > ul > li > a:after {
  content: "";
  position: absolute;
  top: 18px;
  right: 15px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAOCAYAAAD9lDaoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MzQzOTZCMkM1MTc0MTFFQjk4MEJCMzRBM0EwREEwM0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MzQzOTZCMkQ1MTc0MTFFQjk4MEJCMzRBM0EwREEwM0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozNDM5NkIyQTUxNzQxMUVCOTgwQkIzNEEzQTBEQTAzRiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozNDM5NkIyQjUxNzQxMUVCOTgwQkIzNEEzQTBEQTAzRiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pnx5VmgAAADfSURBVHjabJGxDsFQFIarhg4GSU0Si9XIQ3gFEy9g72A3GCwmYeEBJB6AkcQkzCYxsJGIpLdpfUdORas3+XqT/3y5595Ty/f9BqxgGIahE0WRlcaiuIYbnIMg6BDaWVILNhDB0xjjIScl+RBWEXYqyuoj2wlJxbK2FjGAEaKTkFR0KS5VFGZkxb+XEBYoTrWtiPPc57jUok2FbQ8leNhpQe7GNgZXo0m6lbxyq20MDMjyX4EWNcKDCi/oxfOKT6gTnlS4Q/d3oDJxD44qXDmx/TdxCgu4iEixmfWD3wIMAG9Xcaq0XRLpAAAAAElFTkSuQmCC)
    no-repeat;
  background-size: contain;
  width: 9px;
  height: 14px;
  visibility: hidden;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  opacity: 0;
}

.setting_menu_list > ul > li.active > a:after,
.setting_menu_list > ul > li > a:hover:after,
.setting_menu_list > ul li:hover .myacc_dropmenu {
  visibility: visible;
  opacity: 1;
}

.setting_menu_list > ul li:hover .myacc_dropmenu {
  opacity: 1;
  visibility: visible;
}
.myacc_dropmenu {
  position: absolute;
  left: 105%;
  top: -20px;
  list-style: none;
  padding-left: 0;
  background: rgba(0, 0, 0, 0.77);
  padding: 30px 35px;
  z-index: 1;
  width: 284px;
  border-radius: 7px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
}

.myacc_dropmenu::after {
  position: absolute;
  content: "";
  border-right: 14px solid #3b3b3b;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -7px;
}

.myacc_dropmenu::before {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  background: #ccc;
  width: 20px;
  height: 100%;
  opacity: 0;
}

.myacc_dropmenu li a {
  color: #fff;
}

.myacc_dropmenu .tg-list {
  position: absolute;
  top: 0;
  right: -12px;
  padding-top: 0;
}

.promotion-newdesign .main_tabsec_inner,
.rewards-newdesign .main_tabsec_inner {
  padding: 30px 30px 0;
}

.main_tabsec_inner > .nav-tabs > li {
  width: 33.17%;
  margin-right: 0.24154589371980675%;
  position: relative;
}

.main_tabsec_inner > .nav-tabs > li:last-child {
  margin-right: 0;
}

.main_tabsec_inner > .nav-tabs {
  text-align: center;
}

.main_tabsec_inner > .nav-tabs > li > a {
  background: #bfbebe;
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #fff;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  text-transform: uppercase;
  padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner > .nav-tabs > li.active > a {
  border: 0;
  background: #0b0405;
  color: #fff;
}

.main_tabsec_inner > .nav-tabs > li > a > span > b {
  font-weight: normal;
}

.count_num_t {
  min-width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #000;
  margin-left: 5px;
  font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
  line-height: 21px;
  vertical-align: top;
  margin-top: 1px;
  padding: 0 4px;
  background: #fff;
}

.btn.spirite_image {
  height: 56px;
}

.spirite_image {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.spirite_image i {
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}

.filter_tabsec .nav-tabs {
  width: 100%;
  padding: 0;
  border-bottom: 0;
}

.filter_tabsec .nav-tabs > li {
  width: 50%;
  float: left;
  border-bottom: 5px solid #f0f0f0;
  position: relative;
}

.filter_tabsec .nav-tabs > li > a span {
  display: inline;
}

.filter_tabsec .nav-tabs > li > a:after {
  height: 5px;
  bottom: -5px;
  background: #561c4e;
  max-width: 253px;
  margin: auto;
  width: 100%;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.ord-body::after {
  display: block;
  clear: both;
  content: "";
}

.ord-pagination-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0 0 10px 0;
}

.ord-pagination-col::after {
  display: block;
  content: "";
  clear: both;
}

.ord-pagination-col span {
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-weight: bold;
}

.ord-pagination-col p {
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: bold;
}

.ord-pagination-col .form-group {
  margin-bottom: 0;
  padding-right: 5px;
}

.ord-pagination-col .form-group select {
  background: #d7d4d8;
  border: 0;
  font-weight: bold;
}

.cur-order-body {
  width: 100%;
  clear: both;
}

.cur-order-body::after {
  display: block;
  clear: both;
  content: "";
}

.cur-order {
  padding: 10px 0;
  border-bottom: 1px solid #efefee;
  position: relative;
}

.click_view {
  position: absolute;
  right: 21px;
  color: #474747;
  padding: 0;
  font-weight: 700;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.cur-order-del {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 450px;
  list-style: none;
  padding-left: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.cur-order-del li {
  font-weight: 700;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  padding-left: 20px;
}

.cur-order-del li a.btn {
  min-width: 132px;
  height: 35px;
  border-radius: 10px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  padding: 8px 5px;
  color: #0b0405;
  line-height: normal;
  margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
  color: #fff;
}

.cur-order-del li span {
  padding-left: 5px;
}

.cur-order-del-icon {
  background: url(../images/spirite_whole.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.calender-icon {
  background-position: -215px -258px;
}

.time-icon {
  background-position: -249px -257px;
}

.cur-ord-rec {
  list-style: none;
  float: right;
  max-width: 400px;
  padding-right: 19px;
  margin-bottom: 0;
  padding: 8px 19px 0 0;
}

.cur-ord-rec li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cur-ord-rec li a {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  text-decoration: underline;
  color: #2e2e2c;
}

.cur-ord-rec li a:hover {
  color: #909090;
}

.cur-ord-rec li span {
  display: inline-block;
  width: 1px;
  height: 100%;
  background: #2e2e2c;
  margin: 0 20px;
  position: relative;
}

.cur-ord-rec li span::after {
  content: "";
  background: #2e2e2c;
  height: 14px;
  width: 1px;
  display: block;
  position: absolute;
  top: 2px;
}

.order-cat {
}

.order-cat-hea {
  background: #fff;
  padding: 8px 35px;
  max-width: 331px;
  margin-bottom: 10px;
}

.order-cat h2 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #0b0405;
  margin-bottom: 0;
}

.order-cat-table {
  padding: 0 35px;
}

.order-cat-table h4 {
  color: #474747;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  margin-bottom: 11px;
}

.order-cat-table table {
  border: 0;
  max-width: 364px;
  margin-bottom: 10px;
}

.order-cat-table table td {
  font-weight: 300;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #3d3d3d;
  padding: 0 5px;
}

.order-cat-table table td.p_name {
  padding-left: 0;
}

.p_name {
  width: 247px;
}

.p_val {
  width: 80px;
}

.ord-amount {
  padding: 10px 0 0 35px;
  border-top: 1px solid #efefee;
}

.ord-amount .row {
  margin-right: -20px;
}

.ord-amount-item {
  max-width: 364px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ord-amount h2 {
  font-size: calc(18px + (27 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #202020;
}

.ord-rec-sec {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: right;
  float: right;
  width: 649px;
}

.ord-rec-sec li {
  color: #aba9a9;
  margin-bottom: 0;
  height: 39px;
  padding: 10px 15px;
  max-width: 184px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  width: 25%;
  line-height: 23px;
  overflow: hidden;
}

.ord-rec-sec li.completed {
  background: #ecdfcc;
  color: #3d3d3d;
}

.ord-number {
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  font-weight: 700;
  margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
  color: #fff;
  background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
  padding: 6px 72px 50px 72px;
}

.congrats::after {
  display: block;
  content: "";
  clear: both;
}

.congrats-inner img {
  margin-bottom: 8px;
}

.congrats-inner, .promo-form {
  margin: 0 auto;
  text-align: center;
  padding: 28px 15px;
  background: #fdd18c;
}

.congrats-inner h3 {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  margin-bottom: 5px;
}

.congrats-inner h2 {
  font-size: calc(26px + (42 - 26) * ((100vw - 300px) / (1600 - 300)));
  color: #561c4e;
  margin-bottom: 0;
}

.rewards-tab-section .ord-amount {
  border-top: inherit;
  padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
  margin-bottom: 7px;
}

.earned_points {
  max-width: 300px;
  padding-left: 0;
  list-style: none;
  float: right;
  padding-right: 24px;
}

.earned_points li span {
  color: #bfbebe;
  text-transform: uppercase;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  display: inline-block;
  margin-right: 5px;
}

.earned_points li h2 {
  color: #b69a6b;
  font-size: calc(17px + (24 - 17) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  margin-bottom: 8px;
}

.redeem_popsec {
  max-width: 600px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
  margin: 0px auto;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
  width: 25%;
  height: 270px;
}

.redeem_popsec .promo-earned-content {
  padding: 30px 20px 30px;
  text-align: center;
}

.redeem_popsec .promo-earned-content h4 {
  font-size: calc(17px + (24 - 17) * ((100vw - 300px) / (1600 - 300)));
  margin: 0px 0px 10px 0px;
}

.redeem_popsec .promo-earned-content h2 {
  font-size: calc(26px + (42 - 26) * ((100vw - 300px) / (1600 - 300)));
  color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
  margin: 7px 0 0;
}

.redeem_popsec .abc_code {
  padding: 20px 0 0px;
}

.redeem_popsec .promo-earned-content h2 span sup {
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.redeem_pop_image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.redeem_popsec .pouup_in {
  padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  line-height: 24px;
}

.redeem_popsec .btn {
  background: #561c4e;
  color: #fff;
  display: block;
  border-radius: 6px;
  margin: 0 auto 0px;
  text-align: center;
}

.redeem_popsec .btn:hover {
  background: #200200;
  color: #fff;
}

.redeem_popsec .mfp-close {
  background: #d9d7d7;
  border-radius: 5px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  top: 10px !important;
  right: 10px !important;
}

.redeem_popsec .mfp-close:after {
  background: url(../images/cross-close.png) no-repeat;
  background-size: contain;
  background-position: center;
}

.redeem_popsec .mfp-close:hover {
  background: #b5b5b5;
}

.input_area {
  position: relative;
}

.input_area {
  position: relative;
}

.cal_icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 100%;
  width: 45px;
  pointer-events: none;
}

.cal_icon b {
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro {
  display: block;
  margin: 0 auto;
  background: #3d5a98;
  text-transform: inherit;
  border-radius: 5px;
  width: 100%;
  padding: 11px 15px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  font-weight: normal;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pop-whole .kep-login-facebook.metro i {
  font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
  /*border-right: 2px solid #3c558e;*/
  padding-right: 10px;
  margin-left: 30px;
}

.pop-whole .kep-login-facebook.metro:hover {
  background: #000000;
}
/*.pop-whole .kep-login-facebook.metro:hover i {
  border-right: 2px solid #252525;
}*/

.combo-input-label {
  height: 100%;
  border: none;
  color: #000;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  margin: 0;
  padding: 2px;
  background: #fff;
  border-radius: 0px;
  border-right: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
  line-height: 21px;
}

.compo_acc_innerdiv {
  padding: 10px 20px 0px 20px;
}

.gloading_img {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9;
}

.gloading_img:after {
  content: "";
  width: 35px;
  height: 35px;
  border: 4px solid rgba(30, 29, 29, 0.49);
  border-top-color: #1e1d1d;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 1s linear infinite;
  animation: loader-rotate 1s linear infinite;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.load_warpper {
  position: relative;
}

.load_warpper #dvLoading {
  position: absolute;
}

#dvLoading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  margin: 0;
  background: #fff url(../images/loading.gif) no-repeat center;
  background-size: 60px;
  -webkit-background-size: 60px;
  bottom: 0;
  right: 0;
}

#inner_loading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif)
    no-repeat center;
  bottom: 0;
  right: 0;
}

.popup_close {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #fff;
  opacity: 1;
  padding: 10px;
  border-radius: 5px;
  width: 40px;
  z-index: 1;
}

.back_arrow {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 43px;
  height: 49px;
  text-align: center;
  line-height: 49px;
  cursor: pointer;
  z-index: 4;
}

.back_arrow:after {
  content: "";
  background: url(../images/spirite_whole.png) no-repeat;
  width: 26px;
  height: 26px;
  background-position: -160px -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.react-datepicker-popper {
  z-index: 11 !important;
}

.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 50px;
  right: 15px;
  background: #523429;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  cursor: pointer;
  z-index: 111;
}

.scrollup:hover {
  background: #000;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
  margin: 0px 0 30px;
}

.cms-page .container {
  padding-top: 40px;
  padding-bottom: 30px;
}

.cms-page h3 {
  font-size: calc(20px + (27 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: #040507;
}

.abt-cms-lhs {
  width: 53%;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.abt-cms-rhs {
  width: 42%;
}
.abt-cms-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.abt-cms-lhs h2 {
  /*color: #6e1d24;*/
  text-transform: inherit;
  font-size: calc(26px + (47 - 26) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 10px;
}

.contact_left p a {
  color: inherit;
}

.contact_left p a:hover {
  color: #b69a6b;
}

.contact_left p.open-hours {
  border-top: 1px solid #eaeaea;
  padding-top: 25px;
  margin-top: 26px;
}

.contactus_pg .contact_form h3 {
  margin: 0 0 5px;
}

.contact_form .help-block {
  margin: 0 0 24px;
}

.product_tag {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 5px 0 10px;
}

.product_tag li {
  padding-right: 10px;
}

.product_tag li b {
  margin-right: 10px;
}

.product_tag li a {
  display: inline-block;
  color: #5b5b5b;
  font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
  position: fixed;
  top: 96px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  margin: auto;
  text-align: center;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  max-width: 1260px;
  padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
  top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
  right: 0;
}

.chosen_file {
  position: relative;
  display: inline-block;
}

.chosen_file input[type="file"] {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.chosen_file label {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 11.5px 15px;
  outline: 0;
  background: #ecdfcc;
  max-width: 160px;
  border: 1px solid #e6e5e5;
  text-overflow: ellipsis;
  margin: 0;
  pointer-events: none;
}

.catering .ttsplitter span {
  background: #fff;
}

.catering .ttsplitter {
  margin: 0 auto 20px;
}

.container_720 {
  margin: 0 auto;
  max-width: 720px;
}

.date_list {
  margin-bottom: 30px;
  position: relative;
  padding: 40px 120px 30px;
  border: 2px solid #dddddd;
  background: #fff;
  border-radius: 6px;
}

.date_list:after {
  content: "";
  display: table;
  clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
  text-align: center;
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 20px 0;
  font-family: 'caviar_dreamsbold';
}

.ordering-more {
  text-align: center;
}

.ordering-more .button.no {
  background: #000;
}

.ordering-more .button.yes {
  background: #561c4e;
}
.more-time-slots {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 476px;
  margin: 0 auto;
}
.more-time-slots a {
  width: 49%;
  margin: 0;
  padding: 17px 5px 16px;
  border-radius: 5px;
  font-size: calc(17px + (19 - 17) * ((100vw - 300px) / (1600 - 300)));
}

#nobtn_id {
  margin-right: 5px;
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
  background: #333;
}

.ordering-more h3 {
  color: #3d3d3d;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin: 0 0 15px 0;
  font-family: 'caviar_dreamsregular';
}

.date_list h4 {
  color: #3d3d3d;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin: 0 0 15px 0;
  font-family: 'caviar_dreamsregular';
}

.venue-details-inner h3 {
  display: block;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #3c3a3a;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-left: 5px;
}

.show {
  display: block;
}

.no-padding {
  padding: 0px;
}
.errorInfo-div {
  display: none;
  background: #ef1616;
  padding: 5px;
  color: #fff;
}

.making-catering {
  width: 36%;
  float: left;
  padding: 5px 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.17);
}

.making-catering p {
  margin: 0;
}

.making-place {
  font-weight: bold;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.making-place.making-place-padding {
  display: block;
}

.change-location a {
  text-decoration: underline;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
}

.change-location a:hover {
  /*color: #f09509;*/
}

.event-time {
  width: 33%;
  padding: 5px 0px 5px 20px;
}

.event-time .title4 {
  margin-bottom: 3px;
}

.event-time .row {
  margin: 0 -4px;
}

.event-time [class*="col-"] {
  padding: 0 4px;
}

.event-time .form-group {
  margin: 0;
}

.event-time .form-control {
  height: 57px;
}

.order-select-brktime ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.order-select-brktime ul .time-cls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
  margin: 0;
  position: relative;
  border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
  width: calc(100% - 200px);
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  color: #020202;
  text-transform: uppercase;
}

.viewmenu-top-div {
  margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
  padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
  font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1600 - 300)));
  top: 5px;
}
ul.outletul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.outletul > li {
  width: 49%;
  margin: 0 2% 30px 0;
  background: #fbaa19;
  position: relative;
}

.outletul > li:nth-child(even) {
  margin-right: 0;
}

.ourrest_img {
  width: 100%;
}

.ourrest_img img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.ourrest_info {
  width: 100%;
  padding: 30px 20px 70px 20px;
  color: #fff;
}

.ourrest_info h4 {
  margin: 0 0 15px;
  color: #fff;
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.ourrest_info p {
  margin: 0 0 8px;
}

.ourrest_infolinks {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ourrest_infolinks li {
  display: block;
  width: 50%;
}
.phone-show-mbl {
  display: none;
}

.phone-show-mbl a {
  color: #fff;
}
.ourrest_infolinks a {
  padding: 15px 5px;
  display: block;
  color: #fff;
  transition: all 400ms;
  height: 100%;
  background: #fdd18c;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  letter-spacing: 1px;
}
.ourrest_infolinks a i {
  display: none;
}
ul li .cat-product-info {
  background: rgba(0, 0, 0, 0.6);
  padding: 45px 15px 45px;
  color: #fff;
  line-height: 1.4;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.35s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
ul li .cat-product-info h4 {
  color: #ffffff;
  margin: 0 0 5px 0;
}
.cat-product-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.cat-product-button a {
  margin: 0;
  display: block;
  height: 45px;
  padding: 15px 10px;
}
.btn_download {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  padding: 8px 20px 7px;
  height: 38px;
  border: 0;
  border-radius: 30px;
  background: #561c4e;
  -webkit-box-shadow: 0 2px 0px 0px #0b0405;
  box-shadow: 0 2px 0px 0px #0b0405;
  margin: 6px 0px 12px;
}

.btn_download:hover {
  background: #ecdfcc;
  color: #0b0405;
  -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
  box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cross_multi {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #5d5d5d;
  margin: 0 0px 5px 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 6px 12px;
  z-index: 1;
  background: #ffffff;
  font-family: 'caviar_dreamsbold';
  letter-spacing: 1px;
  border: 1px solid #d6d6d6;
  line-height: 1;
  border-radius: 4px;
  text-align: center;
}

.checkout_time {
  padding: 0px 0px 20px;
}

.checkout_time label {
  color: #000000;
  line-height: 40px;
  padding: 4px 0px;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-weight: bold;
}

.total_amt {
  background: #1c1c1c;
  padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  font-weight: bold;
}

.total_amt .total {
  font-size: calc(18px + (25 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.total_amt .rt {
  text-align: right;
}

.brline {
  background: #f5f5f5;
  height: 2px;
  margin-left: 40px;
}

.download-quote {
  padding: 20px 0px 0px;
  text-align: center;
}

.download-quote .btn {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  padding: 7px 20px;
  border: 0;
  background: #561c4e;
}

.catering_thak_you .tnk-header {
  text-align: unset;
}

.catering_thak_you .tnk-detail {
  text-align: center;
}

.catering_thak_you .tnk-header .tick {
  text-align: center;
}

.catering_thak_you .order_details .qtybxs {
  font-weight: 700;
  color: #000000;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  line-height: 38px;
  text-align: right;
}

.catering_thak_you .package .package-list {
  padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
  border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
  margin-left: 0px;
}

.promotion_popup {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  max-width: 650px;
}

.promo_poptt {
  margin: 0 0 30px;
}

.promo_poptt .title1 {
  margin-bottom: 8px;
}

.popoff_bar {
  padding: 10px 0 15px;
  border: 1px solid #ddd;
  border-right: 0;
  border-left: 0;
  margin: 0 0 35px;
}

.popoff_bar span {
  display: block;
  font-size: calc(24px + (66 - 24) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  color: #000;
}

.popoff_bar b {
  display: block;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-weight: 400;
}

.popwel_formsec {
}

.popwel_formsec h4 {
  margin: 0 0 10px;
}

.popwel_inputbx {
  position: relative;
}

.popwel_inputbx .form-control {
  height: 51px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.popwel_inputbx .btn {
  position: absolute;
  right: 0;
  top: 0;
  border-color: #ecdfcc;
}

.rating-option {
  position: relative;
  display: inline-block;
}

.rating-option input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

.rating-option span {
  padding: 5px 0 0 40px;
}

.rating-option span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #aba8a3;
  left: 0;
  top: 3px;
  pointer-events: none;
  line-height: 21px;
  border-radius: 50%;
}

.rating-option input[type="radio"]:checked + span:before {
  content: "\f00c";
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: 26px;
  color: #fff;
  text-align: center;
  text-align: center;
  background: #72624f;
  border-color: #ecdfcc;
}

.contact_left p {
  margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

#pay-conf-popup .process_inner {
  text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
  display: inherit;
  width: auto;
}

.loader-main-cls {
  position: relative;
  pointer-events: none;
}

.loader-sub-div {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 98;
}

.loader-sub-div:after {
  width: 35px;
  height: 35px;
  border: 4px solid rgba(30, 29, 29, 0.49);
  border-top-color: #1e1d1d;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 0.3s linear infinite;
  animation: loader-rotate 0.3s linear infinite;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header_middle .container {
  position: relative;
}

.htico_search > a,
.htico_search_mbl > a {
  position: relative;
  display: block;
}

.header_bot {
  display: inline-block;
}

.home-banner-full {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.home-banner .slick-slide img {
  width: 100%;
}

.header-top {
  background: #000;
  text-align: center;
  clear: both;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-top p {
  color: #fff;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0px;
}
.header-top-cls {
  padding: 7px 0;
  position: relative;
}

.logo img {
  height: 70px;
  transition: 0.3s linear all;
}

.logo-main-section {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  align-items: center;
}
.notice-board {
  position: relative;
  padding: 10px 0;
  background: #f4f4f4;
  text-align: center;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.notice-board p {
  margin: 0;
  font-family: 'caviar_dreamsregular';
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
}
.nb-container span {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 10px 50px 10px 30px;
  background: #e2e2e2;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-family: 'caviar_dreamsbold';
  color: #4f1a0c;
}
.nb-container span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  border-bottom: 50px solid #f4f4f4;
  border-left: 25px solid transparent;
  width: 0;
  height: 0;
}
.menu_icon.trigger_menunav_act {
  display: none;
}
.mbl_search_liblk {
  display: none;
}

.slick-slide {
  position: relative;
}

.wppc-checked {
  color: red;
}

.wppc-rating-full {
  background-image: url("../images/star-bg.png");
  background-repeat: no-repeat;
  padding: 5px 10px;
  margin-top: 0px;
  margin-left: 44px;
}

.footer-bottom {
  padding-top: 30px;
  padding-bottom: 15px;
}

.footer-bottom .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-contact a,
.footer-bottom-right h3 {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  text-decoration: none;
  color: #000;
}

.footer-bottom-middle ul,
.footer-bottom-right ul {
  list-style: none;
  padding: 0;
}

.footer-bottom-middle ul li a {
  color: #000;
  text-decoration: none;
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  text-transform: uppercase;
  font-family: 'caviar_dreamsregular';
  position: relative;
  padding: 1px 0;
}
.footer-bottom-middle ul li a::after,
.footer-bottom-middle ul li a::before {
  position: absolute;
  content: "";
  opacity: 1;
  width: 0;
  height: 1px;
  background: #561c4e;
  -webkit-transition: width 0.4s 0.1s;
  transition: width 0.4s 0.1s;
}
.footer-bottom-middle ul li a::before {
  top: 0;
  right: 0;
}
.footer-bottom-middle ul li a::after {
  top: 94%;
  left: 0;
}
.footer-bottom-middle ul li a:hover::after,
.footer-bottom-middle ul li a:hover::before {
  width: 100%;
}
.footer-bottom-middle ul li a:hover {
  /*color: #f09509;*/
}

.footer-bottom-middle ul li {
  display: inline-block;
  margin: 0 7px;
}

.footer-bottom-right ul li a {
  color: #000;
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
}
.footer-bottom-right ul li a:hover {
  color: #561c4e;
}

.footer-nav-info .f-nav ul li h5{  color: #f5ce6d; }

.copyright-section {
  background: #1a1a1a;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  position: relative;
}

.copyright-section p {
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
  text-transform: capitalize;
  width: 100%;
}

.copyright-section ul{
  padding: 0;
  display: flex;
  position: absolute;
  top: 17px;
  right: 0;
}

.copyright-section ul li{
  list-style: none;
  padding: 0 10px;
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
}

.copyright-section ul li:last-child{
  margin-right: 20px;
}

.copyright-section ul li a img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all .2s cubic-bezier(.63,.05,.43,1.7);
  transition: all .2s cubic-bezier(.63,.05,.43,1.7);
}

.slick-list, .slick-slider {
  position: relative;
  display: block;
}

.testimonials-title-text {
  text-align: center;
  padding-bottom: 30px;
}

.footer-nav-info .container-full {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.footer-main .container-full .f-socail .f-socail-btm ul li a img:hover {
  -webkit-transition: all 0.2s cubic-bezier(0.63, 0.05, 0.43, 1.7);
  transition: all 0.2s cubic-bezier(0.63, 0.05, 0.43, 1.7);
}

.footer-main .container-full .f-socail .f-socail-btm ul li a img {
  max-height: 26px;
  width: auto;
}

.f-nav {  width: 920px;}
.f-nav > ul { display: flex; width: 100%; padding: 0; }

.f-address h5 { margin-bottom: 15px;}
.f-nav > ul > li { list-style: none; padding-right: 60px; width: 33.3333%;}
.f-nav > ul > li:last-child { padding-right: 20px;}
.f-nav > ul > li ul,
.f-socail-btm ul { list-style: none; padding: 0; }
.f-nav > ul > li ul li a { color: #000; }
.f-nav > ul > li ul li a:hover, .f-socail-btm ul li a:hover, .copyright-section ul li a:hover { color: #fdd18c; }
.f-nav > ul > li ul li { margin-bottom: 5px; }
.f-address { width: 25%; }
.f-address p { width: 80%; }

.f-nav > ul > li h5,
.f-socail h5 { margin-bottom: 15px; font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300))); }

.footer-top-info ul li { max-width: 210px; width: 100%; }
.footer-top-info ul li a { color: #000; }
.footer-top-info ul li a:hover { /*color: #f09509;*/ }

.f-socail { display: flex; flex-flow: column wrap; width: 350px; }
.f-socail-btm ul li a { color: #282828; }
.f-socail-btm li { display: inline-block; margin: 0 15px 0 0; }
.footer-nav-info { padding: 0px 0 50px 0; position: relative; }

.home-options-tab {
  padding: 30px 0 30px 0;
}

.home-options-tab ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
  border-bottom: 4px solid #e3e3e3;
}

.home-options-tab ul li {
  width: 33.3333%;
  color: #bebebe;
  font-family: 'caviar_dreamsbold';
  text-transform: capitalize;
  font-size: calc(17px + (19 - 17) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  padding-bottom: 7px;
  cursor: pointer;
  line-height: 1.2;
}
.home-options-tab ul li:hover,
.home-options-tab ul li.active {
  color: #000;
}
.home-options-tab ul li:hover:before,
.home-options-tab ul li.active:before {
  visibility: visible;
  transform: scaleX(1);
}
.home-options-tab ul li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -4px;
  left: 0;
  background: #561c4e;
  visibility: hidden;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.choose-option-hover {
  position: absolute;
  width: 360px;
  top: 60px;
  left: 100%;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.19);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 9;
}
.choose-option-hover ul {
  background: #fff;
  border: 3px solid #fbaa19;
  padding: 0;
  list-style: none;
  padding: 10px;
  border-radius: 0 0 5px 5px;
}
.choose-option-hover ul li {
  margin: 7px 0;
}
.choose-option-hover ul li a {
  font-family: 'caviar_dreamsbold';
  color: #000;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.choose-option-hover ul li a:hover {
  /*color: #f09509;*/
}
.home-options-inner > ul > li:hover .choose-option-hover {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  z-index: 9;
}
.home-options-inner > ul > li:nth-child(3n) .choose-option-hover {
  left: inherit;
  right: 100%;
}

.home-banner {
  position: relative;
  z-index: 2;
  box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.5);
}

/*.home-banner .slick-arrow {
  display: none !important;
}*/

.home-banner .slick-prev, .home-banner .slick-next, .press-slider-top .slick-prev, .press-slider-top .slick-next{ 
  width: 30px; height: 45px; font-size: 0; background: #f5ce6da1;
  border: 2px solid #f5ce6d;    top: 50%; left: 0px;  margin: 0; padding: 0; 
  margin-top: -22px; position: absolute; border-radius: 0; }

.home-banner .slick-next{ right: 0px; left: inherit }


.home-banner button.slick-arrow.slick-prev:after, .home-banner button.slick-arrow.slick-next:after,
.press-slider-top button.slick-arrow.slick-prev:after, .press-slider-top button.slick-arrow.slick-next:after {
    content: "\f104";
    font-family: 'FontAwesome';
    font-size: 26px;
    font-weight: normal;
}
.home-banner button.slick-arrow.slick-next:after,
.press-slider-top button.slick-arrow.slick-next:after, {
    content: "\f105";
}

.home-banner .slick-next{
    right: 0;
    left: inherit;
}

.press-slider-top .slick-next{ right: 10px; left: inherit; }

.home-banner button.slick-arrow.slick-next:after, .press-slider-top button.slick-arrow.slick-next:after{
  content: "\f105";
}

.container-full {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.imp-button {
  display: flex;
}
.imp-button a {
  width: 50%;
  padding: 5px 5px;
  position: relative;
  text-align: center;
  font-family: 'caviar_dreamsbold';
  display: flex;
  justify-content: center;
  align-items: center;
}
.imp-button a img {
  margin-right: 5px;
}
.imp-button a:first-child {
  color: #fff;
  background: #561c4e;
  border-top-left-radius: 5px;
}
.imp-button a:last-child {
  color: #979797;
  background: #292929;
  border-top-right-radius: 5px;
}

.slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
  display: none !important;
}

.slick-track .slick-slide {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: inherit;
}

.slick-track .slick-slide .featured-products-single {
  height: 100%;
  background-color: #fff;
}

.addcart_row .qty_bx {
  float: left;
}

.addcart_row .btn {
  margin-bottom: 0;
  margin-top: 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.35px;
  padding: 14px 15px 14px;
  min-width: 85px;
  background: #fdd18c;
  color: #00989a;
}

.addcart_row .btn:hover, .addcart_row .btn:focus {
  color: #000;
  background: #561c4e;
}

.product-menu-listing .addcart_row .btn {
  min-width: 85px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 14px 15px 14px;
  background: #561c4e;
  color: #fff;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  letter-spacing: 2px;
}

.product-menu-listing .addcart_row .btn:hover,
.product-menu-listing .addcart_row .btn:focus {
  background: #a3a3a3;
  color: #fff;
}

.innerproduct-item::after {
  content: "";
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: absolute;
  z-index: 1;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  opacity: 0;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
  opacity: 1;
}

.innerproduct-item-text h4 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  color: #0a0b10;
  padding: 10px 0 8px 0;
  margin-bottom: 0;
}

.bakery-review .fa-star {
  color: #000;
}

.bakery-review .wppc-checked {
  color: #ed8a19;
}

.innerproduct-item-text p {
  line-height: 20px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #949292;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  margin-top: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.innerproduct-item-text h5 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  color: #000;
  margin-bottom: 0;
}

.innerproduct-item-text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.menu-section_full {
  overflow: hidden;
}

.login-popup,
.signup-popup,
.forgot-password-popup {
  max-width: 980px;
  margin: 20px auto;
  width: 100%;
  background: #fff;
  position: relative;
  color: #222222;
  /*border: 1px solid #fbeed9;*/
}
.full-login-new {
  background: #f6f6f6;
  position: relative;
}
.full-login-lhs {
  background: #fbeed9;
  padding: 30px 30px;
  text-align: center;
  width: 320px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.full-login-lhs img {
  margin-bottom: 20px;
}
.full-login-lhs .button {
  margin: 5px 0;
  min-width: 220px;
  background: #fff;
  color: #000;
  padding: 20px 10px;
  border: 2px solid #561c4e;
  border-radius: 0;
  letter-spacing: 1px;
}

.full-login-lhs .button:last-child{
  margin-bottom: 20px;
}
.full-login-rhs-header {
  padding: 0 0 25px 0;
}
.full-login-rhs-header h3 {

  font-family: 'caviar_dreamsbold';
  font-size: calc(22px + (32 - 22) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 5px;
  line-height: 1;
  color: #222222;
}

.full-login-rhs-header p {
  margin-bottom: 0;
  /*font-size: 16px;*/

  font-family: 'caviar_dreamsbold';
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.full-login-rhs {
  margin: 0 auto;
  padding: 60px 80px;
  text-align: center;
  width: calc(100% - 320px);
}
.full-login-rhs h4 {
  display: none;
}
.full-login-rhs .custom_checkbox_content {
  text-align: left;
  display: block;
}
.custom_checkbox_content span {
  display: block;
}
.login_pop_sub button,
.login_pop_sub .signup_submit {
  width: 166px;
  background: #561c4e;
  color: #fff;
  padding: 18px 10px 16px;
  margin-bottom: 0px;
  border-radius: 10px;
}

.billing-addrs-sbmt .btn_minwid:hover{
  background: #fdd18c;
  color: #000;
}

.checkout-heading {
  background: #fdd18c;
  color: #000;
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
  padding: 12px 10px;
  position: relative;
  font-family: 'caviar_dreamsbold';
}
.controls.two-links a {
  font-family: 'caviar_dreamsbold';
  color: #000;
  text-transform: uppercase;
  font-size: calc(9px + (15 - 9) * ((100vw - 300px) / (1600 - 300)));
  display: block;
}

.checkout-total .checkout-control-group-top a:hover {
  color: #fdd18c;
}

.full-login-rhs .open-popup-link:hover {
  color: #561c4e;
}

.login-popup .controls.two-links{
  margin-top: 15px;
}

.forgot-password-popup .open-popup-link{
  margin-top: 5px;
}

.controls.two-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.controls.two-links a:hover {
  /*color: #f09509;*/
}

.full-login-new-body .sign_reg {
  display: inline-block;
  vertical-align: middle;
}
.full-login-new-body .sign_reg p {
  margin-bottom: 0;
}
.full-login-new-body .sign_reg a, .full-login-new-body .pdpa_trmcont_link a {
  color: #000;
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.full-login-new-body .sign_reg a.sign_reg_uline:hover,
.full-login-new-body .pdpa_trmcont_link a:hover{
  color: #fdd18c;
}

.full-login-new-body .custom_checkbox span {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.full-login-new-body .custom_checkbox span:before {
  border-radius: 0;
  width: 16px;
  height: 16px;
}
.full-login-new-body  .custom_checkbox  input[type="checkbox"]:checked  + span:before {
  background: url(../images/tick-16.png) no-repeat;
  border: 0;
}

.login_pop_sub .login_submit:hover{
  background: #dfc288;
  color: #000;
}

.full-login-rhs .open-popup-link {
  text-transform: uppercase;
  color: #000;
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.full-login-lhs .button:hover,
.login_pop_sub .signup_submit:hover,
.login_pop_sub .login_submit:hover,
.login_pop_sub .forgotpassword-cls:hover,
.login_in_guest .guestcheckout_submit:hover {
  background: #561c4e;
  color: #fff;
}

.full-login-rhs .login_pop_sub button:hover{
  background: #dfc288;
  color: #000;
}

.custom_checkbox:hover .sign_reg .sign_reg_uline {
  color: #4f1a0c;
}

.pdpa_trmcont_link a:hover,
.sign_reg_uline:hover {
  color: #fdd18c;
}

.or-seperator {
  margin: 20px 0 15px;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  color: #868686;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  z-index: 1;
}

.or-seperator:before {
  height: 2px;
  background: #e1e1e1;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.or-seperator span {
  background: #ffffff;
  padding: 0 10px;
  color: #868686;
}
.loginBtn {
  position: relative;
  margin: 0 0 5px 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 13px 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-transform: inherit;
}
.loginBtn:before {
  content: "";
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn--google {
  background: #cf4332;
}
.loginBtn--google:before {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")  0 no-repeat;
}
.loginBtn--google:hover, .loginBtn--google:focus {
  background: #000;
}
header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  margin: auto;
  background: #00989a;
}

.logo a {
  position: relative;
}

.header-bottom .header-bottom-innersec {
  position: relative;
}

ul.hmenu_list > li > a {
  font-family: 'caviar_dreamsbold';
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  text-transform: uppercase;
  padding: 20px 6px 14px;
  display: block;
  transition: 0.3s linear all;
  position: relative;
  margin: 0 1px;
  line-height: 1;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  overflow: hidden;
}

ul.hmenu_list > li > a.active:before {
    display: block;
}

ul.hmenu_list > li > a:hover:before {
    display: block;
}

ul.hmenu_list > li > a:before {
  content: "";
  position: absolute;
  left: 1px;
  bottom: 2px;
  width: 6px;
  height: 6px;
  background-color: #fdd18c;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: none;
}

ul.hmenu_list > li > a.active:after {
  width: 100%;
  right: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}

ul.hmenu_list > li > a:hover,
ul.hmenu_list > li > a.active {
  color: #fdd18c;
}

ul.hmenu_list > li > a:hover:after {
  width: 100%;
  right: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}

ul.hmenu_list > li > a:after {
  content: "";
  position: absolute;
  left: 11px;
  bottom: 3px;
  width: 0;
  height: 2px;
  background-color: #fdd18c;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

.hsign_sec > span {
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
  color: #000;
  margin: 0 5px;
}

.haction_sec {
  text-align: center;
  position: relative;
}

.hmenu_actions {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.htico_search > a > img {
  width: 25px;
  height: 25px;
  display: block;
}

.htico_cart > a img {
  width: 23px;
  height: 23px;
  display: block;
}

.header_in {
  padding-top: 45px;
  position: relative;
}

.header_in::before,
.header_in::after {
}

.mobile-login ul,
.desktop-login ul {
  padding: 0;
  list-style: none;
  position: absolute;
  top: 120%;
  left: 0;
  background: #561c4e;
  border-radius: 6px;
  min-width: 150px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  z-index: 5;
  display: block;
  border: 2px solid #4f1a0c;
}
.desktop-login ul {
  right: 0;
  left: inherit;
}
.mobile-login ul.active,
.desktop-login ul.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.desktop-login {
  position: relative;
}

.mobile-login ul li a,
.desktop-login ul li a {
  display: block;
  line-height: 1;
  padding: 10px 15px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'caviar_dreamsbold';
}

.hcart_round {
  background: #de5036;
  border: 2px solid #fff;
  min-width: 18px;
  min-height: 18px;
  font-size: calc(9px + (10 - 9) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 100%;
  position: absolute;
  right: -8px;
  top: -12px;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 0;
}

.home-banner .slick-track .slick-slide > div {
  line-height: 0;
  width: 100%;
}

.featured-products-full .slick-dots {
  display: none !important;
}

.checkout-terms-and-condition {
  height: 136px;
  overflow-y: auto;
  margin: 0 0 15px 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  border: 1px solid #d0cfcb;
  padding: 15px 10px;
}

a.change-edt {
  color: #666;
  border-bottom: 1px solid #999;
  margin: 5px 0 0 0;
  display: inline-block;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

a.change-edt:hover {
  /*color: #f09509;*/
  /*border-color: #f09509;*/
}

.page404-main-div {
  text-align: center;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}

.page404-main-div .gohome {
  display: inline-block;
  line-height: 1;
  font-weight: bold;
  background: #000;
  padding: 15px 50px;
  border-radius: 4px;
  text-align: center;
  background: #000;
  margin: 10px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  color: #fff;
}
.page404-main-div h1 {
  margin-bottom: 10px;
  margin-top: 40px;
}

.innerproduct-button .disabled {
  background: #d5d1d2 !important;
  color: #561c4e;
}

.innerproducts-list-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.innerproduct-button .btn_minwid {
  background: #98684a;
}

.prodetailinner-main-div {
  min-height: 105px;
}

.product-detail-empty {
  padding: 52px 0px 35px 0px;
  text-align: center;
  color: #cb4d36;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.custom-select-bxcls {
  position: relative;
}

.custom-select-bxcls:after {
  content: "";
  background: #fff url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  height: 48px;
  pointer-events: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.custom-select-bxcls:after {
  height: 48px;
}

.custom-select-bxcls select {
  margin: 0 !important;
  width: 100%;
  height: 50px;
  padding-left: 10px;
}

.disable-addtocart {
  pointer-events: none;
  cursor: default;
}

.disable-addtocart:after {
  background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  content: "";
}

.modfr-alert-danger {
  display: none;
  background: #ffdfdf;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  text-align: center;
  padding: 10px;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #c10606;
}

.modfir_addcart_cls .qty_bx input {
  color: #fff;
}

.mdf_common_rows {
  position: relative;
}

.compo_pro_acc {
  background: #f9f9f9;
}

.prd_chosen_row.compo_top_div {
  background: #ffffff;
}

.compoMdfr_item_left {
  width: 100%;
  padding: 0;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
  margin: 0px;
}

.compoMdfr_item_left .form-group {
  width: 70%;
  float: left;
  margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.compopro_acc_head {
  padding: 12px 20px 12px 20px;
  cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
  background: #5e5e5e;
  content: "";
  display: block;
  height: 2px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
  -webkit-transform 0.25s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  width: 14px;
  z-index: 2;
}

.compopro_acc_head::before {
  content: "";
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.text-uppercase {
  text-transform: uppercase;
}

.product-rhs .cart_footer {
  padding: 20px 20px;
  box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14);
  margin-top: 15px;
}
.product-rhs .product_orders_top {
  display: none;
}
.cart_footer .cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
  font-size: calc(16px + (23 - 16) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1.2;
  font-family: 'caviar_dreamsbold';
}

.cart_row.grant-total-cls sup {
  font-size: 80%;
  position: relative;
  top: -6px;
}

.cart_footer .cart_row .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-header-first h4 {
  font-weight: bold;
  color: #1c1b1b;
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  margin: 0 0 5px 0;
  font-family: 'Montserrat', sans-serif;
}

.cart-header-first p {
  color: #000000;
  margin: 0;
}

.cart-header-second h5 {
  font-weight: bold;
  color: #1c1b1b;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  margin: 0 0 5px 0;
}

.cart-header-second p {
  font-weight: bold;
  color: #1c1b1b;
  font-size: calc(20px + (28 - 20) * ((100vw - 300px) / (1600 - 300)));
}

.product-details {
  padding: 20px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e6e6e6;
}
.order-details-with-clear,
.order_card_head {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background: #000;
  align-items: center;
}
.order-details-with-clear h5,
.order_card_head h5 {
  color: #fff;
  margin: 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.3px;
}

.order-details-with-clear .hclear_cart,
.order-details-with-clear h5,
.order_card_head h5 {
  color: #fff;
  margin: 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.3px;
  font-weight: 700;
}

.order-details-with-clear .hclear_cart {
  text-transform: uppercase;
  text-decoration: underline;
}

.cart_body {
  padding: 20px 20px 20px;
  /*box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14); */
}
.overall-parent {
  border: 0px solid #302d2a;
  border-radius: 5px;
}

.cartaction_bottom .btn {
  margin: auto;
  width: 100%;
  text-align: center;
  display: block;
  background: #561c4e;
  padding: 10px 10px;
  border-radius: 5px;
  height: auto;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.5px;
  color: #fff;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  transition: 0.3s linear all;
}

.cartaction_bottom .btn:hover {
  background: #fdd18c;
  color: #000;
}
.cart_row.grant-total-cls {
  /*border-top: 3px solid #f4f4f5;*/
  padding-top: 10px;
  margin-bottom: 20px;
}
.Viewreceipt .cart_row.grant-total-cls {
  border-top: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.Viewreceipt .tnk-delivery .cart_footer.tnk_cart_footer {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.Viewreceipt .delivery_total::before {
  position: absolute;
  left: 50px;
  right: 30px;
  background: #fff;
  top: 10px;
  content: "";
  margin: auto;
  border: 2px dashed #dfdad7;
}
.Viewreceipt .tnk-delivery .delivery_total_number:before {
  display: none;
}

.cart-header-first .row-replace,
.cart-header-second .row-replace,
#cart-close-span {
  display: block;
  position: relative;
  order: 2;
  width: 24px;
  height: 22px;
  cursor: pointer;
}

#cart-close-span:before,
#cart-close-span:after {
  position: absolute;
  left: 13px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #fff;
}
#cart-close-span:before {
  transform: rotate(45deg);
}
#cart-close-span:after {
  transform: rotate(-45deg);
}

.cart-header-first .row-replace .col-sm-cls,
.cart-header-second .row-replace .col-sm-cls {
  width: 50%;
}
.cart_left {
  width: calc(100% - 120px);
}
.cart_right {
  width: 110px;
}

.cart_info .cart_into-text {
  position: relative;
  display: flex;
}

.cart_info .cart_into_txt_cnt {
  margin-top: 5px;
}

.cart_info .cart_into-text img {
  position: relative;
  padding-right: 10px;
  width: 110px;
  height: 80px;
}
.gst-row {
  margin-top: 15px;
}
.productlist-main-div {
  padding: 0;
}

.del_address h5 {
  margin-bottom: 10px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.3px;
}
.del_address {
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.del_address .form-control {
  padding: 10px 10px;
  height: 38px;
}

.hcart_scrollarea .del_address .form-control {
    padding: 10px 50px 10px 10px;
    height: 50px;
}

.form-group.half-fg {
  display: flex;
  justify-content: space-between;
}
.form-group.half-fg .input_field {
  width: 49%;
}
.cart_body .hclear_cart:hover {
  color: #fdd18c;
}

.compo_minmax_maindiv .combo-input-label {
  display: block;
}

.compo_minmax_maindiv .max-min-bar {
  padding-bottom: 10px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.compo_minmax_maindiv .error_combo_div {
  color: red;
  padding-bottom: 10px;
  display: none;
}

.compo_minmax_maindiv .combo_pro_price {
  /*color: #f09509;*/
}

.alert-success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6;
}

.custom_close span {
  color: red;
}

.alert-success:before,
.single-danger:before {
  top: 10px;
  left: 8px;
}

.jquery-success-msg {
  margin-left: 20px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.btn-medium {
  min-width: 190px;
}

.cart_row.cart-header-second {
  margin: 15px 0 0 0;
}

.cart-direction {
  margin: 0 0 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.tnk-delivery .delivery-cart-div .cart-direction::before {
  position: absolute;
  left: 50px;
  right: 30px;
  background: #fff;
  top: 10px;
  content: "";
  margin: auto;
  border: 1px solid #dfdad7;
}
.innersection_wrap {
  padding: 20px 0 0 0;
}

.tnk-chk-order .button {
  display: block;
  margin: 0;
  width: 100%;
  padding: 18px 20px 18px;
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.tnk-chk-order .button:hover {
  background: #000000;
}

.thank-order-detaildiv {
  max-width: 580px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}

.mainacc_toptext {
  text-align: center;
  padding: 0 0 20px 0;
}

.mainacc_toptext h2 {
  margin: 20px 0 5px 0;
}

.mainacc_toptext p {
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  color: #000;
}
.myacc_order_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.myacc_order_details .current_order {
  width: 49%;
  margin-bottom: 20px;
  background: #fff;
  padding: 0 0 130px;
  position: relative;
  box-shadow: 0 3px 21px 0 rgb(0 0 0 / 13%);
}

.myacc_head_sec {
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 15px;
  overflow: hidden;
}

.head_right .head-group {
  z-index: 2;
  position: relative;
}

.head_left h4 {
  color: #fff;
  margin: 0;
  font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.head_right h4 {
  color: #fff;
  margin: 0;
  text-transform: capitalize;
  font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.head_right::before {
  content: "";
  display: block;
  background: #561c4e;
  width: 35%;
  position: absolute;
  right: -30px;
  height: 100%;
  top: 0;
  -webkit-transform: skew(-39deg);
  transform: skew(-39deg);
  z-index: 1;
}

.order_details_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.order_btns, .order_amt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
}

.order_btns .button {
  margin: 0;
  width: 49%;
  display: block;
  padding: 17px 10px;
  color: #000;
}

.myacc_order_details .order_details_footer .order_btns .button {
  border-radius: 5px;
  font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.order_btns .print_invoice {
  background: #f6f6f6;
  border: 1px solid #000;
}

.order_btns .view_recipt {
  background: #000;
  color: #fff;
  border: 1px solid;
}
.order_btns .view_recipt.button_full_view {
  width: 100%;
}
.order_btns .button:hover {
  color: #fff;
  /*background: #f09509;*/
  /*border: 1px solid #f09509;*/

  background: #561c4e ;
  border: 1px solid #561c4e ;
}

.order_amt {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #000;
  padding: 16px 15px;
}

.order_amt h3 {
  margin: 0;
  color: #fff;
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}

.order_amt-right sup {
  font-size: 75%;
  position: relative;
  top: -5px;
  margin: 0 2px 0 0;
}

.order_details_body {
  padding: 15px;
}

.order_no_deatails {
  margin: 0 0 20px 0;
  text-align: center;
}

.order_btns {
  padding: 10px 15px;
}

.order_no_deatails span {
  display: block;
}

.delivery_total_left img {
  margin: 0 0 10px 0;
}

.delivery_total {
  position: relative;
}

.delivery_total_text::before {
  position: absolute;
  left: 50px;
  right: 30px;
  background: #fff;
  top: 10px;
  content: "";
  margin: auto;
  border: 2px dashed #dfdad7;
}

.web_order_typecls {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0 50px 0;
}

.web_order_typecls > li {
  width: 100%;
  border-left: 2px solid rgba(255, 255, 255, 0);
}

.web_order_typecls li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 17px 10px;
  background: #e4e4e4;
  color: #000;
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
  background: #561c4ede;
  border: 2px solid #561c4e;
}

.web_order_typecls li a h5 {
  margin: 0;
  color: #000;
  text-transform: uppercase;
}
.web_order_typecls li.active a h5,
.web_order_typecls li a:hover h5 {
  color: #fff;
}

.sprite_lst_icon {
  margin: 0 10px 0 0;
}

.sprite_lst_icon.order_t {
  max-width: 33px;
}

.sprite_lst_icon.catering_icon_t {
  width: 22px;
}

.sprite_lst_icon.reserve_icon_t {
  width: 22px;
}

.myacc_order_details .reserv-orderlist {
  padding: 0;
  border-bottom: 2px solid #000000;
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
  margin: 15px 0px 0;
  border-top: 1px solid #e4e4e4;
  padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
  margin: 0;
  color: #000;
  font-size: calc(17px + (19 - 17) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
  margin: 0;
  color: #98684a;
  font-size: calc(17px + (21 - 17) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}

.reserv-loader-div {
  display: inline-block;
}

.reserv-orderlist  .delivery_total.delivery_total_number  .delivery_total_left  h4 {
  font-size: calc(17px + (19 - 17) * ((100vw - 300px) / (1600 - 300)));
}

.reserv-orderlist  .delivery_total.delivery_total_number  .delivery_total_left  h2 {
  margin-bottom: 5px;
}

.reserv-orderlist .delivery_total.delivery_total_number {
  margin: 0;
  padding: 0;
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
  /*background: #6e1d24;*/
}

.ord-pagination-col select {
  width: auto;
  height: auto;
  padding: 1px 0 1px 1px;
  border: 0;
  margin: 0 5px;
  background: none;
}

.delivery_submit_div .button {
  width: 100%;
}

.mainacc_menulist #masterCount {
  background: #fdd18c;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
  line-height: 19px;
  vertical-align: top;
  margin-top: 1px;
  padding: 0 4px;
  min-width: 20px;
  font-family: 'caviar_dreamsbold';
}

.reward-list-body .delivery_total.delivery_total_number {
  padding: 0;
  margin: 0;
}

.reward-list-body .delivery_total_left {
  display: inline-block;
  width: auto;
  float: none;
  margin: 0 15px 0 0;
}

.reward-list-body  .delivery_total.delivery_total_number  .delivery_total_left  h4 {
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.reward-list-body .order_amt_div h3 {
  margin: 0;
  font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1600 - 300)));
  padding: 10px 0 0 0;
}

.reward-list-body .order_amt_div h3 sup {
  font-size: 75%;
}

.reward-list-body .order_amt-right h3 {
  /*color: #f09509;*/
}

.reward-list-body .checkoutDate,
.reward-list-body .checkoutTime {
  position: relative;
  padding: 0 0 0 22px;
  color: #474747 !important;
}

.reward-list-body .checkoutDate:before {
  background: url(../images/calenderorder.svg) no-repeat;
}

.reward-list-body .checkoutTime:before {
  background: url(../images/clock-round.svg) no-repeat;
}

.reward-list-body .checkoutDate:before,
.reward-list-body .checkoutTime:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  content: "";
}

.reward-list-body .myacc_order_details .current_order {
  padding: 0 0 110px 0;
}

.promo-form {
  text-align: center;
  padding: 40px 15px;
  margin: 0;
  background: #f0f0f0;
}

.promo_wrap .both-order-tab {
  padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
  position: relative;
  max-width: 640px;
  margin: 0 auto;
}

.promo-form input[type="text"] {
  margin: 0;
  height: 54px;
  padding: 5px 200px 5px 20px;
  background: #fff;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  border-radius: 4px 40px 40px 4px;
}

.promo-form .applyBtn {
  margin: 0;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  padding: 14px 20px;
  height: 54px;
  min-width: 200px;
  border-radius: 5px;
}
.promo-form .applyBtn:hover {
  background: #fdd18c;
}

.promo-form h3 {
  color: #000;
  font-size: calc(24px + (44 - 24) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 5px;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'caviar_dreamsbold';
}

.promo-form h3 span {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  line-height: 0.5;
  margin: 0 0 5px;
}

.promo-earned-col-image {
  position: relative;
  width: 50%;
}
.promo-earned-col-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  max-height: 206px;
  object-position: center;
}
.promo-earned-col-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
  background: #fff;
}
.promo-earned-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 10px;
  width: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 1;
}
.promo-earned-info:before {
  content: "";
  content: "";
  z-index: -1;
  position: absolute;
  top: -1px;
  left: -40px;
  bottom: -1px;
  background: #ffffff;
  width: 80px;
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
}
.promo-earned-code {
  font-size: calc(16px + (17 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 5px 0;
}
.promo-earned-code span {
  display: block;
  font-size: calc(22px + (35 - 22) * ((100vw - 300px) / (1600 - 300)));
}
span.promo-valid {
  color: #a6a6a6;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  padding: 0 0 15px 0;
  display: block;
}
.promation_btn {
  line-height: 0;
}
.promation_btn {
  display: flex;
  width: 100%;
}
.promation_btn .button {
  width: 49%;
  margin: 0;
  background: #561c4e;
  border: 1px solid #561c4e;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
  padding: 10px 5px;
  color: #000;
}
.promo-earned-info p {
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #4a4646;
}
.promation_btn .promation_btn-one {
  background: #f7f7f7;
  color: #000;
  border: 1px solid #d4d4d1;
  margin-right: 4px;
}
.promation_btn .button:hover {
  background: #000;
  color: #fff;
  border-color: #000;
}

.promo-bot-left span {
  display: block;
}

.promo-bot-left p {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.promo-bot-left p span {
  display: block;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.promo-earned-info .promo-bot-left p {
  color: #000;
  font-size: calc(22px + (30 - 22) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}

.promation_btn {
  line-height: 0;
}

.promo-earned-col .tag {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  color: #fff;
  background: #000000;
  margin: 0;
  padding: 10px 12px 5px;
  position: absolute;
  left: -43px;
  top: 30px;
  transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  width: 220px;
  line-height: normal;
  text-align: center;
}

.promo-earned-col .tag span {
  display: inline-block;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}
.mypromo-main-div .myacc_order_details .promo-earned-col {
  width: 49%;
  background: #fff;
  margin: 0 0 40px 0;
}

.mypromo-main-div .myacc_order_details li:nth-child(3n) {
  margin-right: 0;
}

.pickup-cart-div .cart-direction:after {
  display: none;
}

.pickup-cart-div .cart-direction img {
  width: 50px;
  margin: 0 auto;
}

.pickup-cart-div .cart-header-first .row {
  display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
  padding: 0 0 10px 0;
}

.mobile-order-nowbtn {
  display: none;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}

span.myaccount_separate {
  width: 2px;
  height: 16px;
  display: inline-block;
  background: #fff;
  margin: 0 7px 0 5px;
}

.load_more_div {
  display: block;
  clear: both;
  width: 100%;
  text-align: center;
  padding: 0px 0 0 0;
}

.load_more_div .load_more_data {
  margin: 0 auto;
  min-width: 160px;
}

.make-pay-div h4 {
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.pay-cart-header {
  padding: 18px 0px 5px 0px !important;
}

.inner_banner {
  height: 400px;
}

.inner_banner img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

.err_alt {
  border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
  color: #ff1313;
}

.mfp-bg {
  background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
  position: fixed;
  bottom: 90px;
  right: 30px;
  width: auto;
  height: auto;
  z-index: 9;
  opacity: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#scrollbutton.show {
  display: inline-block;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#scrollbutton a {
  background: #353535;
  display: flex;
  justify-content: center;
  position: relative;
  color: #fff;
  text-decoration: none;
  padding: 10px 14px;
  overflow: hidden;
  height: 38px;
  width: 38px;
  line-height: 15px;
  border-radius: 100%;
  border: 2px solid #353535;
  font-size: calc(17px + (18 - 17) * ((100vw - 300px) / (1600 - 300)));
}

#scrollbutton a:hover {
  background: #f5ce6da1;
  border: 2px solid #f5ce6d;
}

#scrollbutton a i.ars {
  -webkit-transition: all 0.2s linear 0.2s;
  transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
  -webkit-transform: translateY(-25px) rotateY(360deg);
  transform: translateY(-25px) rotateY(360deg);
  opacity: 0;
  -webkit-transition: all 0.3s linear 0.2s;
  transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1,
#scrollbutton a i.ars2 {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
  transform: translate(-50%, -50%) rotateZ(360deg);
  opacity: 0;
  -webkit-transition: all 0.2s linear 0.2s;
  transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
  bottom: 10%;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transition: all 0.2s linear 0.2s;
  transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
  bottom: 0%;
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transition: all 0.2s linear 0.4s;
  transition: all 0.2s linear 0.4s;
}
.oreder-row-inv {
  margin: 0;
}

.oreder-row-inv .cart_img {
  padding: 0;
}

.oreder_itm_row {
  padding: 18px 5px 5px 5px !important;
}

.order-items-maindiv {
  background: #f5f5f5;
}
.Viewreceipt .order-items-maindiv {
  background: #fff;
}
.Viewreceipt .oreder_itm_row {
  padding: 10px 0 10px !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.Viewreceipt .cart_price,
.thank-order-detaildiv .cart_price {
  padding-right: 0;
}
.Viewreceipt .tnk-delivery .delivery_total_number {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.oreder-row-inv .cart_left {
  text-align: left;
}

.month-select-div,
.choose-date-div {
  margin: 0 auto 40px auto;
}

.tarms-error {
  display: none;
  color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
  display: block;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-family: 'caviar_dreamsbold';
}

.catering-product-rowlist .price_nor {
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  padding: 10px 0 0 0;
  font-family: 'caviar_dreamsregular';
  font-weight: bold;
}

.catering-product-rowlist .price_nor sup {
  font-size: 80%;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}

.dotted-line-top {
  border-top: 1px dotted #ccc;
  padding: 20px 0 0 0;
  margin: 10px 0 0 0;
}

.custom-tarms-div .sign_reg {
  padding-left: 30px;
}

.custom-tarms-div span:before {
  width: 20px;
  height: 20px;
  /*border: 2px solid #f09509;*/
  margin-top: 2px;
  border-radius: 0;
}

.custom-tarms-div
  .custom_checkbox
  input[type="checkbox"]:checked
  + span:before {
  background: none;
  content: "\F00C";
  font-family: FontAwesome;
  text-rendering: auto;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  line-height: 16px;
  color: #fff;
  text-align: center;
  background: #561c4e;
  border: 2px solid #561c4e;
}

.error_div_cls {
  color: red;
  display: none;
}

.single_outlet_info {
  padding-top: 15px;
  text-align: center;
}

.single_outlet_info p {
  margin: 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.single_outlet_info .mainacc_toptext p {
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-style: normal;
}

.isDisabled {
  cursor: default;
  text-decoration: none;
}

.disableDivCls {
  display: none;
}

.event-type-single {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rmv-border-bottom {
  border-bottom: 0px !important;
}

.no-stock-product .innerproduct-item::after {
  opacity: 1 !important;
}

.no-stock-product .innerproduct-button {
  opacity: 1 !important;
  visibility: visible !important;
  top: 50% !important;
}

.no-stock-product .innerproduct-button .btn {
  cursor: not-allowed;
  pointer-events: all !important;
}

.guest_btn {
  text-align: center;
}

.controls.single-link {
  text-align: center;
}

.controls.single-link a {
  display: inline-block;
  color: #272727;
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
}

.back-to-listbtn a {
  width: 100% !important;
}

.apply-red {
  color: red !important;
}

.pdpa_trmcont_link {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Montserrat', sans-serif;
}


.pdpa_trmcont_link span {
  display: inline-block;
}


.delivery_outletpoup .outlet-scooter-img {
  width: 86px;
}

.outleterror {
 color: #561c4e;
}
.delete-act-icon {
  position: absolute;
  right: 2px;
  top: 0px;
}
.act-remove-topdiv {
  position: relative;
  padding-right: 20px;
}

.venue_popup .close-icon-but {
  background: #98684a;
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  top: -15px;
  right: -15px;
  padding: 0;
  margin: 0;
}
.hide_section_cls,
.back_click_mbl {
  display: none;
}
.back_click_mbl a:before {
  content: "\f104";
  font-family: FontAwesome;
  margin-right: 5px;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}
.custom_center_alertcls {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  max-width: 450px;
  margin: auto !important;
  padding: 22px;
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 10px;

  display: inline-block;
  text-align: center;
}
.custom_center_alertcls.alert-danger {
  color: #ca322f;
  background-color: #fdd1d1;
}
.mfp-custom-close {
  position: absolute;
}
img.view-img-span {
  width: 20px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
img.close-img-span {
  width: 19px;
  position: relative;
  top: 1px;
  margin-left: 5px;
  display: none;
}
.chk-payment .form-group.custom-tarms-div {
  padding: 0 20px 20px;
  text-align: left;
}
.text-lbl {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li {
  margin-right: 0;
}
.price_nor dl {
  display: inline-block;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  vertical-align: text-top;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}
.checkout-control-group-middle .syd_merge .form-group222 input {
  border-radius: 0;
}
.timeslot_info {
  margin-bottom: 5px;
  color: red;
}
.ordrdatetime_error .error {
  width: 100%;
}
.syd_merge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.syd_date {
  width: 48%;
}
.syd_time.delivery_submit_cls {
  width: 48%;
}
.syd_merge .form-group {
  margin: 0;
}

.checkout-control-group-middle .react-datepicker-wrapper {
  display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
  position: relative;
  display: block;
}
.datetime_selt_sec .react-datepicker-wrapper,
.datetime_selt_sec .react-datepicker__input-container {
  display: block;
}

.membership_status {
  margin-top: -33px;
  position: relative;
}

.membership_status span {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
}

/*myaccount - 13 jan 2020*/
.membership-desc {
  background: #561c4e;
  border: 2px solid #fff;
  padding: 12px 5px 10px;
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  margin: 15px auto;
  max-width: 250px;
}
.membership-desc img {
  width: 20px;
  margin-right: 8px;
}
.user-id {
  margin: 10px 0;
}
.user-id span {
  text-transform: uppercase;
  font-weight: bold;
}
.membership-progress {
  background: #4f7873;
  margin: 15px -10px 0;
  padding: 20px 0 15px;
}
.membership-progress p {
  font-weight: bold;
  margin: 9px auto 0;
  max-width: 170px;
  line-height: 1.2;
}
.pg-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 15px;
  border-radius: 25px;
  background-color: #2a2521;
  max-width: 175px;
  margin: 0 auto;
}
.pg-bar span {
  position: absolute;
  height: 100%;
  width: 0%;
  background-color: #fff;
  top: 0;
  left: 0;
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}
.member-discount-desc {
  display: inline-block;
  background: #561c4e;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  margin: 5px 0;
  line-height: 1;
  text-align: center;
}
.member-discount-total {
  color: #98684a;
  font-weight: bold;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.product-tag-list ul {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
}
.product-tag-list ul li {
  margin: 0 3px 3px 3px;
  background: #f2efef;
  line-height: 1;
  font-family: 'caviar_dreamsbold';
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
  padding: 8px 13px 3px;
  border-radius: 15px;
}

.products-image-div,
.det_inner_image {
  height: 236px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.product-info-div,
.det_inner_content {
  padding: 15px 12px 25px 12px;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: calc(100% - 236px);
  background: #fff;
}
.det_inner_content h5 {
  text-transform: inherit;
}
.product-title h3 {
  font-family: 'caviar_dreamsbold';
  text-transform: inherit;
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
}
.products-image-div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.favour-icon-link {
  position: absolute;
  top: 9px;
  right: 9px;
}
.favour-heartyellow-icon {
  display: none;
}
.favour-icon-link.active .favour-heartyellow-icon {
  display: block;
}
.favour-icon-link.active .favour-heartwhite-icon {
  display: none;
}
.product-title-maindiv {
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
}
.product-title h5 {
  margin-bottom: 10px;
  text-transform: inherit;
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.product-tag-list {
}
.product-short-description {
  color: #353535;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 15px 0;
  line-height: 1.3;
  flex: 1 1 auto;
}
.product-price h3 {
  margin: 0;
  font-family: 'caviar_dreamsbold';
  font-size: calc(17px + (26 - 17) * ((100vw - 300px) / (1600 - 300)));
  color: #606060;
}
.product-price h3 sup {
  font-size: 70%;
}
.product-spl-price {
  text-align: center;
  padding-bottom: 7px;
}
.product-spl-price h3 {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  margin-bottom: 0;
  display: inline-flex;
  color: #130e0b;
  text-decoration: line-through;
}
.products-ordernow-action .button {
  margin-bottom: 0;
  margin-top: 0;
  display: block;
  background: #561c4e;
  color: #fff;
  padding: 18px 24px;
  font-family: 'caviar_dreamsbold';
  
  letter-spacing: 2px;
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.products-ordernow-action .button:hover {
  background: #a3a3a3;
  color: #fff;
}
.products-ordernow-action {
  margin: 10px 0 0 0;
}
.product-short-description p {
  width: 100%;
  max-width: 100%;
  margin: 0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Montserrat', sans-serif;
}
.productlist-main-div .products-list-ulmain .products-single-li:hover {  border: 1px solid #01010124;}

.featured-products-maintitle .products-list-ulmain .products-single-li:hover {  border: 1px solid #01010124;}
.featured-products-maintitle .products-list-ulmain .products-single-li {  border: 1px solid #01010124;}

.products-single-li {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.featured-products-maintitle {
  text-align: center;
  padding-bottom: 40px;
}
.featured-products-maintitle h2 {
  margin: 0px 0px 5px 0px;
}
.featured-products-infotag {
  margin: 0;
}

.products-ordernow-action .button.disabled,
.products-ordernow-action .button.disabled:hover {
  opacity: 0.4;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  pointer-events: none;
}
.hmenu-login-section > ul {
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}
.doorstep-block-section {
  position: relative;
}

.guest-checkout-popupdiv {
  background: #ededed;
  margin: 0 -55px;
  padding: 25px 55px;
}
.guest-checkout-popupdiv .button {
  margin: 0;
  background: #464646;
  display: block;
  width: 100%;
  padding: 17px 5px;
  font-family: 'caviar_dreamsbold';
}
.signup-gender-selct {
  margin-bottom: 0;
}
span.birthday_info_sp {
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #737373;
}

span.birthday_info_sp b {
  font-weight: 500;
  font-style: italic;
}

.product-menu-listing .container:before,
.product-menu-listing .container:after {
  display: none;
}

.products-list-ulmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.products-list-ulmain > li {
  text-align: center;
  width: 32%;
  margin: 0 2% 20px 0;
  background: #fff;
  /*box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.11);*/
  transition: 0.4s linear all;
}

.product-rhs {
  width: 374px;
  position: relative;
}
.product-rhs > ul {
  list-style: none;
  padding: 0;
}
.productlist-main-div .products-list-ulmain{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.productlist-main-div .products-list-ulmain > li {
  text-align: center;
  width: 32%;
  margin: 0 2% 20px 0;
  background: #fff;
  /*-webkit-box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);*/
  transition: 0.4s linear all;
  border: 1px solid #01010124;
}
.productlist-main-div .products-list-ulmain > li:nth-child(3n) {
  margin-right: 0;
}
.productlist-main-div .products-list-ulmain > li.active,
.productlist-main-div .products-list-ulmain > li:hover,
.featured-products-main > ul > li:hover {
}

.cart_footer .progress_bar_div .indication {
  width: 100%;
}

.progress_bar_div .indication .indication_progress {
  background: #ececec;
  margin-bottom: 10px;
}

.progress_bar_div .indication .indication_progress .progress_bar {
  background: #fdd18c;
}

.progress_bar_div .help-block {
 /* color: #676767;*/
  font-family: 'caviar_dreamsbold';
}

.search-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 30px;
}
.search-product > .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.search-product > .container:before,
.search-product > .container:after,
.menu-top-section-inner .container:before,
.menu-top-section-inner .container:after {
  display: none;
}

.spm-lhs {
  position: relative;
}
.spm-lhs .search-rest {
  padding-right: 45px;
  border: 0;
  box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07);
  height: 52px;
  padding-left: 18px;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
}
.spm-lhs img {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 21px;
}
.spm-lhs {
  width: 380px;
}
.spm-rhs {
  width: 162px;
}
.spm-rhs .filter-area {
  padding-right: 45px;
  border: 0;
  box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07);
  height: 52px;
  padding-left: 18px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  padding: 14px 40px 12px 18px;
  position: relative;
  width: 100%;
  background: #fff;
  cursor: pointer;
  cursor: pointer;
}

.spm-rhs {
  width: 162px;
  margin-left: 10px;
}

.filter-area img {
  position: absolute;
  right: 15px;
  top: 18px;
  width: 17px;
}
.filter-area img.filter-clear-image {
  width: 12px;
  top: 20px;
}
.filter-details {
  position: absolute;
  z-index: 9;
  width: 100%;
  left: 0;
  top: 105%;
  display: none;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 20px;
}
.filter-details.active {
  display: block;
}
.common-filter {
  padding-bottom: 20px;
}
.common-filter:last-child {
  padding-bottom: 0;
}
.common-filter ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.common-filter ul li {
  width: 23.828125%;
  margin: 0px 1.5625% 15px 0;
  cursor: pointer;
  line-height: 20px;
}
.common-filter ul li:nth-child(4n) {
  margin-right: 0;
}
.common-filter h3 {
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 15px 0;
}
.common-filter.item-offer ul li {
  display: block;
  font-weight: 700;
  border: 2px solid #d3334b;
  border-radius: 5px;
  padding: 10px 5px;
  line-height: 1;
  text-align: center;
}
.common-filter ul .custom_checkbox span {
  padding-left: 25px;
}
.common-filter ul .custom_checkbox span img {
  vertical-align: text-bottom;
  margin-right: 3px;
}

.clear-filter {
  position: absolute;
  right: 20px;
  top: 25px;
  line-height: 1;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.backto-rest {
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.1);
  background: #fff;
  margin-right: 6px;
  text-align: center;
}
.backto-rest a {
  display: block;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;
  transition: 0.3s linear all;
}
.backto-rest a img {
  width: 28px;
}
.backto-rest a:hover {
  background: #fdb330;
}
.search-product-main {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.product-lo-top a {
  display: inline-block;
  padding: 13px 20px;
  font-family: 'caviar_dreamsbold';
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #b4b3b3;
  line-height: 1.3;
  text-transform: uppercase;
  background: #e7e6e6;
  min-height: 46px;
  position: relative;
  width: calc(50% - 11px);
  text-align: center;
}
.product-lo-top a:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  float: left;
  padding-right: 1px;
}
.product-lo-top a:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  float: right;
  padding-left: 1px;
}

.product-lo-top a:first-child:after,
.product-lo-top a:last-child:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
}
.product-lo-top a:first-child:after {
  left: 100%;
  border-top: 46px solid #e7e6e6;
  border-right: 23px solid transparent;
}
.product-lo-top a:last-child:before {
  right: 100%;
  border-bottom: 46px solid #e7e6e6;
  border-left: 23px solid transparent;
}

.product-lo-top a.active {  color: #fff;  background: #561c4e;}
.product-lo-top a.active:last-child:before {  border-bottom: #561c4e;}
.product-lo-top a.active:first-child:after {  border-top: #561c4e;}
.product-lo-top { min-width: 330px; }

.progress_bar_div {
  margin-top: 10px;
}
.common-inner-banner {
  padding: 40px 20px 20px;
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  /*height: 252px;*/
}

.contactusmain-top-div .common-inner-banner p {
  color: #fff;
  font-family: 'caviar_dreamsbold';
  font-size: calc(24px + (42 - 24) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
}

.common-inner-banner img {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.checkout-main-div .common-inner-banner p,
.pagesList-main-div .common-inner-banner .cms-top-hdrcls {
  margin: 0;
  font-family: 'caviar_dreamsbold';
  font-size: calc(24px + (42 - 24) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  line-height: 1.1;
  color: #fff;
}

.myacc-main-div .common-inner-blckdiv .common-inner-banner p {
  text-transform: uppercase;
  font-size: calc(28px + (54 - 28) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  color: #fff;
  line-height: 1;
}

.myacc-main-div .common-inner-blckdiv .common-inner-banner span {
  font-family: 'caviar_dreamsregular';
}

.common-inner-banner span {
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  margin: 10px 0 0;
  color: #fff;
}
.common-inner-banner h2 {
  font-size: calc(24px + (38 - 24) * ((100vw - 300px) / (1600 - 300)));
  color: #fdd18c;
  line-height: 1;
  margin: 0 0 5px 0;
  text-transform: capitalize;
}
.inner-banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.productpage-main-div .common-inner-blckdiv .inner-banner-content p {
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  margin-bottom: 0;
}
.menu-section-inner {
  max-height: 500px;
  overflow: auto;
}
.fxd_lftmenu_div {
  position: fixed;
  top: 160px;
  width: 300px;
}
.bottom_abs {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 300px;
}

.prodet_top {
  text-align: center;
}
.prodet_top img {
  margin: 0 auto;
}
.prodetailinner-main-div .modal-body {
  padding: 0;
}
.detail-pg-slider .slick-track .slick-slide > div {
  margin: 30px 7px;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
  box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.detail-pg-slider .product-title h3 {
  font-size: calc(17px + (21 - 17) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
}
.addonpro-slider-top {
  margin: 70px 0 0px 0;
}
.chk-payment-title h3,
.chk-addonpro-title h3 {
  font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  text-align: center;
}
.detail-pg-slider .product-tag-list {
  bottom: -8px;
}
.chk-addonpro-title h3 {
  margin-bottom: 0px;
}
.addcart_row:after {
  clear: both;
  content: "";
  display: table;
  overflow: hidden;
}
.birthday-info-div {
  padding: 15px 5px 20px;
  text-align: center;
}
.birthday-info-top h3 {
  font-size: calc(24px + (43 - 24) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  margin-bottom: 0;
  padding-top: 10px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.birthday-info-top h3 img {
  margin: 0px 0 0 15px;
  position: relative;
  top: -5px;
}
.birthday-info-middle {
  font-family: 'caviar_dreamsboldregular';
  padding: 10px 0 0 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
}
.left-input-div,
.right-input-div {
  max-width: 280px;
  width: 100%;
}
.birthday-inpt-act {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 570px;
  margin: 0 auto;
}
.narml_select {
  position: relative;
}
.narml_select:after {
  width: 30px;
  height: 46px;
  content: "";
  position: absolute;
  right: 1px;
  background: #fff;
  top: 1px;
}

.chk-payment .chk-payment-title h3 {
  text-transform: capitalize;
}
.normal-popup {
  max-width: 680px;
  text-align: center;
}
.normal_popup_cont ul {
  padding: 0;
  list-style: none;
}
.normal-popup button.mfp-close {
  background: #561c4e;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  line-height: 1;
}

.promopopup-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#promo-check-popup {
  max-width: 810px;
  background: none;
}
.promopopup-rhs {
  width: 53.08641975308642%;
  border: 1px solid #561c4e;
}
.promopopup-lhs {
  width: 46.91358024691358%;
  border-top: 30px solid rgba(0, 0, 0, 0);
  border-bottom: 30px solid rgba(0, 0, 0, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.frm-action-div {
  background: #561c4e;
  color: #fff;
  padding: 40px 30px;
}
.promopopup-rhs img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.promopopup-lhs .frm-top-title h3 {
  color: #fff;
  margin-bottom: 0px;
}
.promopopup-lhs .frm-inputbtn-div {
  position: relative;
}
.promopopup-lhs .frm-inputbtn-div .button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  height: 48px;
  background: #000;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  padding: 10px 14px 10px;
  font-family: 'caviar_dreamsregular';
  font-weight: bold;
  border: 1px solid #fff;
}
.promopopup-lhs .form-group input {
  border-color: #fff;
  padding-right: 110px;
}
.promopopup-lhs .frm-bottom-text {
  text-transform: uppercase;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin-bottom: 0;
}
.promopopup-lhs .frm-bottom-text p {
  margin: 0;
}
.promopopup-lhs .form-group {
  margin-bottom: 5px;
}
#promomail-error {
  color: #b70000;
  line-height: 1;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}
.promo-check-popup button.mfp-close {
  background: #561c4e;
  opacity: 1;
  color: #fff;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  line-height: 1;
  top: -15px;
  right: -15px;
}

.prodetailinner-main-div button.close {
  width: 24px;
  height: 24px;
  background: #000;
  color: #fff;
  box-shadow: none;
  top: 5px;
  right: 5px;
  text-indent: 9999px;
  position: absolute;
}

.prodetailinner-main-div button.close:after {
  background: url(../images/plus-white.svg) no-repeat;
  width: 16px;
  height: 16px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#ProductDetailMdl .modal-content {
  border-radius: 0;
}

.mbl-hiden {
  overflow-y: hidden;
}

.mobile-order-nowbtn a {
  padding: 20px 15px;
  background: #561c4e;
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  transition: all 0.3s ease;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  font-family: 'caviar_dreamsbold';
  display: block;
  color: #fff;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.mobile-login {
  display: none;
  position: relative;
}
.mobile-login ul {
  padding: 0;
  list-style: none;
  position: absolute;
  top: 110%;
  left: 0;
  background: #fff;
  border-radius: 6px;
  min-width: 150px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
  z-index 0s linear 0.01s;
  z-index: 5;
}
.mobile-login ul.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.mobile-login ul li a {
  display: block;
  line-height: 1;
  padding: 10px 20px;
  color: #000;
  border-bottom: 1px solid rgb(0 0 0 / 22%);
  font-family: 'caviar_dreamsbold';
}
a.controller-nav img {
  width: 28px;
}

.mobile-menu {
  position: fixed;
  padding: 0;
  overflow: hidden;
  background: #000;
  height: 100vh;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.2s linear, -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.2s linear, transform 0.3s ease-in-out;
  transition: opacity 0.2s linear, transform 0.3s ease-in-out,
  -webkit-transform 0.3s ease-in-out;
  width: 100vw;
  z-index: 99999;
  max-width: 520px;
}

.mobile-menu .menu {
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
}

.mobile-menu .menu li {
  list-style: none;
  position: relative;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.22);
  padding-right: 40px;
}

.mobile-menu .mobile-menu-body {
  overflow: hidden;
  overflow-y: auto;
  padding: 50px 40px 40px;
  height: 100%;
}

.mobile-menu.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.mobile-menu.active .menu a {
  -webkit-animation: a 0.4s 0.2s backwards;
  animation: a 0.4s 0.2s backwards;
}

.mobile-menu.active .menu li:nth-of-type(2) a {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.mobile-menu.active .menu li:nth-of-type(3) a {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.mobile-menu.active .menu li:nth-of-type(4) a {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.mobile-menu.active .menu li:nth-of-type(5) a {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.mobile-menu.active .menu li:nth-of-type(6) a {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.mobile-menu.active .menu li:nth-of-type(7) a {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.mobile-menu .toggle-submenu-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  display: block;
  height: 60px;
  cursor: pointer;
}

.mobile-menu .toggle-submenu-button:before {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
  background: #fff;
  display: block;
  height: 2px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
  -webkit-transform 0.25s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  width: 14px;
  z-index: 1;
  content: "";
}

.mobile-menu .toggle-submenu-button:after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
  transform: rotate(1turn);
  -webkit-transform: rotate(1turn);
}

.mobile-menu .mobile-menu-close span {
  cursor: pointer;
  display: block;
  width: 22px;
  height: 22px;
  position: relative;
}

.mobile-menu .mobile-menu-close span:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
  background: #fff;
  display: block;
  height: 2px;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
  -webkit-transform 0.25s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  width: 24px;
  z-index: 2;
  content: "";
}

.mobile-menu .mobile-menu-close span:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mobile-menu .mobile-menu-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  position: fixed;
  top: 0;
  right: 40px;
  left: 40px;
  height: 50px;
  background: #000;
  z-index: 2;
  text-align: right;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.22);
}

.mobile-menu .menu a {
  display: block;
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  padding: 1.3rem 0;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.mobile-menu .menu a:hover {
  color: #fdd18c;
}

.mobile-menu .menu .sub-menu {
  display: none;
  padding: 0;
}

.mobile-menu .menu .sub-menu li {
  padding-right: 0;
}

.mobile-menu .menu .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu .menu .sub-menu a {
  font-family: 'caviar_dreamsregular';
}


@-webkit-keyframes a {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes a {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.pkselect_tabsec .tab-content {
  background: #f9f7f8;
  padding: 0px 25px 20px;
}
.rest-list-cls {
  margin-top: 20px;
}
.li-full-width {
  width: 100% !important;
}
.btn-act-clss {
  color: #fff;
  background: #000;
  /*border: 1px solid #f09509;*/
}

#delivery-continue-link,
#takeaway-continue-link {
  display: none;
}

/* omise */
.omise-form-error input {
  -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0),
    0 0 2px 0 rgba(255, 0, 0, 0.5);
  box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0),
    0 0 2px 0 rgba(255, 0, 0, 0.5);
  border-color: #ff7076;
  border-top-color: #ff5c61;
}

.pay-omiseconf-popup {
  max-width: 340px;
  border-radius: 10px;
  background: #f5f5f7;
}
span.omise-pay-img {
  display: block;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  margin: 0 auto;
  border: 4px solid #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  position: absolute;
  top: -39px;
  left: 0;
  right: 0;
}

img.makisan-logo-img {
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: none;
}

.omise_pay_header {
  position: relative;
  padding: 50px 30px 25px;
  text-align: center;
  border-bottom: 1px solid #d2d2d3;
}
.omise_pay_body {
  padding: 30px;
  position: relative;
  border-top: 2px solid #fff;
}
.omisepop_in .form-control {
  padding: 10px;
  height: 40px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 4px;
  padding-left: 36px;
}
.expire_row_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.expire_row_inner > div {
  width: 32%;
  position: relative;
}
.omisepop_in .alt_btns .button {
  margin-bottom: 0;
  width: 100%;
  padding: 13px 10px 11px;
  border-radius: 4px;
}
.omisepop_in .form_sec i {
  position: absolute;
  left: 10px;
  color: #888;
  top: 12px;
}
.omisepop_in .form_sec > div {
  position: relative;
}
.omisepop_in .form_sec .uname-box i {
  left: 13px;
}
.omisepop_in .form_sec .card-numbr-div i,
.omisepop_in .form_sec .expire_left i {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  top: 13px;
}
.omisepop_in .form_sec .expire_right i {
  font-size: calc(16px + (19 - 16) * ((100vw - 300px) / (1600 - 300)));
  top: 12px;
}
.expire_mdl .form-control {
  padding-left: 15px;
}
.card {
  position: absolute;
  display: block;
  right: 10px;
  width: 28px;
  height: 19px;
  background: url(../images/cards/spritecard.png) no-repeat 0;
  top: 10px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  background-position: 0 0;
  overflow: hidden;
}
.visa + .card {
  background-position: 0 -19px;
}
.master + .card {
  background-position: 0 -38px;
}
.discover + .card {
  background-position: 0 -57px;
}
.american + .card {
  background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
  padding: 0;
  width: 21px;
  height: 21px;
  display: block;
  color: #fff !important;
  border-radius: 50%;
  line-height: 21px;
  background: #a5a5a5;
  border: 1px solid #929292;
  top: 10px;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  right: 10px;
  text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover {
  background: #000;
  border-color: #000;
}
.omisepay-mode-option {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #ffd025;
  line-height: 1;
  padding: 9px 11px 8px;
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
}
.omise-error-info-div {
  color: #ffffff;
  background-color: #b12f2f;
  border-color: #ebccd1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  line-height: 1;
}
.omise-error-icon {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.omise_alert_close {
  position: absolute;
  right: 0px;
  color: #fff;
  top: 0;
}
.omise-error-msg {
  margin: 0 0 0 11px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.omise-error-info-div .container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}
.displayDivCls {
  display: none;
}
.would-like-bottom label.chk_hea {
  margin: 0;
  padding: 0 0 0 35px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
}

.would-like-bottom .custom_checkbox {
  left: 0;
  right: inherit;
  top: -6px;
}

.would-like-bottom {
  margin: 15px 0 10px;
}

.product_sidebar_top {
  display: none;
}
.product-rhs .datetime-wrap .react-datepicker-wrapper {
  display: block;
}
.product-lhs-inner {
  box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14);
  padding: 10px 0 0 0;
}
.catering-product-list .product-lhs {
  box-shadow: none;
  padding: 0;
}
.products_sidebar_inner ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.products_sidebar_inner ul li {
  width: 24.6%;
  min-height: 56px;
  margin: 0 0.5% 5px 0;
}
.products_sidebar_inner ul li a,
.slick-slider .products_sidebar_content a {
  color: #9d9d9d;
  border: 2px solid #999999;
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9d9d9d;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  transition: 0.3s linear all;
  height: 100%;
  border-radius: 5px;
}
.products_sidebar_inner ul li:nth-child(4n) {
  margin-right: 0;
}
.products_sidebar_inner ul li:hover a,
.products_sidebar_inner ul li.active a,
.slick-slider .products_sidebar_content a:hover,
.slick-slider .products_sidebar_content.active a {
  color: #fff;
  background: #561c4e;
  border: 2px solid #561c4e;
}
.product-lhs-inner .products_list {
  padding: 22px 18px 20px 18px;
}
.products_sidebar_inner {
  margin-bottom: 15px;
}
.products_list_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.products_list_inner {
  width: 49%;
}
.products_list_desc {
  width: 49%;
}
body .accordion {
  border: 0;
  border-radius: 0;
}
.product_acc_grp .accordion .accordion_title {
  background: #282828;
  color: #fff;
  font-family: 'caviar_dreamsbold';
  padding: 12px 35px 10px 12px;
  line-height: 1;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.product_acc_grp .accordion {
  margin: 0 0 10px 0;
}
.product_acc_grp .accordion .accordion_title span {
  color: #ece223;
  font-family: 'Montserrat', sans-serif;
  font-size: 90%;
}
.product_acc_grp .accordion .accordion_title::before,
.product_acc_grp .accordion .accordion_title::after {
  background: #fff;
  content: "";
  display: block;
  height: 2px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transition: transform 0.25s ease-in-out 0s;
  -webkit-transition: transform 0.25s ease-in-out 0s;
  width: 14px;
  z-index: 2;
}
.product_acc_grp .accordion .accordion_title::before {
  content: "";
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
.product_acc_grp .accordion .accordion_title::after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.product_acc_grp .accordion .accordion_title.active::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.product_acc_grp .accordion .accordion_title.active::after {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

a.button.add_to_cart {
  width: 100%;
}
.mobile-footer-nav {
  display: none;
}
.mobile-footer-nav .accordion {
  border: 0;
  border-radius: 0;
}
.mobile-footer-nav .accordion button.title {
  margin: 0;
  border: 0;
  box-shadow: none;
  background: #efefef;
  border-radius: 0 !important;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.mobile-footer-nav .accordion .accordion-item .title:after {
  content: "\f107";
  font-size: calc(18px + (25 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  margin-left: 0px;
  font-family: "FontAwesome";
  position: absolute;
  right: 20px;
  top: 12px;
  transition: 0.3s linear all;
  transform: rotate(0deg);
}
.mobile-footer-nav .accordion .accordion-item.active .title:after {
  content: "\f107";
  transform: rotate(180deg);
}
.mobile-footer-nav .accordion .accordion-item .panel {
  background-color: #fff;
  border: 0;
  border-radius: 0px;
  box-shadow: none;
}
.mobile-footer-nav .accordion .accordion-item.active .panel {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  padding: 20px;
}
.mobile-footer-nav .accordion .accordion-item .panel ul {
  padding: 0;
  list-style: none;
}
.mobile-footer-nav ul li a {
  color: #464646;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  font-family: 'caviar_dreamsbold';
  letter-spacing: 0.5px;
  margin-bottom: 3px;
}
.menu-section-mobile-inner .slick-track,
.mobile-menu-section-left .slick-track {
  border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div,
.mobile-menu-section-left .slick-track .slick-slide > div {
  display: block;
  width: 100%;
}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link,
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link {
  padding: 10px 2px;
  position: relative;
  cursor: pointer;
  color: #c3c1c1;
  font-family: 'caviar_dreamsbold';
  text-transform: capitalize;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  line-height: normal;
  display: block;
  height: 100%;
  text-align: center;
}

.menu-section-mobile-inner  .slick-track  .slick-slide  > div  .menu-title-link:before,
.mobile-menu-section-left  .slick-track  .slick-slide  > div  .menu-title-link:before {
  content: "";
  position: absolute;
  left: 51%;
  right: 51%;
  bottom: -4px;
  background: #f6aa32;
  height: 4px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.menu-section-mobile-inner  .slick-track  .slick-slide  > div  .active  .menu-title-link:before,
.mobile-menu-section-left .slick-track  .slick-slide  > div  .active  .menu-title-link:before {
  left: 0;
  right: 0;
}
.menu-section-mobile-inner  .slick-track  .slick-slide  > div  .active  .menu-title-link,
.mobile-menu-section-left  .slick-track  .slick-slide  > div  .active  .menu-title-link {
  color: #181818;
}
.menu-section-mobile-inner,
.mobile-menu-section-left {
  display: none;
}
header.small_sticky {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
}
.slick-slide .menu-list-intvl,
.product-offer-section .slick-slide {
  height: 100%;
}
.product-offer-section .slick-track .slick-slide > div {
  display: block;
  width: 100%;
}
.product-offer-section .pro-offer-item {
  padding: 15px 5px;
}
.pro-offer-box-active {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  transition: 0.2s linear all;
}
.pro-offer-box-active:hover {
  /*border-color: #f09509;*/
}
.pro-offer-icon {
  width: 53px;
}
.pro-offer-txt {
  width: calc(100% - 73px);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.pro-offer-box-active:before {
  position: absolute;
  top: 8px;
  right: 8px;
  content: "";
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background: #f7f7f7;
  border: 2px solid #e0e0e0;
}

.pro-offer-box-active:hover:before {
  background: #561c4e;
  border: 2px solid #561c4e;
}

.f-socail-btm ul li a .fa-instagram:before {
  content: "\f16d";
}

.pro-offer-txt h5 {
  margin: 0 0 5px 0;
  text-transform: inherit;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.pro-offer-txt p {
  margin: 0;
  color: #37433b;
}
.pro-offer-dot-select {
  display: none;
}

.catry-top-title h2 {
  margin: 0 0 10px 0;
  color: #39170f;
}

.catry-top-title p {
  margin: 0 auto;
  max-width: 1080px;
}

.menu-section-mobile-inner button.slick-arrow,
.products_sidebar_inner button.slick-arrow,
.mobile-menu-section-left button.slick-arrow {
  position: absolute;
  overflow: hidden;
  text-indent: -9999px;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s linear all;
}
.menu-section-mobile-inner .slick-prev,
.products_sidebar_inner .slick-prev,
.mobile-menu-section-left .slick-prev {
  left: 0;
}
.menu-section-mobile-inner .slick-next,
.products_sidebar_inner .slick-next,
.mobile-menu-section-left .slick-next {
  right: 0;
}
.menu-section-mobile-inner button.slick-arrow:before,
.products_sidebar_inner button.slick-arrow:before,
.mobile-menu-section-left button.slick-arrow:before {
  position: absolute;
  left: 0;
  right: 0;
  width: 14px;
  height: 14px;
  top: 50%;
  margin-top: -8px;
  background: url(../images/left-arrow.png) no-repeat;
  content: "";
  margin-left: 6px;
}
.menu-section-mobile-inner button.slick-arrow.slick-next:before,
.products_sidebar_inner button.slick-arrow.slick-next:before,
.mobile-menu-section-left button.slick-arrow.slick-next:before {
  transform: rotate(180deg);
  margin-top: -6px;
}

.hcart_dropdown .cart_left {
  width: calc(100% - 120px);
}
.hcart_dropdown .cart_right {
  width: 115px;
}
.product_orders_top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #302d2a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.product_orders_top h4 {
  color: #fff;
  margin: 0;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
}

span.close_product_orders {
  line-height: 1;
}

.product_card_desc {
  text-align: center;
  padding: 10px;
  font-style: italic;
}
.product_card_desc p {
  margin-bottom: 5px;
}
.package_checkitem {
  text-align: left;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.package_checkitem .custom_checkbox {
  margin: 0 0 10px 0;
  display: block;
}
.package_checkitem .custom_checkbox span {
  padding: 0 50px 0 0;
  line-height: 1.2;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  display: block;
}
.package_checkitem .custom_checkbox span:before {
  right: 0;
  left: inherit;
  border-radius: 5px;
}
.package_checkitem  .custom_checkbox  input[type="checkbox"]:checked  + span:before {
  background: url(../images/tick20-square.png) no-repeat;
}
.package_checkitem .custom_checkbox span p {
  margin: 5px 0 0 0;
}
.package_checkitem .custom_checkbox span img {
  position: absolute;
  right: 30px;
}
.input-file-sec {
  position: relative;
  margin: 10px 0 0 0;
}
.input-file-sec #upload-file {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  right: 0;
  cursor: pointer;
}
.file-name {
  display: inline-block;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 4px;
  margin: 0;
  margin-right: 10px;
}
.input-file-sec .btn {
  pointer-events: none;
  padding: 9px 20px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.product_det_cards .det_inner_content i {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}
.show_details > label.combo-input {
  display: none;
}
.det_card .pkgerror_txt {
  color: #f90000;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 5px 0;
}
.pkgerror_txt {
  color: #f90000;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 5px 0;
}

.package_details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 22px;
}

.package_details_left {
  width: 48%;
}

.package_details_right {
  width: 50%;
}
.package_details_left .slick-dots {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex !important;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  justify-content: center;
}
.package_details_left .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 11px;
  height: 11px;
  color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0 3px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #302d2a;
}
.package_details_left .slick-dots li.slick-active button {
  background: #fff;
}
.package_details_left .slick-slide img {
  max-height: 240px;
  width: 100%;
  object-fit: cover;
}
.pdr_right {
  text-align: center;
  width: 150px;
}
.pdr_right-inner {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.package_details_right_inner {
  display: flex;
  justify-content: space-between;
}

.pdr_left {
  width: calc(100% - 160px);
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}
.pdr_left h4 {
  margin-bottom: 15px;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
}

.pdr_right .qty_bx {
  width: 120px;
  height: 38px;
  padding: 0 30px;
}
.pdr_right .product-min-pax {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin: 8px 0 12px;
}
.pdr_right h5 {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 10px;
}
.pdr_right .proqty_input {
  height: 100%;
  line-height: 36px;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  border-right: 2px solid #e0e0e0;
  width: 100%;
  display: block;
  border-left: 2px solid #e0e0e0;
}
.pdr_right .qty_bx span {
  width: 30px;
}
.pdr_right h4 {
  margin-bottom: 15px;
}
.pdr_right h4 sup {
  top: -4px;
  margin-right: 2px;
  font-size: 90%;
}
.fix-only-lhs {
  display: none;
}
.fix-only-lhs h3 {
  margin-bottom: 15px;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
}
.pdr_right.price-fixed {
  position: fixed;
  background: #fff;
  padding: 10px;
  z-index: 9;
  top: 80px;
  box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07);
  width: auto;
  display: flex;
  justify-content: space-between;
 /* border: 3px solid #6e1d24;*/
  border-top: 0;
  margin: 0;
}
.pdr_right.price-fixed .fix-only-lhs {
  display: block;
  padding-right: 30px;
  text-align: left;
}
.pdr_right.price-fixed .flavours,
.pdr_right.price-fixed h5 {
  display: none;
}
.pdr_right.price-fixed .product-min-pax {
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  margin: 2px 0 0px;
}
.pdr_right.price-fixed h4 {
  margin-bottom: 5px;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
}
.pdr_right.price-fixed .qty_bx {
  width: 110px;
  height: 30px;
}
.pdr_right.price-fixed .proqty_input {
  line-height: 26px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.additionals {
  padding-top: 10px;
}
.instruction h5 {
  margin-bottom: 10px;
  text-transform: inherit;
}
.product_details_popup a.button.add_to_cart {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin: 10px 0 0 0;
  padding: 15px 24px 15px;
  letter-spacing: 0.5px;
}

.det_inner_content .show_details {
  background: #282828;
  margin: 0 auto;
  min-width: 128px;
  padding: 12px 6px 12px;
}
.det_inner_content .prd_close {
  /*color: #6e1d24;*/
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}
.det_inner_content .prd_close span {
  text-decoration: underline;
}
.det_inner_content .product_desc {
  color: #353535;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 20px 0;
  line-height: 1.3;
  flex: 1 1 auto;
}
.det_inner {
  height: 100%;
}
.modifier-btn-grp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modifier-btn-grp .button {
  width: 49%;
  padding: 12px 5px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 5px 0;
}
.modifier-btn-grp .mdf-done.button {
  background: #561c4e;
}
.custom_logo_banner textarea.form-control {
  height: 80px;
  border-radius: 5px;
  margin-bottom: 0px;
  padding: 10px;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}
.custom_logo_banner .input_desc {
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
}
.event_timing .tooltip_link {
  width: 21px;
  height: 19px;
}
.order_card {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}
.event_timing {
  border-bottom: 1px solid #e4e4e4;
  padding: 15px 15px 10px 15px;
}
.event_timing .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.event_timing .form-group .input_field {
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.event_timing .form-control {
  margin-bottom: 0;
  height: 40px;
  border-radius: 4px;
  padding: 6px 12px;
}
.event_timing .form-control.required {
  border-color: #e4000d;
}
.event_timing .custom_select:after {
  width: 30px;
  height: 38px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.event_timing .form-group .input_field .custom_select {
  width: calc(100% - 110px);
}
.event_timing label {
  color: #353535;
}
.det_inner_image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.package_pdfico a {
  /*color: #6e1d24;*/
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}

.package_pdfico a i {
  margin: 0 5px 0 0;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.file_details {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 5px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file_details a {
  width: 10px;
  height: 10px;
  display: inline-flex;
  margin-left: 5px;
}
.file_details a img {
  width: 10px;
}
.products_cart {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
}
.products_cart img {
  margin-bottom: 10px;
}
.order_card.light {
  background: #f9f9f9;
}
.order_card.light .order_card_head {
  background: #dcdcdc;
}
.order_card.light .order_card_head h5 {
  color: #464646;
}
.order_tooltip {
  padding: 20px 12px 20px;
  width: 230px;
  letter-spacing: 0.3px;
}
.order_tooltip p:first-of-type {
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}
.det_inner_content {
  border: 2px solid #fff;
  border-top: 0;
}
.selected .det_inner_content {
  /*border-color: #f09509;*/
}
.downalod-quote {
  text-align: center;
  padding: 15px 0 0 0;
}
.downalod-quote a {
  font-family: 'caviar_dreamsbold';
  text-decoration: underline;
  color: #464646;
  text-transform: uppercase;
}
.products_info_item .cart_left h4 {
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0 0 2px;
  color: #161616;
  position: relative;
  padding-left: 20px;
}
.receipt_cateringpopup .pouup_in .products_info_item .cart_left h4 {
  padding-left: 0;
}
.receipt_cateringpopup .pouup_in .cart_indvl_price {
  display: block;
  clear: both;
}
.receipt_cateringpopup .pouup_in .pkg-list-lhs > div {
  padding-right: 0;
}

.products_info_item .cart_left h4 a {
  position: absolute;
  left: 0;
  top: 0px;
  color: #161616;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.products_info_options p {
  margin: 0 0 2px 0;
  color: #959595;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  padding-left: 10px;
  font-style: italic;
}
.products_info_options span b {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: block;
  padding: 10px 0 0 0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
}
.product-details.products_info_item {
  padding-top: 15px;
}
.cart_indvl_price {
  margin: 0;
  color: #161616;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}
.cart_indvl_price sup {
  top: 0;
}
.cart_right .cart-counter .product_quantity {
  height: 100%;
  line-height: 27px;
  border-right: 2px solid #e0e0e0;
  width: 100%;
  display: block;
  border-left: 2px solid #e0e0e0;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  font-weight: normal;
  text-align: center;
  background: #fff;
}
.cartaction_checkout {
  text-align: center;
  background: #561c4e;
  color: #fff;
}
.cartaction_checkout:hover {
  background: #fdd18c;
  color: #000;
}
.downalod-quote a:hover {
  /*color: #6e1d24;*/
}
.products_total sup {
  top: -1px;
  margin-right: 2px;
}
.add-breaktime {
  font-family: 'caviar_dreamsbold';
  text-decoration: underline;
  color: #464646;
  text-transform: capitalize;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  margin: 0 auto;
  text-align: center;
}
.product_container {
  font-family: 'Montserrat', sans-serif;
}
.additionals ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cart-settup-list,
.products_info_item {
  padding: 5px 15px;
}
.cart-settup-list ul {
  list-style: none;
  padding: 0;
  display: block;
}
.cart-settup-list ul li {
  padding: 5px 0px 10px 0;
}
.cart-settup-list ul li:last-child {
  padding-bottom: 10px;
}
.pkgslist_addition {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #959595;
}
.pkgslist_addition p {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  color: #959595;
  margin: 0 0 1px 0;
}
.pkgslist_addition p:last-of-type {
  margin-bottom: 0;
}

.pkg-list-lhs {
  display: flex;
  justify-content: space-between;
}
.pkg-list-lhs label {
  color: #161616;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 1;
}
.pkg-list-lhs > div {
  position: relative;
  padding-right: 20px;
  line-height: 1;
}
.cart-package-info:nth-child(even) {
  background: #f8f8f8;
  border-top: 1px solid #e4e4e4;
}
.pkg-list-lhs > div a.remove {
  top: 1px;
  right: 0;
}
.pkg-list-lhs > div .price sup {
  top: -1px;
}
.pkg-list-lhs > div .price {
  color: #161616;
  font-family: 'caviar_dreamsbold';
}
.instruction .spl_instruction {
  height: 100px;
  border-radius: 5px;
}
.additionals h5 {
  text-transform: capitalize;
}
.product_acc_grp {
  margin-bottom: 30px;
}
ul.additionals-deliverylist {
  padding: 15px 0 10px 30px;
}
.additionals_setuplist > li {
  padding-bottom: 15px;
}
.additionals_setuplist > li .custom_radio span {
  font-weight: 600;
}
ul.additionals-deliverylist li {
  margin-bottom: 15px;
}
.addon_checklist li {
  margin-bottom: 10px;
}
ul.additionals-deliverylist li:last-child,
.addon_checklist li:last-child {
  margin-bottom: 0;
}
ul.additionals-deliverylist.equipment_listing_div {
  padding: 0px 0 10px 0px;
}
ul.additionals-deliverylist li .custom_checkbox span {
  line-height: 1.4;
}
ul.additionals-deliverylist li .custom_checkbox span:before,
.addon_checklist li .custom_checkbox span:before {
  border-radius: 3px;
  width: 16px;
  height: 16px;
  top: 4px;
}
ul.additionals-deliverylist  li  .custom_checkbox  input[type="checkbox"]:checked  + span:before,
.addon_checklist  li  .custom_checkbox  input[type="checkbox"]:checked  + span:before {
  background: url(../images/tick-16.png) no-repeat;
}
.addon_popup-catering {
  max-width: 780px;
}
.addon_popup-catering .addon_linfo h4 {
  text-transform: capitalize;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 10px;
}
.addon_poptop_header h3 {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
}
.additionals_equipmentlist .qty_val,
.addon-lright-parent .addon_qty_lable,
.combo_qtycount .qty_val {
  height: 100%;
  line-height: 28px;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  border-right: 1px solid #e0e0e0;
  width: 100%;
  display: block;
  border-left: 1px solid #e0e0e0;
  text-align: center;
}
.additionals_equipmentlist .counter {
  margin-bottom: 5px;
}
ul.additionals_equipmentlist li {
  margin-bottom: 20px;
}
.addon_poptop_header h3 small {
  font-size: 80%;
  text-transform: capitalize;
  margin-left: 10px;
  font-family: 'caviar_dreamsregular';
  color: #4e4e4e;
}
.addon_poptop_header h3 small {
  font-size: 80%;
  text-transform: capitalize;
  margin-left: 10px;
  font-family: 'caviar_dreamsregular';
  color: #4e4e4e;
}

.addon_listrw {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.addon_linfo {
  width: calc(100% - 160px);
}

.addon-lright-parent {
  width: 150px;
}
.addon_price {
  font-family: 'caviar_dreamsbold';
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.addon-lright-parent .btn {
  padding: 10px 12px;
  line-height: 1;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin: 5px 0 0 0;
  background: #282828;
}
.addon-lright-parent .btn:hover {
  color: #fff;
  background: #f6aa32;
}
.addon_lright {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
}
.addon_popbot {
  text-align: right;
}

.addon_popbot .button {
  margin: 10px 0 0 10px;
}
.act-remove-topdiv .delete-act-icon {
  color: #000;
}
.address_linfo {
  margin-bottom: 10px;
}
.catering-checkout-total .order_det {
  padding: 0 15px;
}
.catering-checkout-total .cart_footer {
  padding: 10px 15px 0 15px;
}
.btn_big {
  display: block;
  padding: 12px 10px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.cdd-details-rhs .event_timing .form-group .input_field .custom_select {
  width: calc(100% - 220px);
}
.cdd-details-rhs .products_info_item .cart_left h4 {
  padding-left: 0;
}
.cdd-details-rhs .event_timing {
  padding-bottom: 5px;
  border-bottom: 0;
}
.catering-checkout-total .cdd-details-rhs {
  font-family: 'Montserrat', sans-serif;
}
.no-recrds-found.loader-main-cls {
  min-height: 250px;
}
.modal.fade {
  opacity: 1;
}

.hcart_dropdown .pkgslist_additionrow {
  margin-bottom: 15px;
}
.hcart_dropdown .pkgslist_additionrow:last-child {
  margin-bottom: 0;
}
.hcart_dropdown .cart-settup-list ul li:last-child {
  padding-bottom: 5px;
}
.hcart_dropdown .cart-settup-list ul li label {
  color: #161616;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 1;
}

.popup_product_details {
  width: 100%;
  max-width: 1080px;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
select option:disabled {
  color: rgba(0, 0, 0, 0.4);
}
.cdd-details-rhs .pkgslist_additionrow {
  padding-bottom: 10px;
}
.cdd-details-rhs .pkgslist_additionrow:last-child {
  padding-bottom: 0;
}
.cart-settup-list ul li > label {
  padding-bottom: 5px;
  color: #161616;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}
.slick-slider .products_sidebar_content a {
  padding: 10px 15px;
}
.products_sidebar_inner .slick-slider {
  display: none;
}
.additionals .instruction {
  padding-top: 20px;
}
.product-edit-half {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-lhs .product-edit-half a {
  width: 49%;
  margin: 10px 0 0 0 !important;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));
  padding: 13px 10px 13px !important;
  letter-spacing: 0.5px;
}
.mobile-choose-option-hover {
  display: none;
}
.mobile-choose-option-hover > ul {
  padding: 0;
  list-style: none;
}
.mobile-choose-option-hover ul li ul {
  background: #ececec;
  list-style: none;
  padding: 10px 0;
  display: none;
}
.mobile-choose-option-hover ul li:hover ul {
  display: block;
}
.mobile-choose-option-hover > ul > li > a {
  padding: 5px 5px;
  position: relative;
  text-align: center;
  font-family: 'caviar_dreamsbold';
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #561c4e;
}
.mobile-choose-option-hover > ul > li:last-child > a {
  color: #979797;
  background: #292929;
}
.mobile-choose-option-hover > ul > li > a img {
  margin-right: 5px;
}

.mobile-choose-option-hover ul li ul li a {
  font-family: 'caviar_dreamsbold';
  color: #000;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  display: block;
  padding: 4px 10px;
}
.mobile-choose-option-hover ul li ul li a:hover {
  /*color: #f09509;*/
}

.product-lhs .product-offer-section .slick-slider .slick-dots {
  text-align: center;
  padding: 0;
}
.product-lhs .product-offer-section .slick-slider .slick-dots li {
  display: inline-block;
  padding-right: 8px;
}

.product-lhs .product-offer-section .slick-slider .slick-dots li button {
  width: 12px;
  height: 12px;
  line-height: 12px;
  font-size: 0;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  margin: 5px 0 0;
  border: 0;
}

.product-lhs  .product-offer-section  .slick-slider  .slick-dots  li.slick-active  button {
  color: #fff;
  background: #fdd18c; 
}
.hide-cel-cls {
  display: none;
}
.load-more-btndiv {
  text-align: center;
  padding: 20px 0 0 0;
}

.load-more-btndiv .button {
  margin: 0;
  border: 2px solid #561c4e;
  color: #561c4e;
  background: #fff;
}

.load-more-btndiv .button:hover {
  color: #fff;
  background: #561c4e;
  border: 2px solid #561c4e;
}

.product-info-div .products-ordernow-action .qty_bx {
  margin-right: 3px;
}

.product-menu-listing .modal-full-xl {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec  .inn-product-img-bg
  .slick-slider  .slick-dots {
  display: flex !important;
  flex-flow: wrap;
  justify-content: flex-start;
  flex-direction: row;
  padding: inherit;
}

.modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec  .inn-product-img-bg
  .slick-slider  .slick-dots  li:first-child {
    padding: 10px 0;
}

.modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec  .inn-product-img-bg
  .slick-slider .slick-dots  li {
  position: relative;
  padding: 10px 0 10px 10px;
  cursor: pointer;
  max-width: 143px;
  list-style: none;
}

.modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec  .inn-product-img-bg
  .slick-slider  .slick-dots  li  img {
  display: block;
  max-width: 100%;
  height: 145px;
  /*border: 2px solid #49190d;*/
}

.modal-full-xl .modal-content .modal-body .modal-detail-box .inn-product-popup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.modal-full-xl .modal-content .modal-body .modal-detail-box {
  position: relative;
  max-width: 100%;
}

.modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec {
  position: relative;
  display: inline-block;
  flex: 1 1 50%;
  max-width: 592px;
  padding: 10px;
}

.modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-img-bg  img {
  width: 100%;
  border: 2px solid #caa865;
}

.modal-full-xl  .modal-content .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec  .inn-product-img-bg
  .slick-initialized  .slick-list  .slick-track  .slick-slide {
  display: block !important;
}

.prodetailinner-main-div .modal-full-xl .modal-content .modal-body {
  position: relative;
  padding: 35px;
}

.tab-content  .product_chosen_item_left  .compo_mdfselect_maindiv  .custom-radio-btncls  .components_selct  .custom_radio {
  padding: 10px 30px 10px 0;
  display: block;
}

.tab-content  .product_chosen_col_inner  .product_chosen_item_left  .compo_minmax_maindiv  .chosen_adn  .combo-inc-parent
  .qty_bx
  .combo-input-label {
  height: 100%;
  border: none;
  color: #060203;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  margin: 0;
  padding: 2px;
  border-radius: 5px;
  font-weight: bold;
  line-height: 2.5;
}

.inn_product_row .prd_chosen_row .product_chosen_inner .tab-content {
  width: 100%;
}

.tab-content .tab-pane .product_chosen_col .text-box em {
  margin-bottom: 10px;
}

.product-lhs .prodetailinner-main-div  .modal-full-xl  .modal-content  .modal-body {
  box-shadow: 0 1px 21px 0 rgb(0 0 0 / 18%);
  padding: 35px 0 0;
}

.inn_product_row .prd_chosen_row .product_chosen_inner .nav {
  border-bottom: 4px solid #e9e9e9;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 3;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.tab-content  .product_chosen_item_left  .compo_minmax_maindiv  .chosen_adn  .pull-right  .qty_bx  .qty_minus {
  box-shadow: 1px -1px 8px rgb(0 0 0 / 19%);
  border: 2px solid #000;
}

.tab-content  .product_chosen_item_left  .compo_minmax_maindiv  .chosen_adn  .pull-right  .qty_bx  .qty_plus {
  box-shadow: 1px -1px 8px rgb(0 0 0 / 19%);
  border: 2px solid #000;
  background-color: #000;
}

.tab-content  .product_chosen_item_left  .compo_minmax_maindiv  .chosen_adn  .pull-right  .qty_bx  .qty_plus:before,
.tab-content  .product_chosen_item_left  .compo_minmax_maindiv  .chosen_adn  .pull-right  .qty_bx  .qty_plus:after {
  background: #ffffff;
}

.productdetail-rightside-maindiv {
  width: 50%;
  float: right;
}

.inn-product-popup .inn_product_row .prd_chosen_sub_row {
  position: relative;
  text-align: inherit;
  margin-top: 10px;
  padding: 25px;
  border-top: 1px solid #e3e3e3;
}

.modal-content  .modal-detail-box  .inn-product-popup  .productdetail-rightside-maindiv  .inn_product_row  .prd_chosen_sub_row
  .prd_chosen_sub_col  .prd_chosen_sub_item_left {
  display: inline-block;
  width: inherit;
  margin: 0;
  margin-left: 0;
}

.inn-product-popup  .productdetail-rightside-maindiv  .prodet_baninfo  .inn_product_hea {
  text-align: left;
  font-family: 'Montserrat', sans-serif;
}

.inn-product-popup  .productdetail-rightside-maindiv  .prd_chosen_sub_row  .prd_chosen_sub_col  
.cart_update_div.compo_update_div {
  width: 260px;
}

.inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row p {
  position: absolute;
  top: 45px;
  left: 50px;
  margin-bottom: 0;
  font-family: 'caviar_dreamsbold';
}


.inn-product-popup  .productdetail-rightside-maindiv  .prd_chosen_sub_row  .prd_chosen_sub_col {
  /*margin-left: 140px;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.inn_product_row .prd_chosen_row .product_chosen_inner .nav li a {
  padding: 10px 2px;
  position: relative;
  cursor: pointer;
  color: #0a0b10;
  text-transform: capitalize;
  font-size: calc(11px + (17 - 11) * ((100vw - 300px) / (1600 - 300)));
  line-height: normal;
  display: block;
  height: 100%;
  border-radius: 0;
  border: 0;
  margin-right: 0;
}

.inn_product_row .prd_chosen_row .product_chosen_inner .nav li a {
  background: transparent;
}

.inn_product_row .prd_chosen_row .product_chosen_inner .nav li {
  width: 50%;
}

.inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right
  button {
  display: block;
  padding: 17px 22px 13px;
  color: #fff;
  /*background: #49190d;*/
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  letter-spacing: 0.5px;
  margin: 0;
  line-height: 1;
  border-radius: 5px;
  max-width: 180px;
  margin: 0 auto;
  margin-right: 0;
}

.inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .prd_chosen_sub_col .prd_chosen_sub_item_right
  button:hover {
  color: #000;
  background: #561c4e;
  color: #fff;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain  .addon-products-single-li
  .product-info-div  .products-ordernow-action  .button:hover {
  color: #fff;
  /*background: #49190d;*/
}

.inn_product_row .prd_chosen_row .product_chosen_inner .nav li a:before {
  content: "";
  position: absolute;
  left: 51%;
  right: 51%;
  bottom: -4px;
  background: #000;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.inn_product_row .prd_chosen_row .product_chosen_inner .nav li.active a:before {
  left: 0;
  right: 0;
}

.inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col  .addcart_row  .qty_bx  .qty_plus {
  box-shadow: 1px -1px 8px rgb(240 149 8 / 24%);
  border: 2px solid #d68407;
  background: #dfc288;
  color: #fff;
}

.inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col  .addcart_row  .qty_bx  .qty_minus {
  box-shadow: 1px -1px 8px rgb(0 0 0 / 19%);
  border: 2px solid #d68407;
  background: #dfc288;
  color: #fff;
}

.inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col  .addcart_row  .qty_bx  .qty_plus:after {
  background: #fff;
}

.inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col  .addcart_row  .qty_bx  .qty_minus:before {
  background: #fff;
}

.inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col  .addcart_row  .qty_bx  .qty_plus:before {
  background: #fff;
}

.inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col  .addcart_row  .qty_bx  input {
  height: 100%;
  color: #000;
  text-align: center;
  margin: 0;
  padding: 2px;
  background: #fff;
  border-radius: 0;
  font-weight: 700;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  border-top: none;
  border-bottom: none;
}

.inn-product-popup  .productdetail-rightside-maindiv  .inn_product_row  .prd_chosen_sub_row
  p
  span {
  font-family: 'caviar_dreamsbold';
  font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.inn-product-popup  .productdetail-rightside-maindiv  .inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col
  .addcart_row {
  padding: 0 10px;
}

.tab-content  .product_chosen_col_inner  .compo_acc_innerdiv  .custom-radio-btncls  .components_selct  .custom_radio
  span:before {
  right: 0;
  left: 100%;
}

.tab-content  .product_chosen_col_inner  .compo_acc_innerdiv  .custom-radio-btncls  .components_selct  .custom_radio
  span {
  padding: 0;
}

.tab-content .product_chosen_col .product_chosen_item_left .compo_acc_innerdiv {
  padding: 10px 0px 0px 20px;
}

.inn-product-popup  .product-detail-topmaindiv  .productdetail-rightside-maindiv  .inn_product_row  .prd_chosen_row
  .product_chosen_inner  .nav.nav-tabs.text-center  li  a  span {
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
}

.inn-product-popup  .product-detail-topmaindiv  .productdetail-rightside-maindiv  .inn_product_hea  .product-tags-list  ul  li {
  margin: 0 3px 3px 3px;
  background: #3cac5e;
  line-height: 1;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  padding: 5px 13px 3px;
  border-radius: 15px;
  color: #fff;
}

.inn-product-popup  .product-detail-topmaindiv  .productdetail-rightside-maindiv  .inn_product_hea  .inn_product_review
  .inn_product_hea_left  h3 {
  text-align: left;
  text-transform: capitalize;
}

.inn-product-popup  .product-detail-topmaindiv  .productdetail-rightside-maindiv  .inn_product_hea  ul {
  justify-content: left;
}

.tab-content  .product_chosen_item_left  .product_chosen_addons  .compopro_acc_head::before,
.tab-content  .product_chosen_item_left  .product_chosen_addons  .compopro_acc_head::after {
  background: #a8a8a8;
  width: 20px;
}

.tab-content .product_chosen_col .product_chosen_col_inner {
  margin-bottom: 0;
}

.inn-product-popup  .productdetail-rightside-maindiv  .inn_product_row  .prd_chosen_row {
  padding: 30px 30px 0px 0px;
}

.inn-product-popup .addon_rewive_product_topmaindiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 35px 30px;
  background: #fcf8f2;
  overflow: hidden;
  width: 100%;
}

.inn-product-popup .addon_rewive_product_topmaindiv .addon-prolist-inner {
  overflow: hidden;
  width: 100%;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  padding: 0 15px;
  height: 270px;
  overflow: auto;
  overflow-x: auto;
  background-color: #fff;
  box-shadow: 0px 5px 8px #dddd;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .products-reviews-maindiv  .productreviews-list-ulmain {
  list-style: none;
  padding: 10px 15px 0 15px;
  /*overflow-y: scroll;*/
  height: 270px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0px 5px 8px #dddd;
  /*border-left: 4px solid #49190d;
  border-right: 4px solid #49190d;*/
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li {
  width: 31%;
  background: #fff;
  padding: 0;
  padding-right: 20px;
  margin-top: 15px;
}


.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .products-image-div {
  height: 110px;
}
.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-topdiv.addon-prolist-fulwdth {
  width: 100% !important;
}

.commom-modal-topcls  .inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-topdiv {
  width: 50%;
  padding-right: 50px;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addonproinner-topdiv  .addonpro-title-toptext {
  padding-bottom: 30px;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addonproinner-topdiv  .addonpro-title-toptext  .text-uppercase  h3 {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div {
  text-align: center;
  padding: 15px 0 12px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 143px;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain  .addon-products-single-li
  .product-info-div  .product-title-maindiv {
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .product-title-maindiv  .product-title  h3 {
  margin-bottom: 10px;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div .products-ordernow-action {
  margin: 0 auto;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .product-info-div  .product-price {
  margin-bottom: 10px;
  flex: 1 1 auto;
}

.commom-modal-topcls  .inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .products-ordernow-action  .button {
  display: block;
  padding: 12px 8px;
  color: #000;
  /*background: #f09509;*/
  text-transform: uppercase;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  letter-spacing: 0.5px;
  line-height: 1;
  border-radius: 5px;
  width: 100px;
}

.commom-modal-topcls  .inn-product-popup  .addon_rewive_product_topmaindiv .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .products-ordernow-action  .addcart_row  .btn {
  display: block;
  padding: 12px 8px;
  color: #000;
  /*background: #f09509;*/
  text-transform: uppercase;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  letter-spacing: 0.5px;
  line-height: 1;
  border-radius: 5px;
  width: 100px;
}
.inn-product-popup .addon_rewive_product_topmaindiv .products-reviews-maindiv {
  width: 50%;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .products-reviews-maindiv  .reviewspro-title-toptext {
  padding-bottom: 30px;
}

.inn-product-popup .addon_rewive_product_topmaindiv .products-reviews-maindiv .reviewspro-title-toptext .text-uppercase h3 {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
}

.inn-product-popup  .addon_rewive_product_topmaindiv .products-reviews-maindiv .productreviews-list-ulmain
 .product-reviews-list-li .product-reviews {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .products-reviews-maindiv  .productreviews-list-ulmain
  .product-reviews-list-li  .product-reviews  .product-reviews-info {
  padding-left: 15px;
}

.inn-product-popup  .addon_rewive_product_topmaindiv .products-reviews-maindiv .productreviews-list-ulmain
  .product-reviews-list-li  .product-reviews .product-reviews-info  h4 {
  margin-bottom: 8px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .product-price  h3 {
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.commom-modal-topcls  .modal-detail-box  .inn-product-popup  .productdetail-rightside-maindiv
  .prd_chosen_sub_row  p.prt-sct {
  top: 3px;
  color: #535353;
  left: 195px;
  margin-top: 10px;
}

.mainacc_menucontent  .tab-pane  .tab_mobrow  .account_sec  .accsec_right  .cart_header_title  p {
  text-align: center;
  margin-bottom: 0;
}

.mainacc_menucontent  .tab-pane  .tab_mobrow  .account_sec  .accsec_right  .cart_header_title {
  padding: 0;
}

.web_order_typecls li.active .count_num_t,
.web_order_typecls li:hover .count_num_t {
  /*background: #49190d;*/
}

.accsec_right  .order-tab-section  .mainacc_mobrow  .tab_sec  .web_order_typecls  .orderTypeli  .count_num_t {
  background: #501f4a;
  width: 70px;
  height: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  color: #f1f0f0;
  font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1600 - 300)));
  justify-content: center;
  align-items: center;
  border-radius: 0;
  margin-top: 0;
}

.mfp-wrap .mfp-content .popup-body .form_sec .form-group .login_pop_sub button {
  width: 100%;
}

.commom-modal-topcls  .inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .products-ordernow-action  .addcart_row  .qty_bx {
  margin-bottom: 3px;
  float: none;
  height: 35px;
}
.commom-modal-topcls  .inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner
  .addonproducts-list-ulmain  .addon-products-single-li  .product-info-div  .products-ordernow-action  .addcart_row  .btn {
  min-width: 111px;
}

.commom-modal-topcls  .inn-product-popup  .addon_rewive_product_topmaindiv  .addon-prolist-inner  .addonproducts-list-ulmain
  .addon-products-single-li  .product-info-div  .products-ordernow-action  .addcart_row {
  padding: 0;
}

.modal-dialog-tgrfromhome .modal-full-xl .modal-content .modal-body {
  padding: 0;
}

.modal-dialog-tgrfromhome  .modal-full-xl  .modal-detail-box  .inn-product-popup  .product-detail-topmaindiv {
  padding: 0;
}

.modal-dialog-tgrfromhome  .modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec {
  padding: 0;
  display: inherit;
  flex: inherit;
  max-width: 100%;
  width: 100%;
  height: 480px;
  overflow: hidden;
}

.modal-dialog-tgrfromhome  .modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup
  .prodet_bansec  .inn-product-img-bg {
  height: 480px;
}

.modal-dialog-tgrfromhome  .modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup
  .product-detail-topmaindiv {
  width: 100%;
}

.modal-dialog-tgrfromhome  .modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup
  .prodet_bansec  .inn-product-img-bg  img {
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: cover;
}

.modal-dialog-tgrfromhome .modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup
  .productdetail-rightside-maindiv  .prd_chosen_sub_row  .prd_chosen_sub_col  .prd_chosen_sub_item_right {
  width: 100%;
}

.modal-dialog-tgrfromhome .modal-full-xl  .modal-content  .modal-body  .modal-detail-box  .inn-product-img-bg
  img {
  border: 0;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .product-detail-topmaindiv .productdetail-rightside-maindiv {
  width: 100%;
  float: none;
  padding: 30px 30px 0;
  position: relative;
  text-align: center;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .productdetail-rightside-maindiv  .prodet_baninfo
  .inn_product_hea {
  text-align: center;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .product-detail-topmaindiv  .productdetail-rightside-maindiv
  .inn_product_hea  .inn_product_review  .inn_product_hea_left  h3 {
  text-align: center;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .product-detail-topmaindiv
  .productdetail-rightside-maindiv  .inn_product_hea  ul {
  justify-content: center;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .addon_rewive_product_topmaindiv {
  width: 100%;
  display: none;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .productdetail-rightside-maindiv  .prd_chosen_sub_row  p {
  position: inherit;
  top: 0;
  left: 0;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .inn_product_row  .prd_chosen_sub_row {
  position: relative;
  text-align: inherit;
  margin-top: 10px;
  padding: 25px;
  border-top: 1px solid #e3e3e3;
  display: inherit;
  justify-content: center;
  align-items: inherit;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .inn_product_row  .prd_chosen_sub_row  .prd_chosen_sub_col {
  display: inherit;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.modal-dialog-tgrfromhome .modal-detail-box .inn-product-popup .inn_product_row .prd_chosen_sub_row .addcart_row .qty_bx {
  float: none;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn-product-popup  .productdetail-rightside-maindiv  .inn_product_row
  .prd_chosen_sub_row  .prd_chosen_sub_col  .prd_chosen_sub_item_left {
  margin-bottom: 10px;
}

header .hcart_dropdown.open {
  display: block;
  -webkit-transform: translateX(0);
  transform: translateX(0) !important;
}

header .hcart_dropdown,
header.small_sticky .hcart_dropdown {
  background: #fff;
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  z-index: 11;
  max-width: 720px;
  padding: 45px 0 50px;
}

.product_orders_top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.product_orders_top h4 {
  color: #000;
  margin: 0;
  font-size: calc(17px + (21 - 17) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
}

span.close_product_orders {
  line-height: 1;
  position: absolute;
  right: 10px;
  opacity: 0;
}

span.close_product_orders img {
  -webkit-filter: invert(1);
  filter: invert(1);
  width: 15px;
}


header .hcart_dropdown .hcart_scrollarea .cart_body { height: 100%; display: flex; flex-direction: column; }

.hmenu-login-act .del_address {
  margin-bottom: 0;
}

.hcart_dropdown .hcart_row_head {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.hcart_dropdown .hcart_row_head .hcart_box {
  position: relative;
  flex: 1 1 50%;
  min-width: 200px;
  max-width: 50%;
}

.hcart_dropdown .hcart_row_head .hcart_box h5 {
  margin-bottom: 5px;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  letter-spacing: 0.1px;
}

.del_address p {
  margin: inherit;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
}

.hcart_dropdown .hcart_row_head .hcart_add_loca {
  position: relative;
  text-align: right;
}

.hcart_dropdown .hcart_row_head .hcart_add_loca .hcart_add_innr_loca {
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-end;
}

.hcart_dropdown .hcart_row_head .hcart_add_loca .hcart_add_innr_loca p {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.hcart_dropdown  .hcart_row_head  .hcart_add_loca  .hcart_add_innr_loca  .form-group {
  margin: 0;
  padding: 5px 0 0 10px;
  line-height: 0;
  vertical-align: top;
  font-size: inherit;
  width: 25%;
}

.hcart_dropdown .hcart_row_head  .hcart_add_loca  .hcart_add_innr_loca  .form-control {
  display: inline-block;
  text-align: center;
  padding: 0;
  font-weight: 700;
  margin: 0;
  height: 33px;
  width: 100%;
}

.hcart_dropdown .hcart_row_head .hcart_add_loca p span {
  display: block;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  text-decoration: underline;
}

.hcart_dropdown .hcart_row_head .hcart_add_loca p span a {
  color: #000;
}

.hacrt_sec_title {
  padding-top: 20px;
}

.hcart_scrollarea .timeslot_info_main {
  width: 100%;
}

.hcart_scrollarea .hcart_row_head .syd_date {
  display: flex;
}

.hmenu-login-act .syd_merge {
  justify-content: space-around;
  grid-gap: 0;
  gap: 0;
}

.syd_merge {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hmenu-login-act .syd_merge .syd_date,
.hmenu-login-act .syd_merge .syd_time.delivery_submit_cls {
  flex: inherit;
  max-width: inherit;
  min-width: inherit;
  width: 49%;
}

.hcart_scrollarea .timeslot_info_main .syd_date {
  display: flex;
}

.hmenu-login-act .syd_merge .form-group222 {
  position: inherit;
}

.hcart_scrollarea .syd_merge .form-group222 {
  position: inherit;
  /*width: 55%;*/
}

.hcart_scrollarea .timeslot_info_main .syd_time.delivery_submit_cls {
  display: flex;
  justify-content: flex-end;
}

.order-details-with-clear h5 {
  color: #fff;
  margin: 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.3px;
  font-weight: 700;
}

.overall-parent .product-details {
  border-bottom: 1px solid #e6e6e6;
}

.cart_remove {
  background: #b0b0b0;
  position: absolute;
  top: 22px;
  right: 15px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .hcart_dropdown .hcart_scrollarea .cart_body .cart_footer {
  position: relative;
  width: 100%;
  bottom: 0;
  background: #fff;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

header .hcart_dropdown .cart_footer .cart_footer_inner_item {
  width: 100%;
}

header .hcart_dropdown .cart_footer .cart_footer_back {
  background: #f8f8f8;
  padding-bottom: 15px;
}

header .hcart_dropdown .cart_footer .cart_footer_back .cart_footer_itme_pack {
  padding: 20px 20px 5px;
}

.cart_footer .cart_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.cart_footer .cart_row p {
  margin: 0;
  font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  font-weight: 700;
  color: #000;
}

.hmenu-login-act .syd_merge .form-group222 .react-datepicker-wrapper {
  width: 100%;
  display: flex;
}

.cart_row.grant-total-cls {
  padding-top: 10px;
  margin-bottom: 20px;
}

header .hcart_dropdown .cart_footer .cart_itme_free:empty,
header .hcart_dropdown .cart_footer .cart_itme_per:empty {
  padding: 0;
}

header .hcart_dropdown .cart_footer .cart_itme_free {
  padding-top: 5px;
}



.cartaction_bottom .btn {
  margin: auto;
  width: 100%;
  text-align: center;
  display: block;
  /*background: #f09509;*/
  padding: 10px;
  border-radius: 5px;
  height: auto;
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0.5px;
  color: #fff;
   font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.cartaction_checkout,
.cartaction_checkout:hover {
  background: #561c4e;
  color: #fff;
}

.cart_into_txt_cnt .cart_extrainfo,
.cart_into_txt_cnt p {
  margin: 0;
  color: #959595;
  font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.promo-popup-image {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.promo-popup-image img {
  width: 100%;
  object-fit: contain;
  max-height: 250px;
}

.mCustomScrollbar_new .mCustomScrollBox .mCSB_container {
  display: flex;
  height: 270px;
  overflow-x: auto;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
}

.cart_row.promo-cart-row {
  position: relative;
  padding-right: 15px;
}

.checkout-main-div .alert_fixed a {
  top: 5px;
}

.checkout-total .cdd-details-lhs.fl .checkout-control-group-top { padding: 0 0 10px; }
.cdd-details-lhs.fl .checkout-body-section .checkout-billing-address { padding: 0 0 20px;}

.compo_top_div  .tab-content  .common_compo_div  .compo_inner_main  .compo_acc_innerdiv  .custom-select-bxcls
  .components_selctbox_cls  .custom_radio {
  padding: 10px 30px 10px 0;
  display: block;
}

.custom_radio span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #d0cfcb;
  left: 0;
  top: 1px;
  pointer-events: none;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.compo_top_div  .tab-content  .common_compo_div  .compo_inner_main  .compo_acc_innerdiv  .custom-select-bxcls
  .components_selctbox_cls  .custom_radio  span {
  padding: 0;
}

.compo_top_div  .tab-content  .common_compo_div  .compo_inner_main  .compo_acc_innerdiv  .custom-select-bxcls
  .components_selctbox_cls  .custom_radio  span:before {
  right: 0;
  left: 100%;
}

.modal-lg  .modal-content  .modal-body  .modal-detail-box  .inn-product-popup  .prodet_bansec {
  width: 592px;
  padding: 3px;
}

.content-main {
  background: url(/static/media/home-bg.8dffb75e.jpg) repeat;
}

.modal-dialog-tgrfromhome  .modal-detail-box  .inn_product_row  .compo_top_div  .product_chosen_inner  .tab-content
  .product_chosen_col_inner  .compo_acc_innerdiv  .custom-radio-btncls  .components_selct  .custom_radio  span {
  text-align: left;
}

.contact_wrap .bg-black {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.contact_wrap .bg-black .contactus_lhs {
  flex: 1 1 50%;
  max-width: 50%;
  min-width: 150px;
}
.contact_wrap .bg-black .contactus_lhs .contactus_gmap {
  width: 100%;
  height: 100%;
  border: 0px;
}
.contact_wrap .bg-black .contactus_rhs {
  flex: 1 1 50%;
  max-width: 50%;
  min-width: 150px;
  background: #fff;
  box-shadow: -11px 0px 11px 0px #00000059;
  z-index: 1;
  padding: 25px;
}
.contact_wrap .bg-black .contactus_rhs .contact_form h3 {
  color: #000;
  font-size: calc(18px + (25 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  margin: 0 0 15px;
}
.contact_wrap .btn_sec {
  text-align: center;
}
.contact_wrap .btn {
  border-radius: 0px;
  text-transform: uppercase;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  display: block;
  width: 100%;
  color: #fff;
  /*background: #f09509;*/
}

.tempHideCls {
  display: none;
}
.displayFullWtCls {
  width: 100%;
}

.modal-open {
  overflow: hidden !important;
}

.hmenu-login-act  li  .hcart_dropdown.open  .hcart_maiin_scrollarea  .pickup-cart-div  .cart-header-first  .row-replace {
  width: 100%;
}

/************************************ 15-07-21 *************************************/

.hcart_maiin_scrollarea .overall-parent .product-details,
.checkout-right-body-section  .overall-parent .product-details {
  display: block;
}

.hcart_maiin_scrollarea  .hcart_scrollarea .product-details-container,
.checkout-right-body-section  .overall-parent .product-details-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pro-avbl-errormsg {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 10px 15px;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  /*display: none;*/
  transition: all 0.4s;
  margin-top: 5px;
  width: 100%;
}

#cart-close-span {
  display: block;
  position: relative;
  order: 2;
  width: 24px;
  height: 22px;
  cursor: pointer;
}

#cart-close-span:before,
#cart-close-span:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #fff;
}
#cart-close-span:before {
  transform: rotate(45deg);
}
#cart-close-span:after {
  transform: rotate(-45deg);
}

.payment-info-popup .pop-whole-lhs-inner p {
  color: #000;
  font-size: calc(17px + (19 - 17) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 10px;
  font-family: 'caviar_dreamsbold';
}

.payment-info-popup .pop-whole-lhs-inner p span {
  font-family: 'caviar_dreamsbold';
  font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.payment-info-popup .inside-dine-popup-rhs {
  background: #fbeed9;
  padding: 40px 45px 20px;
  position: relative;
  z-index: 9;
  border: 1px solid #fbeed9;
  margin: 0 auto;
  -webkit-transition: 0.4s linear all;
  transition: 0.4s linear all;
}

.payment-info-popup .inside-dine-popup-rhs h3 span strong span{
  font-size: calc(15px + (22 - 15) * ((100vw - 300px) / (1600 - 300)));
  background: #561c4e;
  color: #fff;
}

.payment-info-popup .pop-whole-lhs-inner {
  text-align: center;
}
.payment-info-popup .pop-whole-lhs-inner {
  max-width: 100%;
  text-align: center;
}
.payment-info-popup .payment-info-body h3 span {
  font-size: calc(26px + (45 - 26) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
}
.popup_social_icon li a {
  color: #2a2a2a;
  padding: 0 10px;
  display: inline-block;
}
.popup_social_icon {
  list-style: none;
  margin-bottom: 0;
  background: #dba715;
  padding: 4px 0 0px 0px;
}
.popup_social_icon li {
  float: left;
}
.copied {
  position: absolute;
  right: 10px;
  top: -21px;
  background: #000;
  padding: 3px 7px;
  color: #fff;
  font-weight: 400;
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
  border-radius: 2px;
  pointer-events: none;
}
.gift-box {
  margin-top: 10px;
}
.popup_social_icon li a:hover {
  color: #fffefe;
}
.gift_message p {
  margin: 0 0 5px;
}
.gift_message .delivery_total_left {
  width: 100%;
}

.hcart_scrollarea .del_address .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
}

.hcart_scrollarea .del_address input:-webkit-autofill {
  background: #fff !important;
}
.favour-icon-link{
  display: none;
}

.home-banner .slick-dots{
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 40px;
}
.home-banner .slick-dots li button {
  height: 11px;
  width: 11px;
  border: 1px solid #fff;
  background: transparent;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  overflow: hidden;
  text-indent: -9999px;
  background: transparent;
}
.home-banner .slick-dots li{
    display: inline-block;
}
.home-banner .slick-dots li.slick-active button {
    background: #fff;
}

/************************* 30-09-21 ********************************/

.product-offer-section .pro-offer-txt p .read-less.home-about-us-less, 
.product-offer-section .pro-offer-txt p .read-more.home-about-us-dots {
    text-decoration: underline;
}

.qty_bx span.qty_plus.product_plus:before, 
.qty_bx span.qty_plus.product_plus:after{
  background: #fff;
}

.cart_footer .cart_remove {
  top: 4px;
  right: -16px;
}

.cart_row.promo-cart-row {
  position: relative;
  padding-right: 0;
}

.compo_addcart_cls .compocart_success_msg{
  margin-top: 10px;
}

.hmenu-login-section .hmenu-login-act .hcart_dropdown #cart-close-span {
  background: #000;
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 5px;
  top: 10px;
  right: 15px;
  border-radius: 5px;
}

.inn-product-popup .productdetail-rightside-maindiv .prd_chosen_sub_row .compo_addcart_cls{
  align-items: center;
  margin-top: 20px;
}

.cart_left .cart_into_desc {
  font-style: italic;
}

.checkout-control-group-top .gift_sub_div textarea{
  padding: 10px 15px;
  height: 100px;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
  outline: 0;
}

.mypromo-main-div .promo-earned-col-item .promo-earned-info .promo-earned-top {
  width: 100%;
}

.mypromo-main-div .promo-earned-col-item .promo-earned-info .promo-earned-top .promo-earned-code{
  line-height: 1.2;
}

.mypromo-main-div .promo-earned-col-item .promo-earned-info .promo-earned-top .promo-earned-code span {
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.modal-dialog-tgrfromhome .modal-full-xl .slick-slider,
.modal-dialog-tgrfromhome .modal-full-xl .slick-list,
.modal-dialog-tgrfromhome .modal-full-xl .slick-track,
.modal-dialog-tgrfromhome .modal-full-xl .slick-track div{
  height: 100%;
}

.modal-dialog-tgrfromhome .modal-full-xl .slick-track div .popup_social{
  height: auto;
}

.modal-full-xl .product-gellery-imglist .slick-track div .popup_social {
    bottom: 10px;
}
.react-datepicker__time-container,ul.react-datepicker__time-list,.react-datepicker__header.react-datepicker__header--time,
.react-datepicker__time, .react-datepicker__time-box {
    width: 130px !important;
}

.catering_receipt_popup .tnk-delivery .oreder_itm_row .cart_info h4{
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
}

/********************************* HOME POPUP START ****************************************/

.hcategory_sec.header{
  position: sticky;
  top: 78px;
  z-index: 0;
}

.hcategory_submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}
span.readMoreText {
    color: #000 !important;
}
.hcategory_sec.header {
  position: -webkit-sticky;
  position: sticky;
  top: 78px;
  z-index: 0;
}

.prodetailinner-main-div .productdetail-rightside-maindiv .compo_addcart_cls .compo_add_div{
  width: 50%;
}
.mob_sub_cat {
  position: sticky;
  z-index: 99;
  background: #f8f8f8;
}

.foot-img img{
  max-width: 100%;
  border-radius: 15px;
}

.hcategory_trigger,
.hcategory_selected_text,
.mob_sub_cat a,
.mob_sub_cat a span{
  font-family: 'caviar_dreamsregular';
}

/*************************** 19-10-21 ************************************/

.homebanner-content {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 756px;
    margin: 0 auto;
    width: 100%;
    right: 0;
    height: auto!important;
    line-height: normal;
    text-align: center;
}

.homebanner-content-inner {
    max-width: 682px;
    margin: 0 auto;
    background: rgb(11 12 15 / 67%);
    padding: 35px 35px
}

.homebanner-content-inner h4 {
    font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-family: 'caviar_dreamsbold';
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #fff;
}

.homebanner-content-inner h2 {
    font-size: calc(28px + (58 - 28) * ((100vw - 300px) / (1600 - 300)));
    text-transform: capitalize;
    line-height: 1;
    margin-bottom: 15px;
    color: #fff;
}

.homebanner-content-inner a {
  font-size: calc(10px + (15 - 10) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  margin-bottom: 0;
  padding: 12px 24px;
  background: #fff;
  align-items: center;
  color: #000;
  font-family: 'impactregular';
  border-radius: 0;
}

.homebanner-button a.btn.active,
.homebanner-button a.btn:hover{
  background: #dfc288;
  color: #000;
}

.homebanner-content-inner p{
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4;
  color: #fff;
  max-width: 512px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.homebanner-button {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 540px;
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
}

.home-banner .loader-main-cls img{
  width: 100%;
}

/********************** HOME RESTAURANT START ***********************/

.home-restaurant{
  background: url(../images/resimg.jpg) no-repeat;
  background-size: cover;
  padding: 110px 0;
  text-align: center;
  background-attachment: fixed;
}

.home-restaurant h2{
  font-size: calc(26px + (38 - 26) * ((100vw - 300px) / (1600 - 300)));
  color: #fdd18c;
  margin-bottom: 30px;
}

.home-restaurant-inner-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-restaurant-left{
  width: 32%;
  max-width: 100%;
  padding: 0;
  color: #fff;
  text-align: center;
  padding: 0 20px;
}

.home-restaurant-left li{
  list-style: none;
}

.h-r-i-heading{
  font-family: 'autographyregular';
  font-size: calc(32px + (52 - 32) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 20px;
  background: #561c4e;
}

.h-r-i-sub-heading{
  font-family: 'caviar_dreamsbold';
  font-size: calc(15px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1.5;
  padding: 10px 0 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.h-r-i-para{
  font-family: 'caviar_dreamsregular';
  font-size: calc(14px + (14 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.home-restaurant-button a {
  font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  margin-bottom: 0;
  padding: 16px 52px;
  background: #fff;
  color: #000;
  font-family: 'impactregular';
  border-radius: 0;
  margin-right: 15px;
}

.home-restaurant-button{ display: flex; justify-content: center; }
.home-restaurant-button a.btn:hover{ background: #dfc288; color: #000; }

.h-r-i-para-space{ padding-top: 20px; }
.h-r-i-para-space:last-child{ padding-bottom: 30px; }

/********************** HOME MENU START ***********************/

.home-menu{
  padding: 0;
  margin: 0px;
  background-color: #000
}

.home-menu h2 {
  font-size: calc(26px + (38 - 26) * ((100vw - 300px) / (1600 - 300)));
  color: #fdd18c;
  text-align: center;
  margin-bottom: 35px;
}

.home-menu-inner{
  background: #000;
  padding: 60px 0px 55px;
  margin: 0px 60px;
}

.home-menu .product-info-div-home{
  display: flex;
  justify-content: center;
  flex: auto;
  flex-direction: inherit;
  height: 100%;
  background: transparent;
  padding: 15px 12px 0px 12px;
}

.home-menu .product-info-div-home .button{
  padding: 22px 52px;
  font-family: 'impactregular';
  border-radius: 0;
  letter-spacing: 0;
  color: #000;
  background: #fff;
}

.home-menu .product-info-div-home .button:before{
  background: #dfc288;
}

.home-menu .product-info-div-home .button:hover{
  background: #dfc288;
  border: #dfc288;
  color: #000;
}

.home-review{
  background: #ffffff;
  padding: 70px 0 95px;
}

.home-review h2{
  font-size: calc(22px + (38 - 22) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  margin-bottom: 60px;
}

.h-menu-box ul{
  display: flex;
  justify-content: space-between;
  padding: 0;
  max-width: 1210px;
  margin: 0 auto;
}

.h-menu-box ul li{
  width: 288px;
  list-style: none;
  margin: 0 20px 20px 0;
}

.h-menu-box ul li {
  overflow: hidden;
  position: relative;
}

.h-menu-box ul li:nth-child(4n){
  margin-right: 0;
}

.h-menu-li-image{
  overflow: hidden;
}

.h-menu-box ul li a img {
  transition: 0.3s;
}

.h-menu-box ul li:hover a img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.overlay-box{
  position: static;
  top: 0;
  width: 100%;
  height: 100%;
}

.h-menu-box ul li:hover .overlay-box{
  background: #00000069;
}

/********************** REVIEW START ***********************/

.h-review-left-one{
 margin: 0 10px;
}
.h-review-one{
  padding: 20px;
  background: #efefef;
  border-radius: 6px;
}

.h-review-one .h-review-ul{
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.h-review-one ul li{
  list-style: none;
}

.h-review-heading{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #000;
  font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 5px;
}

.h-review-para{
  color: #000;
  font-weight: 500;
  line-height: 1.4;
  font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Montserrat', sans-serif;
}

.h-review-content{
  padding: 0;
}

.h-review-content li{
  list-style: none;
}

.h-review-ul li img{
  height: 20px;
}

.h-review-bottom-box ul {
  list-style: none;
  padding: 15px 0 5px 5px;
}

.review-full ul li {
  margin-bottom: 30px;
}

.review-author {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.review-author-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
}

.review-author-content {
    width: calc(100% - 60px);
}

.review-author-content h4 {
    text-transform: inherit;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1600 - 300)));
    margin-bottom: 10px;
}

.product-addtional-info .accordion .accordion-item .panel p:last-child {
    margin: 0;
}

.review-author-content h5{
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Montserrat",sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: inherit;
}

.review-author-content p{
  font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: inherit;
  color: #727272;
}

.h-review-one{
  position: relative;
  width: 100%;
}

.h-review-one:before {
  content:"";
  position: absolute;
  width: 0;
  height: 0;
  left: 80px;
  border:10px solid transparent;
  border-top-color:#efefef;
  bottom: -20px;
  margin-left: -65px;
}

.h-review-one:after {
  content:"";
  position: absolute;
  width: 0;
  height: 0;
  left: 80px;
  bottom:-20px;
  margin-left: -65px;
  border:10px solid transparent;
  border-top-color:#efefef;
  z-index: -1;
}

/****************************** FOOTER START ****************************/

.footer-main{
  background: #00989a;
  position: relative;
  margin: 0;
  padding-top: 50px;
}

.footer-top-info,
.footer-top{
    display: none;
}

.footer-nav-info .f-nav ul li a,
.footer-nav-info .f-nav ul li{
  color: #fff;
  font-family: 'caviar_dreamsbold';
}

.footer-nav-info .f-nav ul{ text-align: center; }

.f-newletter-form { position: relative; }

.footer-newletter { margin-top: -15px; }

.f-newletter-form input[type="text"] {
  background: none;
  border: 0;
  border-bottom: 1px solid #f5ce6d;
  padding-left: 2px;
  padding-right: 34px;
  color: #fff;
  border-radius: 0;
  font-family: 'caviar_dreamsbold';
  font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.f-newletter-form input::placeholder{
  color: #f5ce6d;
}

.f-newletter-form .button {
  position: absolute;
  right: 5px;
  top: 20px;
  margin: 0;
  background: none;
  padding: 0;
  border-radius: 0;
  width: 21px;
  height: 26px;
  font-size: 0;
}

.f-newletter-form .button:after {
  background: url(../images/gold-right-arrow.svg) no-repeat;
  width: 20px;
  height: 12px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.f-newletter-bottom input[type=checkbox]:not(.switch):checked {  background: #f7d57e; }

.footer-newletter input[type=checkbox]:not(.switch):checked {  --r: 43deg; }
.footer-newletter input[type=checkbox]:not(.switch):checked, .footer-newletter input[type=radio]:not(.switch):checked{ --o: 1;}
.f-newletter-bottom { position: relative; padding-left: 24px; }

.footer-newletter input[type=checkbox], .footer-newletter input[type=radio] {
  --active: #f5ce6d;
  --active-inner: #000;
  --focus: 2px rgba(0, 0, 0, .3);
  --border: #757575;
  --border-hover: #000;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #999;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 18px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

.footer-newletter input[type=checkbox]:not(.switch):after {
  width: 5px;
  height: 9px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 6px;
  top: 3px;
  transform: rotate(var(--r, 20deg));
}

.footer-newletter input[type=checkbox]:not(.switch):after, .footer-newletter input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
}

.footer-newletter input[type=checkbox]:after, .footer-newletter input[type=radio]:after {
  content: "";
  display: block;
  left: -1px;
  top: -1px;
  position: absolute;
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
}

.footer-newletter input[type=checkbox]:not(.switch) {
  border-radius: 3px;
}

.footer-newletter input[type=checkbox]:not(.switch), .footer-newletter input[type=radio]:not(.switch) {
  width: 18px;
}

.f-newletter-bottom input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 4px;
  background: #fff;
  border-color: #f5ce6d;
}

.f-newletter-bottom label {
  font-family: 'caviar_dreamsregular';
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  line-height: 1.4;
  display: inline-block;
  margin-left: 5px;
  text-align: left;
}

/****************************** FOOTER END ****************************/

.home-banner img{
  width: 100%;
}

.H-popup-inner-content a.btn {
  background: #00989a;
  border-color: #00989a;
  color: #fff;
  border-radius: 0;
  font-family: "impactregular";
  letter-spacing: 0.5px;
}

.hsign_sec a {
  font-family: 'caviar_dreamsbold';
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  text-transform: uppercase;
  line-height: 1.8;
}

.hsign_sec>span {
  font-size: calc(12px + (17 - 12) * ((100vw - 300px) / (1600 - 300)));
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
  color: #fff;
  margin: 0 5px;
}

.hsign_sec a.active, .hsign_sec a:hover {
  color: #fdd18c;
}

.f-socail-btm {
  margin: 0 25px 0 50px;
}

.mobile-socail-btm ul li a img:hover {
  transform: scale(1.1);
  transition: all .2s cubic-bezier(.63,.05,.43,1.7);
}

.mobile-menu-body .menu li.active a{
  color: #fdd18c;
}

/**************************** PRODUCT LIST PAGE ********************************/

.product-menu-listing .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.product-menu-sidebar{
  width: 278px;
}

.product-menu-sidebar .product-menu-left{
  width: 278px;
}

.mobile-catgegory-nav {
  display: none;
}

.product-menu-sidebar .menu-section-left {
  background: #fff;
  box-shadow: 5px 5px 29px 0px rgb(0 0 0 / 11%);
  border-radius: 10px;
  padding: 20px 0;
}

.product-menu-sidebar .menu-section-left .our-menu-title {
  display: flex;
  align-items: center;
  padding: 11px 5px 11px 25px;
  font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1600 - 300)));
  border-bottom: 1px solid #eee;
}

.product-menu-sidebar .menu-section-left .our-menu-title span {
  line-height: 0.7;
  margin-left: 10px;
}

.product-menu-sidebar .menu-section-left-inner .menu-list-intvl a {
  padding: 18px 10px 18px 22px;
  position: relative;
  cursor: pointer;
  color: #000;
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  display: block;
  line-height: 1.1;
  z-index: 1;
}

.product-menu-sidebar .menu-section-left-inner .menu-list-intvl a:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #fdd18c;
  height: 100%;
  transition: all 0.3s linear;
  width: 0;
  border-radius: 0 0px 0px 0;
}

.product-menu-sidebar .menu-section-left-inner .menu-list-intvl.active > a:before, 
.product-menu-sidebar .menu-section-left-inner .menu-list-intvl a:hover:before {
  width: 100%;
}

.product-menu-sidebar .product-menu .menu-list-intvl{ position: relative; }
.product-menu-sidebar .product-menu .menu-list-intvl.active { width: 104%; }

.product-lhs-container {
  width: calc(100% - 300px);
  border-radius: 10px;
  padding: 25px 35px 65px;
  background: #fff;
  margin-bottom: 110px;
}

.catry-top-title span{
  display: block;
  color: #606060;
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  text-transform: capitalize;
}

.catry-top-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 30px;
}

.cafe-lhs-filter {
  width: 50%;
  margin: 0;
  padding: 10px 15px 15px;
  position: relative;
}

.filter_by_brand_tag{ width: 50%; }
    
.filter_by_tag{  width: 50%; }

.filter_by_brand_tag .filter-act{
  max-width: 190px;
}

.filter-act {
  position: relative;
  padding-right: 20px;
  text-transform: capitalize;
  letter-spacing: .5px;
  margin-top: 10px;
  cursor: pointer;
  color: #000;
}

.cafe-lhs-filter .filter-act {
  letter-spacing: 4px;
  text-transform: uppercase;
  padding-bottom: 10px;
  color: #000;
  font-size: calc(11px + (14 - 11) * ((100vw - 300px) / (1600 - 300)));
  margin-top: 5px;
}

 .filter_by_tag span{
  text-align: right;
}

.filter-act:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 0px;
  top: -3px;
  font-size: calc(22px + (28 - 22) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1;
  font-weight: 400;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cafe-lhs-filter ul{
  display: none;  padding: 0;  margin: 0;  background: #fff;  border: 1px solid #e4e4e4;  position: absolute;
  top: 100% !important;  top: 0;  left: 0;  list-style: none;  width: 160px;  z-index: 9;
}

.cafe-lhs-filter ul { width: 100%; }
.cafe-lhs-filter ul.active{ display: block; }

 .cafe-lhs-filter ul li a{
  display: block;  padding: 8px 10px;  line-height: 1.2;  border-bottom: 1px solid #f4f4f4;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));  color: #000;
}

.hcategory_sec {
  display: none;
}

.addcart_done_maindiv .qty_bx span.qty_minus {
    border: 2px solid #d68407;
}

.alt_btns .disbl_href_action:hover{
  background: #000;
  color: #fff;
}

.outlet_error_popup .alert_body h2{  margin-bottom: 10px; }
.outlet_error_popup .alert_body p{  font-family: 'Montserrat', sans-serif; margin-bottom: 5px; }
.outlet_error_popup .alert_body .alt_btns{  margin-top: 10px; }

.delevery_popup_datetime .syd_date .react-datepicker__input-container:after,
.syd_date .react-datepicker__input-container:after, 
.syd_time .react-datepicker__input-container:after {
  content: "";
  background: #fff url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  pointer-events: none;
  height: 46px;
}

.custom-alrt-popupcls .alt_btns a:hover{
  background: #fdd18c;
  color: #000;
}

/****************************** HOME END ****************************/

.homebanner-content-inner a.btn:before,
.home-restaurant-button a.btn:before,
.home-popup-block .order-body .H-popup-inner-content a.btn:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .3;
  z-index: 9;
  content: "";
  -webkit-transition: -webkit-transform .6s;
  transition: -webkit-transform .6s;
  transition: transform .6s;
  transition: transform .6s,-webkit-transform .6s;
  -webkit-transform: scale3d(1.9,1.4,1) rotate(180deg) translate3d(0,100%,0);
  transform: scale3d(1.9,1.4,1) rotate(180deg) translate3d(0,100%,0);
}

.homebanner-content-inner a.btn:hover,
.home-restaurant-button a.btn:hover,
.home-popup-block .order-body .H-popup-inner-content a.btn:hover {
  border-color: #dfc288;
}

.homebanner-content-inner a.btn:hover:before,
.home-restaurant-button a.btn:hover:before,
.home-popup-block .order-body .H-popup-inner-content a.btn:hover:before{
  -webkit-transform: scale3d(1.9,1.4,1) rotate(180deg) translate3d(0,-100%,0);
  transform: scale3d(1.9,1.4,1) rotate(180deg) translate3d(0,-100%,0);
}

/********************** BUTTON EFFECT ANIMATION **********************************/

.products-ordernow-action .button,
.product-menu-listing .addcart_row .btn,
.delevery_popup_datetime .btn_sec .button,
.login_pop_sub button, .login_pop_sub .signup_submit,
.alt_btns a,
.acc-inform .form-group .row .button,
.hcart_maiin_scrollarea .cartaction_bottom .btn,
.check_out_fourcommon .chk-payment-btn-row a.button,
.contact_wrap .btn,
.menumain-div .menu-blckdiv .product-info-div .products-ordernow-action .button,
.vouchergift-popup .gift-group .button,
.vouchergift-popup .button.add_to_cart,
.career-top .myaccount_update,
.redeem_apply .btn,
.omisepop_in .alt_btns .button,
.homebanner-content-inner a.btn,
.home-restaurant-button a.btn,.login-info-body .button,
.home-popup-block .order-body .H-popup-inner-content a.btn{
  position: relative;
  overflow: hidden;
}

.products-ordernow-action .button:before,
.product-menu-listing .addcart_row .btn:before,
.delevery_popup_datetime .btn_sec .button:before,
.login_pop_sub button:before, 
.login_pop_sub .signup_submit:before,
.alt_btns a:before,
.acc-inform .form-group .row .button:before,
.hcart_maiin_scrollarea .cartaction_bottom .btn:before,
.check_out_fourcommon .chk-payment-btn-row a.button:before,
.contact_wrap .btn:before,
.vouchergift-popup .gift-group .button:before,
.vouchergift-popup .button.add_to_cart:before,.login-info-body .button:before,
.career-top .myaccount_update:before,
.redeem_apply .btn:before,
.omisepop_in .alt_btns .button:before{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .3;
  z-index: 9;
  content: "";
  transition: transform .6s;
  transform: scale3d(1.9,1.4,1) rotate(180deg) translate3d(0,100%,0);
}

.menumain-div .menu-blckdiv .product-info-div .products-ordernow-action .button:before{
  background: #dfc288;
}

.menumain-div .menu-blckdiv .product-info-div .products-ordernow-action .button:hover{
  background: #dfc288;
  border: #dfc288;
}

.products-ordernow-action .button:hover,
.product-menu-listing .addcart_row .btn:hover{
  background: #a3a3a3;
  color: #fff;
  border-color: #a3a3a3;
}

.check_out_fourcommon .chk-payment-btn-row a.button:hover, .login-info-body .button:hover,
.contact_wrap .btn:hover{
  background: #fdd18c;
  color: #000;
}

.products-ordernow-action .button:hover:before,
.product-menu-listing .addcart_row .btn:hover:before,
.delevery_popup_datetime .btn_sec .button:hover:before,
.login_pop_sub button:hover:before, 
.login_pop_sub .signup_submit:hover:before,
.alt_btns a:hover:before,
.acc-inform .form-group .row .button:hover:before,
.hcart_maiin_scrollarea .cartaction_bottom .btn:hover:before,
.check_out_fourcommon .chk-payment-btn-row a.button:hover:before,
.contact_wrap .btn:hover:before,
.vouchergift-popup .gift-group .button:hover:before,
.vouchergift-popup .button.add_to_cart:hover:before,
.career-top .myaccount_update:hover:before,
.redeem_apply .btn:hover:before, .login-info-body .button:hover:before,
.omisepop_in .alt_btns .button:hover:before{
  transform: scale3d(1.9,1.4,1) rotate(180deg) translate3d(0,-100%,0);
}

/**************************** PRODUCT DETAILS PAGE ********************************/

.modal-full-xl .modal-detail-box .inn-product-popup .product-detail-topmaindiv {
  padding: 0 30px;
  width: 100%;
}

.product-detail-box .modal-full-xl .modal-detail-box .inn-product-popup .product-detail-topmaindiv{
  padding: 0;
}

.product-detail-box #ProductDetailMdl .modal-content{
  border-radius: 10px;
}

.product-detail-box .prodetailinner-main-div .modal-full-xl .modal-content .modal-body{
  padding: 30px 28px;
}

.inn_product_hea_left h4{
  margin-bottom: 10px;
}

.product-detail-box .product-desc{
  background: #f2efef;
  color: #000;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  margin-bottom: 15px;
  padding: 4px 20px;
  border-radius: 50px;
  display: inline-block;
}

.compo_list_div.main_combo_div .custom-select-bxcls:after{
  display: none;
}

.mobile-menu.active .mobile-socail-btm ul li a {
  animation-delay: 0.9s;
}

.mobile-menu.active .mobile-socail-btm a {
  animation: a 0.4s 0.2s backwards;
}

.mobile-menu.active .mobile-socail-btm ul li:nth-of-type(2) a {
  animation-delay: 0.9s;
}

.mobile-menu.active .mobile-socail-btm ul li:nth-of-type(3) a {
  animation-delay: 0.9s;
}

.check_out_fourcommon .birthday-info-div{
  background: #fff;
  border-radius: 10px;
  border: 2px solid #fdd18c;
}

.chk-bffoot {
  border: 2px solid #00989a;
  border-radius: 10px;
  padding: 40px 0px 10px 0px;
  background: #fff;
  margin: 20px 0;
}

.checkout-main-div .cart_header_title p,
.checkout-main-div .cart_innr_header h4{
  color: #fff;
}

.sale-flag-sash {
  background: #dfc288;
  color: #fff;
  font-size: 15px;
  left: -35px;
  line-height: 1.06667rem;
  padding-top: 0.13333rem;
  position: absolute;
  text-align: center;
  top: 25px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: .8s ease;
  transition: .8s ease;
  height: 1.33333rem;
  width: 7.93333rem;
  z-index: 20;
}

.products-list-ulmain .products-single-li .sale-flag-sash .sale-text {
  padding-left: 10px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
}

.products-container-div {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.offer.products-single-li:after {
  background: url(../images/product-off-2.png) no-repeat;
  -webkit-backface-visibility: visible;
  position: absolute;
  content: "";
  bottom: 0;
  width: 44px;
  left: 55px;
  z-index: -1;
  top: -24px;
  height: 44px;
}

.offer.products-single-li:before {
  background: url(../images/product-off-1.png) no-repeat;
  position: absolute;
  content: "";
  bottom: 0; 
  width: 44px;
  left: -23px;
  z-index: -1;
  top: 54px;
  height: 44px;
}

.productlist-main-div .products-list-ulmain > li.products-single-li {
  position: relative;
  overflow: visible;
}

.mobile-login ul li a:hover{
  background: #fdd18c;
  color: #000;
}

.cms-page-box{
  margin-bottom: 150px
}

.container.cms-page-box{
  background: #fff;
  padding: 40px 20px;
  border-radius: 10px;
}

.check_out_fourcommon .redeem-col.redeem-col-right{
  border: 2px solid #561c4e;
}

/*Perumal 03-11-2021*/

.home-review .slick-slider .slick-dots .slick-active button{
    background: #cbcbcbc4;
    border: 2px solid #cecece;
}

.home-review .slick-slider .slick-dots li, .press-slider-top .slick-dots li{
  list-style: none;
}

.home-review .slick-dots li, .press-slider-top .slick-dots li {
  margin: 0 5px;
}

.home-review .slick-slider .slick-dots, .press-slider-top .slick-dots{
  display: flex!important;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 0;
}

.home-review .slick-dots,
.press-slider-top .slick-dots{
  display: flex!important;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 20px 0 0;
}

.home-review .slick-slider .slick-dots li button, .press-slider-top .slick-dots li button{
  font-size: 0;
  width: 11px;
  height: 11px;
  padding: 0;
  margin: 0 3px;
  border-radius: 50%;
  background: #ececec;
}

.press-slider-top .slick-dots li button{
  background: transparent;
  border: 2px solid #cecece;
}

.press-slider-top .slick-dots li.slick-active button{
  background: #fff;
  border: 2px solid #cecece;
}

.home-review button{
  background: #ececec;
  width: 11px;
  height: 11px;
  text-indent: -9999px;
  padding: 0;
  list-style: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
  border: none;
}

/************************ 05-11-21 *****************************/

.compo_addcart_cls .compocart_error_msg{
  margin-top: 10px;
}

.checkout-main-div .cart_header_title p{
  margin-right: 20px;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv{
  padding-bottom: 100px;
}

.myadmin_wrap .setting_menu_list > ul > li > a{
  border-bottom: 1px solid #6763634d;
}

.hmenu_list>li.active>a:after, ul.hmenu_list>li>a:hover:after {
    width: 100%;
    /*left: 0;*/
    opacity: 1;
}

ul.hmenu_list > li.active > a:before, ul.hmenu_list > li.active > a:after{
  display: block;
}

.self_popup.home-popup-block{ max-width: 760px; }

.home-popup-block .order-body{  border: 2px solid #fff; }
.home-popup-block .self_popup_hea_row{ background: #000; }
.home-popup-block .self_pop_row{  background: #000; }

.home-popup-block .order-body .H-popup-inner-content p{
  font-family: 'caviar_dreamsbold';
  color: #fff;
  font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.menumain-div .menu-blckdiv{
  padding: 70px 0 110px;
  text-align: center;
}

.menumain-div .menu-blckdiv h3{
  text-transform: uppercase;
  font-size: calc(18px + (38 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  position: relative;
}

.menumain-div .menu-blckdiv p{
  text-transform: uppercase;
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  font-family: 'caviar_dreamsbold';
  padding: 20px 0 40px;
}

.menumain-div .menu-blckdiv .products-list-ulmain > li{
  width: 23.515625%;
  margin: 0 1.953125% 20px 0; 
}

.menumain-div .menu-blckdiv .products-list-ulmain > li:nth-child(4n) {
  margin-right: 0;
}

.menumain-div .menu-blckdiv .products-list-ulmain{
  justify-content: flex-start;
}

.menumain-div .menu-blckdiv .product-info-div{     
  display: flex;
  justify-content: center;
  flex: auto; 
  flex-direction: inherit; 
  height: 100%; 
  background: transparent;
}

.menumain-div .menu-blckdiv .product-info-div .products-ordernow-action .button{
  padding: 22px 52px;
  font-family: 'impactregular';
  border-radius: 0;
  letter-spacing: 0;
  color: #000;
  background: #fff;
}

.menumain-div .inner-banner-content p{
  font-size: calc(24px + (38 - 24) * ((100vw - 300px) / (1600 - 300)));
  color: #fdd18c;
  line-height: 1;
  margin: 0 0 5px 0;
  text-transform: capitalize;
  font-family: 'caviar_dreamsbold';
}

.menumain-div .menu-blckdiv .products-image-div{
  position: relative;
  border: 2px solid #00989a;
}

.menumain-div .menu-blckdiv .products-image-div p{
  position: absolute;
  content: '';
  top: 95px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'caviar_dreamsbold';
  width: 100%;
  padding: 0 20px;
  margin-bottom: 0;
  text-transform: inherit;
}

.css-1pcexqc-container{ z-index: 4; }

.myadmin_wrap .cart_header_title .cart_innr_header h4{
  min-width: 100px;
  text-align: right;
  padding-right: 0;
}

.menumain-div .press-body-div h3:after{  top: 55px; }

.press-slider-top{  padding-top: 20px;}
.press-body-div .press-slider-top .products-image-div{ border: none; height: 100%;}

.press-body-div .press-slider-top .products-single-li{  padding-right: 10px;}

.press-body-div .press-slider-top .slick-track .slick-slide{ display: block !important; }
.press-body-div .press-slider-top .products-image-div p{
  bottom: 10px;  left: 0;  transform: translateX(0);  padding: 0 10px;  top: inherit;  text-align: left;
  text-transform: uppercase; font-weight: 500;
  font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));  font-family: 'Montserrat', sans-serif;
  
}

.press-body-div .press-slider-top .press-tag{  position: absolute;  content: '';  top: 0;  right: 0; }

.press-body-div .press-slider-top .press-tag span{
  text-transform: uppercase;
  font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
  padding: 5px;
  background: #1566c1;
  color: #fff;
}

.press-slider-top .products-container-div .products-image-div-arrow:before{
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 12px;
  height: 20px;
  background: url(../images/press-arrow.png) repeat;
  z-index: 1;
}

.press-body-div .products-container-div:hover .products-image-div:after{
  background: #00000070;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  content: '';
  z-index: 1;
}

.press-body-div .products-container-div:hover .products-image-div p{ z-index: 10; }

/************************** HOME REVIEW ************************************/

.h-review-one{  background: #000; }
.h-review-para{ color: #fff; }
.h-review-one:before{ border-top-color: #000; }
.h-review-heading{ color: #fff; }
.review-author-content h5{ color: #fff; }
.review-author-content p{ color: #fff; }
.home-review{ background: #561c4e; padding: 70px 0 95px; }
.home-review h2{ color: #fff; }

.copyright-section{ background: #561c4e; }

.foot-map{
  flex: 1 1 25%;
  max-width: 100%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #561c4e;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.header-top-cls{ border-bottom: 1px solid #efc789; }

.container.cms-page-box{ background: #00989a; color: #fff; }

.menumain-div.career-maindiv .career-head p{
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)))!important;
  padding: 0;
  text-align: center;
  text-transform: inherit;
}

.career-info-div{
  padding: 40px 30px;
  background: #f8f8f8;
  color: #000;
  overflow: hidden;
}

.career-info-div .button{
  width: 35%;
  display: block;
  margin: 0 auto;
  height: 48px;
  border-radius: 5px;
}

.career-maindiv .career-head{ padding-bottom: 20px; }
.career-info-div .button:hover{ color: #000;}
.career-info-div .choose-file-sec .form-control-file{ margin-top: 8px; margin-bottom: 4px; }

.menumain-div .menu-blckdiv p{
  margin-bottom: 0px;
  padding-top: 15px;
}

.career-form .col-md-4,
.career-textarea {
  margin-bottom: 25px;
}

.choose-file-sec .form-group p{
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  padding: 5px 0px;
  text-transform: capitalize;
}

/************ sssss **************************/

.full-login-new-header {
  background: #00989a;
  padding: 20px 30px;
  text-align: center;
  box-shadow: 0 4px 18px 0 rgb(2 2 2 / 4%);
}

.full-login-new-header h3 {
  font-size: calc(20px + (36 - 20) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 0;
  line-height: 1;
  color: #efc789
}

.full-login-new-header p {
  margin-bottom: 0;
  font-size: calc(14px + (15 - 14) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  padding-top: 5px;
  color: #efc789
}

.full-login-new-body {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  padding: 50px 0;
  text-align: center;
}

.popup-footer span {
  color: #868686;
  font-size: 14px;
  margin: 0 0 3px;
  text-transform: uppercase;
}

#login-content .kep-login-facebook.metro, .pop-whole .kep-login-facebook.metro {
  display: block;
  margin: 0 auto;
  background: #3d5a98;
  text-transform: inherit;
  border-radius: 5px;
  width: 100%;
  padding: 8px 15px;
  font-size: 13px;
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  border: 1px solid #cbcbcb;
  box-shadow: 0 3px 9px 0 rgb(0 0 0 / 21%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delevery_popup, .delevery_popup_datetime, .login-popup .full-login-new, .forgot-password-popup .full-login-new,
.signup-popup .full-login-new{
  background: #00989a;
}

.login-popup-all.login-popup .full-login-new-header{ border: 0;  }

.forgot-password-popup .full-login-new-header, .login-popup .full-login-new-header,
.signup-popup .full-login-new-header{ border: 1px solid #000; }

.kep-login-facebook .fa {  margin-right: calc(0.34435vw + 3.38843px); }

.or-seperator {
  position: relative;
  padding: 25px 0 20px;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
}

.or-seperator span {
  padding: 0 10px;
  background: #f6f6f6;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.popup-footer span {
  color: #868686;
  font-size: 14px;
  margin: 0 0 3px;
  text-transform: uppercase;
}

.login_pop_sub button, .login_pop_sub .signup_submit {
  width: 100%;
  background: #241e21;
  /*border: 1px solid #cbcbcb;*/
  box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
}

.full-login-new-body button,
.login_pop_sub button, .login_pop_sub .signup_submit{
  padding: 15px 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.react-datepicker button{
  padding: 0px 0px !important;
  margin-top: 0;
  margin-bottom: 0;
}

.login_pop_sub button, .login_pop_sub .signup_submit{ display: block; }

span.spilter {
  width: 2px;
  height: 24px;
  display: block;
  margin: 0 12px;
  background: #d3d4d4;
}

.full-login-new-body .form-group .custom_checkbox_content span{ display: inline-block; }
.full-login-new-body .form-group .custom_checkbox_content{ display: flex; text-align: left; }

.signup-popup .popup-body .pdpa_trmcont_link{ margin: 10px 0; display: block; text-align: left; }

.order-newdesign .main_tabsec {  margin: 0; }

div#site-header-wrapper { display: none; }

.product-info-div-home, .det_inner_content {  width: 100%; }

/*Perumal 17-11-2021 - My Account Page*/

.myacc-main-div .common-inner-blckdiv .common-inner-banner p{  color: #000!important;}
.myacc-main-div .common-inner-blckdiv .common-inner-banner span{  color: #000!important;}

.bg-none{  background: none!important;}

.myacc-main-div{  background: #fff;}

.setting_menu_list{  background: #fff;}
.setting_menu_list > ul > li > a{  color: #000;}
.setting_menu_list > ul > li > a:before{  background: #fff;}
.setting_menu_list > ul > li > a:before{  background: none;}

.accmenu_sec .setting_menu_list ul li.active a{  color: #fdd18c;}
.accmenu_sec .setting_menu_list ul li a:hover{  color: #fdd18c;}

.accsec_right{  background: #fff;  box-shadow: none;}

.myadmin_wrap .setting_menu_list > ul > li > a{  border: 0px;}
.myadmin_wrap .cart_header_title .cart_innr_header h4{  text-align: left;  min-width: auto;}

.mainacc_menucontent .tab-pane .tab_mobrow .account_sec .accsec_right .cart_header_title p{
  text-align: left;
}

.cart_header_title .cart_innr_header img{  margin-left: 15px;}

.count_num_t{  background: #fdd18c;}

.product-info-div-home .products-ordernow-action{  margin: 20px 0 0 0;}

.promo-earned-info:before{  left: -60px;}

.myvoucher-main-div .promation_btn{  justify-content: center;}
.myvoucher-main-div .promo-earned-col-image img{  object-fit: fill;}
.myvoucher-main-div .promo-earned-info:before{  left: -50px;}

.qty_bx span.qty_plus.product_plus:before, 
.qty_bx span.qty_plus.product_plus:after{
  background: #fff;
}

.vouchar-popup .products-ordernow-action .button:before{  background: #dfc288; }

.vouchar-popup .products-ordernow-action .button:hover{
  background: #a3a3a3;
  color: #fff;
  border-color: #a3a3a3;
}

.promo-earned-col{  list-style-type: none; }

/*Perumal 18-11-2021 Evening*/

.order-body .order-heading-top{
  background: #00989a;
  padding: 20px 30px;
  text-align: center;
  box-shadow: 0 4px 18px 0 rgb(2 2 2 / 4%);
  border-bottom: 1px solid #000;
}

.ordernow_popup{
  max-width: 820px;
  background: #00989a;
}

.ordernow_popup .order_delivery_row{ 
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  padding: 50px 0;
  text-align: center;
}

.ordernow_popup .order_delivery_item{ padding: 20px 0 40px;}
.ordernow_popup .order_delivery_item li a{ background: #fff;}
.ordernow_popup .order_delivery_item li a:hover { background: #241e21}

.order-body .self_popup_hea_row p{ color: #efc789;}

.self_outlet_inner li a{  background: #000;}
.self_outlet_inner li a:hover{ background: #000;}

.delevery_popup, .delevery_popup_datetime{ max-width: 730px;  }

.error_postal_popup{ max-width: 730px; background: #f6f6f6; }

.delevery_popup .full-login-new-header,
.delevery_popup_datetime .full-login-new-header{
  border-bottom: 1px solid #000;
}

.datetime_selt_lbl{ color: #000; }

.delevery_popup img.pop-scooter-img{ transform: scaleX(-1);}

.delevery_popup_datetime .mfp-close{
  background: #000;
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  top: 5px;
  right: 5px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
}

.delevery_popup_datetime .mfp-close:hover{ background: #000; }

.delevery_popup_datetime .mfp-close:after {
  content: "";
  background: url(../images/crosslg.png) no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.self_outlet_inner li a:hover, .self_outlet_inner li.active a{ background: #181818; color: #fff; }

.self_outlet .self_outlet_inner>li>span{ background: #fff; color: #000; }

.contactus_main {  background: #000;}
.contact_wrap .container .contactus_lhs{  max-width: 45%;}
.contact_wrap .container .contactus_rhs{ max-width: 45%;  }

.home-popup-block .self_popup_hea_row{ background: #000; }

.home-popup-block .order-body .H-popup-inner-content h2{
  color: #00989a;
  font-size: calc(28px + (52 - 28) * ((100vw - 300px) / (1600 - 300)));
  padding-bottom: 10px;
}

.menumain-div, .pagesList-main-div, .contactusmain-top-div, .myacc-main-div .common-inner-banner{ height: 100%; }

.controls.two-links a:hover,
.controls.single-link a:hover{
  color: #fff;
}

.myacc-main-div .common-inner-banner{ padding: 60px 20px 20px; }    

.accsec_right .welcome-title h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 20px;
  font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.accsec_right .welcome-title h1>div { text-align: center; }

.accsec_right .welcome-title h1>div span {
  color: #000;
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  text-transform: capitalize;
}

.accsec_right .welcome-title h1>img { margin-left: 10px; }

/*My Account Membership*/

.memb-foot{
  border-radius: 10px;
  box-shadow: 0px 1px 21px 0px rgb(0 0 0 / 18%);
  text-align: center;
  padding: 15px 0px;
  margin: 40px 0 30px;
}

.memb-payment-col-radio {
  padding: 0;
  list-style: none;
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.membership-banner { width: 100%; }

.memb-bord {  padding: 45px 20px 30px 0px;}
.memb-first{  text-align: right;  padding: 0;}
.memb-first img{  width: 60%;}
.memb-second{  padding: 0;}

.membership-banner-inner {
  margin: 0px auto 0;
  text-align: center;
  padding: 0px 0px;
}

.membership-banner-inner h3 {
  max-width: 792px;
  color: #000;
  font-size: calc(28px + (40 - 28) * ((100vw - 300px) / (1600 - 300)));
  text-transform: lowercase;
  text-align: right;
  margin-bottom: 20px;
}

.membership-banner-inner h3 span{  color: #fdd18c; }

.membership-banner-inner h4 {
  font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: #000;
  margin-bottom: 0;
  margin-left: 30px;
  text-align: left;
}

.membership-banner-inner h2 {
  font-size: 28px;
  text-align: left;
  color: #fdd18c;
  margin: 5px 0 0 30px;
}

.member-progress-2 {
  margin:15px auto;
  padding:0;
  width:100%;
  height:8px;
  overflow:hidden;
  background:#d3d3d3;
  margin-right: -10px;
  }

.member-prog-bar img{
  max-width: 20%;
  padding-right: 50px;
}
  
.member-bar-2 {
  position:relative;
  float:left;
  min-width:1%;
  height:100%;
  background:#561c4e;
}
  
.member-percent-2 {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  margin:0;
}

.member-bar-width-2{
  width:55%
} 

.member-prog-bar p{
  text-align: center;
  font-weight: bold;
  font-family: 'caviar_dreamsbold';
  text-transform: uppercase;
  color: #000;
}

.member-prog-bar{
  margin-bottom: 0px; 
  display: flex;
  margin-left: 30px;
  align-items: center;
  width: 100%;
}

.memb-payment-col-radio .custom_checkbox p{
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.member-progress-img{
  display: flex;
}

.member-help-block{
  text-align: left;
  margin-left: 30px;
  margin-top: 0px;
  color: #000;
  text-transform: capitalize;
  font-size: 16px;
}

.membership-hd{
  padding: 0px 0px!important;
}

.acc-inform .form-group .row .col-md-6:last-of-type{
  margin-right: 0;
}

.cms-page .container{
  padding-top: 0px;
  padding-bottom: 30px;
}

.container.cms-page-box{
  background: transparent !important;
}

.widget_box{
  margin-top: 40px;
}

.checkout-title{
  padding-top: 40px;
}

.checkout-title h1{
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 20px;
  font-size: 26px;
  font-weight: 600;
}

.checkout-title h1>div {
  text-align: center;
  color: #fff;  
}

.checkout-title h1>div span {
  color: #fff;
  font-size: 18px;
  font-weight: 550;
  font-size: 16px;
  text-transform: capitalize;
}

.checkout-title h1>img {
    margin-left: 10px;
}

.alert_body p{
  font-weight: bold;
}

.reward-banner{
  background: #fff;
}

/******************* PRODUCT VOUCHER POPUP ***************************/

.vouchergift-popup .react-tabs__tab-list .react-tabs__tab {
  font-size: calc(16px + 6*(100vw - 300px)/1300);
}

.vouchergift-popup .react-tabs__tab {
  width: 50%;
  text-align: center;
  margin-bottom: 0;
  text-transform: capitalize;
  border: 0 solid transparent;
  border-bottom: 5px solid #e4e4e4;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
}

.vouchergift-popup .react-tabs__tab--selected {
  background: transparent;
  border-bottom: 5px solid #181818;
  color: #181818;
  border-radius: 0;
}

.vouchergift-popup .react-tabs__tab-list .react-tabs__tab:focus {
  box-shadow: none;
  border-color: hsl(0deg 0% 100%/0%);
  outline: none;
}

.vouchergift-popup .voucher-popup-inner h2 {
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 20px;
}

.vouchergift-popup .react-tabs__tab-list{
  margin-bottom: 35px;
}

.vouchergift-popup .voucher-popup-inner label{
  display: flex;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
  font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1600 - 300)))
}

.vouchergift-popup .howtouse-gift-vouchar {
  margin: 30px 0;
}

.vouchergift-popup .gift-group .button, .vouchergift-popup .button.add_to_cart{
  padding: 16px 50px;
}

.vouchergift-popup .gift-group .button:hover, .vouchergift-popup .button.add_to_cart:hover{
  color: #000;
}

.vouchergift-popup .mfp-close{
  width: 35px;
  height: 35px;
  line-height: 35px;
  top: 2px;
  right: 2px;
  background: #000;
  color: #fff;
}

.vouchergift-popup .mfp-close:hover{
  background: #000;
}

.popup_sec.delevery_popup .full-login-new-body .innervmid_in .address-list-cls.address-list-main {
    text-align: left;
}

/************************************ RESERVATION CSS *********************************/

.reservation-body-div iframe{
  min-height: 880px;
  width: 325px;
}

.reservation-body-div{
  padding: 40px 0 110px;
}

.reservation-fullinner-div{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 845px;
  margin: 0 auto;
}

.reservation-lhs{
  width: 350px;
}

.reservation-Rhs{
  width: calc(100% - 350px);
}

.reservation-Rhs-heading h2, .reservation-Rhs-heading h4, .reservation-Rhs-heading p{  color: #fff; }

.reservation-Rhs-heading h2{
  position: relative;
  margin-bottom: 35px;
}

.reservation-Rhs-heading h2:after {
  position: absolute;
  content: '';
  top: 50px;
  left: 0;
  height: 2px;
  width: 100px;
  background: #fff;
}

.productpage-main-div{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
}

.contact_wrap, .menu-blckdiv, .privacy-policy-div, .menumain-div .press-body-div, .menumain-div .award-body-div{
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
}

.contactusmain-top-div .inner-banner-content p{
  margin-bottom: 0
}

.cms-page .privacy-policy .brunch-cms p{
  color: #fff;
}

.contact_wrap{
  padding-bottom: 110px;
}

.career-maindiv .menu-blckdiv{
  padding-top: 0;
}

.cms-page.privacy-policy-div{
  padding-bottom: 110px;
}

.cms-page .privacy-policy .brunch-cms{
  margin: 0;
}

.cms-page .privacy-policy{
  padding-bottom: 0;
}

.menumain-div .menu-blckdiv.press-body-div{ padding: 40px 0; }
.menumain-div .award-body-div{  padding-bottom: 110px; padding-top: 0;}


.copyright-section ul li a{
  font-family: 'caviar_dreamsbold';
  color: #fff;
}

.reservation-Rhs-heading h2 {
    color: #fdd18c;
}
.inner-banner-content p {
    color: #fdd18c !important;
}

.home-popup-block .f-socail{
  width: 100%;
  margin-top: 10px;
}

.brunch-main{
  background-size: cover;
  min-height: 410px;
}

.career-top .contact_form h3{
  color: #000;
}

.self_popup .self_outlet_inner li a{
  background: #fff;
  color: #000;
}
/*Perumal 15-12-2021*/
.homebanner-button a img{
  margin-right: 8px;
  width: auto;
  height: 30px
}
.order-body .order-heading-top h2{
    color: #efc789 !important;
}

.home-banner .homebanner-content .homebanner-button a{
  display: flex;
  justify-content: center;
}

/******************* MENU SLIDER *****************************/

.mobile_accountdel_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.mobile-order-nowbtn .mobile_account_item {
    position: relative;
}

.mobile_account_item_login>a {
    display: inline-block;
    padding: 21px 55px 19px;
    color: #000;
    font-family: 'caviar_dreamsbold';
    text-transform: uppercase;
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.mobile_account_item_login a {
    padding-right: 0;
    background: transparent;
}

.mobile_account_item_login>a>i {
    position: absolute;
    left: 10px;
    top: 50%;
    background: url(../images/aroow_myaccountb.png) no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    width: 28px;
    height: 28px;
    line-height: 28px;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile-order-nowbtn span.myaccount_separate {
    background: #000;
    margin: 0 8px 0 3px;
}

.mobile_accountdel_col {
    width: 100%;
    background: #e9e2d8;
    position: relative;
}

.mobile_accountdel_col.div_logged_in{
  width: 50%;
}

.mobile_accountdel_col:last-of-type {
    background: #000;
}

.mobile_account_menu_click {
    padding: 12px 55px 12px 50px;
    display: block;
    font-size: 20px;
    position: relative;
}

.mobile_account_menu_click i.arrow_myaccount {
    background: url(../images/aroow_myaccountb.png) no-repeat 50%;
    background-size: 24px auto;
    float: right;
    width: 55px;
    height: 49px;
    position: absolute;
    z-index: 8;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile_account_delivery_item {
    list-style: none;
    padding-left: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    position: absolute;
}

.mobile_account_delivery_item .active a, .mobile_account_delivery_item a:hover {
    color: #fdd18c;
    background: #000;
}

.mobile_account_delivery_item a {
    padding: 12px 15px;
    background: #000;
    display: block;
    font-size: 20px;
    color: #fff;
    font-family: 'caviar_dreamsbold';
    text-transform: uppercase;
    position: relative;
    text-align: center;
    border-bottom: 1px solid hsla(0,0%,100%,.16);
}

.mobile_account_item_login>a.signup_btn{
  padding-left: 0;

}

.mobile-order-nowbtn .mobile_account_delivery_item a {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    padding: 19px 15px;
}
.mobile_account_menu{
  display: none;
}

.career-info-div .contact_col .btn-block{
    width: 35%;
    display: block;
    margin: 0 auto;
    height: 48px;
    border-radius: 5px;
}

.career-info-div .contact_col .btn_sec{
  margin-top: 40px;
}

.mobile-menu-body .submenu-arow {
    display: block;
    width: 40px;
    background: #000;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\F107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin: -11px auto auto;
    font-size: 22px;
    transition: all .3s linear;
    display: block!important;
}

ul.submenu_list {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu-login-section .f-socail-btm ul li a img, .mobile-menu-body .f-socail-btm ul li a img{
  width: 25px;
}

.hmenu-login-section .f-socail-btm ul .f-link-icon a img, .mobile-menu-body .f-socail-btm ul .f-link-icon a img{
  width: 45px;
  vertical-align: middle;
}

.signup-popup .custom_checkbox input{
  width: 40px;
}


.submenu_list{
  display: none;
}

.reservation-Rhs-heading h4{
  font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.menumain-div .awards-section:nth-child(2n){ padding: 0; }

/*.menumain-div .menu-blckdiv.press-body-div*/

.awards-section .press-slider-top{
  padding-top: 0;
}


.delevery_popup_datetime .full-login-new-body h2{
  color: #000
}

.mobile_mainacc_menutrigger {
    background: url(../images/aroow_myaccountb.png) no-repeat 50%;
    background-size: 24px auto;
    float: right;
    width: 55px;
    height: 49px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile_mainacc_menulist {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    z-index: 1;
    display: none;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 12%);
}

.mobile_mainacc_menulist>ul {
    margin: 0;
    padding: 0;
}

.mobile_mainacc_menulist li a {
    display: block;
    padding: 12px 15px 12px 55px;
    background: #e9e2d8;
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    position: relative;
    color: #000;
    text-transform: uppercase;
    font-family: "caviar_dreamsbold";
    border-top: 1px solid rgba(0,0,0,.1);
    text-align: left
}

.mobile_mainacc_menulist .setting_menu_list_icon.password_icon {
    background-position: -295px -255px;
    top: -4px!important;
}

.mobile_mainacc_menulist .setting_menu_list_icon {
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.setting_menu_list_icon.password_icon {
    width: 15px;
    height: 18px;
}

.setting_menu_list_icon {
    background: url(../images/spirite_whole.png) no-repeat;
    display: inline-block;
    margin-right: 16px;
    position: relative;
}

.mobile_account_item a{
  background: #e9e2d8;
  color: #000;
  text-align: left
}

.mobile_account_item > a > i {
    position: absolute;
    left: 10px;
    top: 50%;
    background: url(../images/profile_grey.png) no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    width: 28px;
    height: 28px;
    line-height: 28px;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile_account_item > a {
    padding: 14px 55px 10px 55px;
    background: #e9e2d8;
    color: #000;
    display: block;
    font-family: "caviar_dreamsbold";
    font-size: 20px;
    text-transform: uppercase;
}

.mobile_mainacc_menulist li a{
    display: block;
    padding: 12px 15px 12px 55px;
    background: #e9e2d8;
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    position: relative;
    color: #000;
    text-transform: uppercase;
    font-family: "caviar_dreamsbold";
    border-top: 1px solid rgba(0,0,0,.1);
}

.setting_menu_list_icon.logout_icon {
    width: 20px;
    height: 20px;
    background-position: -462px -255px;
    top: 0px;
}

.mobile_mainacc_menulist .setting_menu_list_icon.logout_icon {
    background-position: -430px -255px;
}

.cart-items-open .jxPOhn, .cart-items-open .sc-7dvmpp-1{
  display: none;
}

.no-recrds-found {
    text-align: center;
    width: 100%;
}

.hcart_dropdown.open .cart_footer_itme_pack .cart_row:last-child{
  margin-bottom: 0;
}

.mobile-menu .menu .submenu_list li:last-child{
  border-bottom: 0;
}

.mobile_accountdel_col.div_logged_in{
  width: 50%;
}

.menu li.active .submenu_list>li.active>a, .menu li.active>a, .mobile-menu .menu a:hover{
  color: #fdd18c;
}

.hcart_dropdown .cart_action {
    padding: 0;
    z-index: 99;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

header .hcart_dropdown .cartaction_bottom .btn {
  border-radius: 0;
}

header .hcart_dropdown .hcart_scrollarea {
        overflow-y: auto;
        height: 100%;
        max-height: calc(100% - 40px);
    }

    .pickup-cart-div .cart-header-first .cart_left{
      border-bottom: 1px solid #ebebeb;
      width: 100%;
      display: block;
    }

    header .hcart_dropdown .cart-header-first .row-replace{
      display: block;
      width: 100%;
      height: 100%;
      position: inherit;
      order: unset;
    }
.ril__navButtons:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+) no-repeat center !important;
}

.login.full-login-rhs-header {
    padding: 0 0px 0px 0 !important;
}
.login.full-login-rhs-header p{
    margin: 0 0 15px !important;
    color: #fff;
}

.login.full-login-rhs{
    padding: 9px 0px 0px 0px !important;
}



.login-popup-all{ 
  max-width: 862px;
  margin: 20px auto;
  width: 100%;
  background: #fff;
  position: relative;
  color: #222222; 
  /*padding: 55px 0 40px;
  background: #00989a;*/
}

.login-popup-all .full-login-new-header{
  box-shadow: none;
  padding: 55px 20px 0;
}

.login-popup-all .full-login-new-body{
  padding: 20px 80px 45px;
  max-width: 100%;
}

.login-popup-all .order_delivery_item{
  padding: 0;
}

.login-popup-all li{
  width: 32%;
}

.login-popup-all .order_delivery_item li .header-ordernow-single-img img {
  height: 65px;
}

.login-popup-all .header_offer{
  padding-top: 10px;
  color: #fff;
  text-transform: inherit;
}

.login-popup-all .form-group{
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.login-popup-all .controls.two-links a{
  position: relative;
  color: #fff;
}

.login-popup-all .controls.two-links a:hover{
  color: #000;
}

.login-popup-all .controls.two-links a:hover:before{ background: #000; }

.login-popup-all .form-group a:before{
  position: absolute;
  content: '';
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 140px;
  height: 1px;
  background: #fff;
}

.login-popup-all .order_delivery_item h3 {
    font-size: calc(10px + (18 - 10) * ((100vw - 300px) / (1600 - 300)));
  }

.login-popup-all .full-login-new-header h3 {
    font-size: calc(20px + (42 - 20) * ((100vw - 300px) / (1600 - 300)));
}


.login-popup-all .full-login-block{
  display: flex;
  justify-content: space-between;
  padding: 60px 80px;
}

.login-popup-all .login-content{
  display: flex;
  justify-content: center;
}

.login-popup-all .login-image{
    width: 150px;
    height: 150px;
    margin: 20px auto 0;
}

.login-popup-all .login-image img{
  width: 100%;
  height: 100%;
}

.login-popup-all .login-info-body{
  width: 260px;
  margin: 0 20px 0 0;
  border: 2px solid #fff;
}

.login-popup-all .login-info-body:nth-child(3n){
  margin-right: 0;
}

.login-popup-all .login-info-body .button{ 
  padding: 16px 10px;
  font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
  min-width: 100px;
}